import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import PermalinkPage from 'pages/PermalinkPage'
import InitCheckoutPage from 'pages/InitCheckoutPage'
import CheckoutTemplate from 'pages/checkouts/CheckoutTemplate/CheckoutTemplate'
import FunnelStepPage from 'pages/FunnelStepPage'
import CartRedirectRouter from 'routers/CartRedirectRouter'

const CheckoutsRouter = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/perm`} component={PermalinkPage} />
      <Route path={`${match.url}/init`} component={InitCheckoutPage} />
      <Route path={`${match.url}/:checkoutId/order/:orderId/step/:stepId`} component={FunnelStepPage} />
      <Route path={`${match.url}/:checkoutId`} component={CheckoutTemplate} />
      <Route component={CartRedirectRouter} />
    </Switch>
  )
}

export default withRouter(CheckoutsRouter)
