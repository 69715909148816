import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext } from 'contexts/ThemeContext'
import { ProcessorContext } from 'contexts/ProcessorContext'
import { CheckoutContext } from 'contexts/CheckoutContext'
import PaymentMethodSelector from 'components/PaymentMethodSelector'
import BillingInformation from 'components/BillingInformation'
import Button from 'components/Button'
import { FiChevronLeft, FiArrowRight } from 'react-icons/fi'
import { useFormikContext } from 'formik'

const PaymentStep = ({ history, checkout }) => {
  // Context //
  const { theme } = useContext(ThemeContext)
  const { handleCreditCardRequestNonce, handlePaypalApproved, paypalOrderId, selectedMethod, resetErrors } = useContext(
    ProcessorContext
  )
  const { loading, errors } = useContext(CheckoutContext)
  const { validateForm, setFieldTouched } = useFormikContext()

  // Functions //

  const handleSubmit = async () => {
    resetErrors()

    const errors = await validateForm()

    const stepValues = [
      'email',
      'acceptsMarketing',
      'shippingFirstName',
      'shippingLastName',
      'shippingLine1',
      'shippingLine2',
      'shippingCity',
      'shippingCountry',
      'shippingRegion',
      'shippingZipcode',
      'shippingPhone',
      'billingFirstName',
      'billingLastName',
      'billingLine1',
      'billingLine2',
      'billingCity',
      'billingCountry',
      'billingRegion',
      'billingZipcode',
      'billingPhone',
    ]

    const scopedErrors = Object.keys(errors).filter(k => stepValues.includes(k))

    if (scopedErrors.length > 0) {
      scopedErrors.forEach(key => setFieldTouched(key, true))
    } else {
      if (selectedMethod === 'PAYPAL') {
        handlePaypalApproved(paypalOrderId)
      } else {
        handleCreditCardRequestNonce()
      }
    }
  }

  return (
    <>
      <PaymentMethodSelector checkout={checkout} />

      <BillingInformation checkout={checkout} />

      <div className="w-full my-4 lg:mt-8">
        <div className="w-full lg:flex lg:items-center">
          <div className="lg:flex-shrink lg:order-2">
            <div className="my-4 text-base text-red-400">
              {errors.map((e, index) => {
                return <div key={index}>{e.message}</div>
              })}
            </div>

            {loading ? (
              <div className="p-4 text-sm text-center text-white bg-gray-500 rounded">Processing Your Order...</div>
            ) : paypalOrderId || selectedMethod !== 'PAYPAL' ? (
              <Button style={{ backgroundColor: theme.primaryBrandColor }} onClick={handleSubmit}>
                <div className="flex items-center justify-center">
                  <div className="mr-2 text-xl font-bold tracking-wide">Complete Purchase</div>
                  <div className="text-3xl text-white slide-left">
                    <FiArrowRight />
                  </div>
                </div>
              </Button>
            ) : null}
          </div>

          <div className="mt-5 lg:mt-0 lg:flex-grow lg:order-1">
            <div className="flex items-center cursor-pointer hover:underline">
              <div>
                <FiChevronLeft className="text-xl text-gray-800" />
              </div>
              <a href={`${window.location.protocol}//${window.location.hostname}/cart`}>
                <div className="ml-1 text-sm text-gray-800 select-none">Return to cart</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(PaymentStep)
