import React, { createContext, useReducer, useEffect, useState, useRef, useCallback, useContext } from 'react'
import AWS from 'aws-sdk'
import { LocationContext } from 'contexts/LocationContext'
import ThirdPartyPixels from 'components/ThirdPartyPixels'
import nanoid from 'nanoid'

const EventTrackerContext = createContext()

const EventTrackerContextProvider = ({ children }) => {
  const [awsReady, setAwsReady] = useState(false)
  const [locationReady, setLocationReady] = useState(false)
  const [batchQueue, setBatchQueue] = useState([])

  const batchTimer = useRef()

  const { location } = useContext(LocationContext)

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_CHECKOUT_IDS': {
        return { ...state, checkoutIds: action.checkoutIds }
      }
      default:
        return state
    }
  }

  const setCheckoutIds = checkoutIds => {
    dispatch({ type: 'SET_CHECKOUT_IDS', checkoutIds })
  }

  const getSession = useCallback(() => {
    if (window.localStorage.getItem('session')) {
      const session = JSON.parse(window.localStorage.getItem('session'))

      if (session.expiresAt < new Date().getTime()) {
        const sessionId = nanoid()

        window.localStorage.setItem(
          'session',
          JSON.stringify({
            expiresAt: new Date().getTime() + 11 * 60000,
            id: sessionId,
          })
        )
      } else {
        window.localStorage.setItem(
          'session',
          JSON.stringify({
            expiresAt: new Date().getTime() + 11 * 60000,
            id: session.id,
          })
        )
      }
    } else {
      const sessionId = nanoid()

      window.localStorage.setItem(
        'session',
        JSON.stringify({
          expiresAt: new Date().getTime() + 11 * 60000,
          id: sessionId,
        })
      )
    }

    return JSON.parse(window.localStorage.getItem('session')).id
  }, [])

  const batch = useCallback(event => {
    setBatchQueue(queue => {
      // Prevent Purchase from firing multiple times within 30 mins
      if (!queue.find(e => new Date().getTime() - e.timestamp < 30 * 60 * 1000 && e.type === 'PURCHASE')) {
        return [...queue, { ...event, id: nanoid(), timestamp: new Date().getTime() }]
      } else {
        return queue
      }
    })
  }, [])

  const track = useCallback(
    event => {
      batch(event)
    },
    [batch]
  )

  const context = {
    checkoutIds: ['', '', ''],
    setCheckoutIds,
    track,
  }

  const [state, dispatch] = useReducer(reducer, context)

  const kinesisPut = useCallback(
    queue => {
      if (queue.length < 1) return false

      const records = queue.map(r => {
        return {
          ...r,
          ip: location.query || '',
          location: location || {},
          timestamp: new Date().getTime(),
          useragent: window.navigator.userAgent,
          resource: 'CHECKOUT_APP',
          checkout: state.checkoutIds[0],
          shop: state.checkoutIds[1],
          funnel: state.checkoutIds[2],
          session: getSession(),
          id: nanoid(),
        }
      })

      console.log('BC Events', records)

      // const kinesis = new AWS.Kinesis()
      // kinesis.putRecords(
      //   {
      //     StreamName: process.env.REACT_APP_AWS_STREAM_NAME,
      //     Records: records.map(r => ({
      //       Data: `${JSON.stringify(r)}\n`,
      //       PartitionKey: 'partition-' + AWS.config.credentials.identityId,
      //     })),
      //   },
      //   (err, data) => {}
      // )
    },
    [location, state.checkoutIds, getSession]
  )

  useEffect(() => {
    if (awsReady && batchQueue.length > 0 && locationReady) {
      clearTimeout(batchTimer.current)

      batchTimer.current = setTimeout(() => {
        kinesisPut(batchQueue)
        setBatchQueue([])
      }, 2000)
    }
  }, [awsReady, kinesisPut, setBatchQueue, batchQueue, locationReady])

  useEffect(() => {
    AWS.config.region = process.env.REACT_APP_AWS_REGION
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    })

    AWS.config.credentials.get(err => {
      if (!err) {
        setAwsReady(true)
      }
    })
  }, [])

  useEffect(() => {
    if (location) setLocationReady(true)
  }, [location, setLocationReady])

  return (
    <EventTrackerContext.Provider value={state}>
      {children}
      <ThirdPartyPixels shopId={state.checkoutIds[1]} events={batchQueue} />
    </EventTrackerContext.Provider>
  )
}

const EventTrackerContextConsumer = EventTrackerContext.Consumer

export { EventTrackerContext, EventTrackerContextProvider, EventTrackerContextConsumer }
