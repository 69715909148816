import React, { useContext } from 'react'
import { ProcessorContext } from 'contexts/ProcessorContext'
import TwoStepPayPal from 'components/digital-wallets/TwoStepPayPal'

const DigitalWallets = ({ checkout }) => {
  const { paypalOrderId } = useContext(ProcessorContext)

  // TODO: THIS WILL CHANGE WHEN WE SUPPORT MORE DIGITAL WALLETS
  if (!checkout.funnel.paypal || paypalOrderId) return null

  return (
    <div className="mt-2 mb-4">
      <div className="px-4 m-auto -mb-2 text-sm text-center">
        <span className="px-2 bg-white">Express Checkout</span>
      </div>

      <div className="px-4 pt-5 pb-4 border border-gray-300 rounded">
        <TwoStepPayPal total={checkout.total} clientId={checkout.funnel.paypal.clientId} />
      </div>

      <div className="mt-8 mb-2 text-center border-t border-gray-300">
        <div className="-mt-3">
          <span className="px-2 text-sm text-gray-600 bg-white">OR</span>
        </div>
      </div>
    </div>
  )
}

export default DigitalWallets
