import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { fetch } from 'whatwg-fetch'
import { shopifyCart } from 'utils/shopify'
import rison from 'rison'

const CREATE = gql`
  mutation CreateCheckout(
    $shopId: ID!
    $cartTags: [String!]!
    $items: [LineItemInput!]!
    $token: String!
    $currency: String!
    $discount: String
    $note: String
  ) {
    createCheckout(
      shopId: $shopId
      cartTags: $cartTags
      items: $items
      token: $token
      currency: $currency
      discount: $discount
      note: $note
    ) {
      id
    }
  }
`

const CONTINUE = gql`
  mutation ContinueCheckout(
    $shopId: ID!
    $id: ID!
    $cartTags: [String!]!
    $items: [LineItemInput!]!
    $discount: String
    $note: String
  ) {
    continueCheckout(shopId: $shopId, id: $id, cartTags: $cartTags, items: $items, discount: $discount, note: $note) {
      id
    }
  }
`

const InitCheckoutPage = ({ location, history }) => {
  const { shopId, checkoutId, algo, data, discount } = queryString.parse(location.search)

  const decodedData = algo === 'rison' ? rison.decode(data) : JSON.parse(window.atob(data))

  const { items, tags: cartTags, token, currency, note } = decodedData

  const [hasUrlErrors, setHasUrlErrors] = useState(false)

  const [createCheckout, { loading: createLoading, error: createError, called: createCalled }] = useMutation(CREATE, {
    onCompleted: data => {
      window.localStorage.setItem('checkoutId', data.createCheckout.id)
      history.replace(`${process.env.PUBLIC_URL}/checkouts/${data.createCheckout.id}`)
    },
    onError: err => {
      console.log(err)
    },
  })

  const [continueCheckout, { loading: continueLoading, error: continueError, called: continueCalled }] = useMutation(
    CONTINUE,
    {
      onCompleted: data => {
        window.localStorage.setItem('checkoutId', data.continueCheckout.id)
        history.replace(`${process.env.PUBLIC_URL}/checkouts/${data.continueCheckout.id}`)
      },
      onError: err => {
        console.log(err)
      },
    }
  )

  const getItemsWithFallback = async items => {
    try {
      if (items.length > 0) {
        return items
      } else {
        const cart = await shopifyCart()
        return cart.items.reduce((acc, item) => {
          return [
            ...acc,
            {
              shopifyProductId: item.product_id,
              shopifyVariantId: item.variant_id,
              quantity: item.quantity,
              properties: item.properties,
            },
          ]
        }, [])
      }
    } catch (err) {
      return []
    }
  }

  const create = useCallback(async () => {
    let fallbackItems = await getItemsWithFallback(items)

    if (shopId && cartTags && token && currency && fallbackItems.length > 0) {
      if (process.env.NODE_ENV === 'production') {
        //Reset Cart
        await (await fetch('/cart/clear.js')).json()

        await fetch('/cart/add.js', {
          method: 'POST',
          body: JSON.stringify({ items: fallbackItems.map(i => (
            {
              id: i.shopifyVariantId,
              quantity: i.quantity,
              properties: i.properties
            }
          ))}),
          headers: { 'Content-Type': 'application/json' },
        })
      }

      createCheckout({
        variables: {
          shopId,
          cartTags,
          items: fallbackItems,
          token,
          currency,
          discount: discount ? discount : undefined,
          note
        },
      })
    } else {
      setHasUrlErrors(true)
    }
  }, [shopId, cartTags, token, currency, items, discount, note, createCheckout])

  const contin = useCallback(async () => {
    let fallbackItems = await getItemsWithFallback(items)

    if (checkoutId && shopId && cartTags && fallbackItems.length > 0) {
      continueCheckout({
        variables: {
          id: checkoutId,
          shopId,
          cartTags,
          items: fallbackItems,
          discount: discount ? discount : undefined,
          note
        },
      })
    } else {
      setHasUrlErrors(true)
    }
  }, [checkoutId, shopId, cartTags, items, discount, note, continueCheckout])

  useEffect(() => {
    if (checkoutId) {
      if (!continueCalled) {
        contin()
      }
    } else {
      if (!createCalled) {
        create()
      }
    }
  }, [checkoutId, createCalled, continueCalled, create, contin])

  return (
    <>
      {createLoading || continueLoading ? (
        <div className="w-full mt-8 text-center text-gray-500">Loading Your Checkout...</div>
      ) : null}
      {createError || continueError || hasUrlErrors ? (
        <div className="flex items-center justify-center w-full h-screen">
          <div>
            <div className="text-lg text-center text-red-400">Whoops! Something does not look right.</div>
            <div className="max-w-lg mx-auto mt-2 text-sm text-center text-gray-800">
              Please try again by clicking the button below to retry or return to your cart if that does not work.
            </div>

            <div
              onClick={() => contin()}
              className="p-3 mt-6 text-center text-white bg-black rounded cursor-pointer select-none">
              Try Again
            </div>

            <a className="mt-4" href={`${window.location.protocol}//${window.location.hostname}`}>
              <div className="p-3 mt-3 text-center text-white bg-black rounded cursor-pointer select-none">
                Return To Cart
              </div>
            </a>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default withRouter(InitCheckoutPage)
