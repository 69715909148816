import ReactPixel from 'react-snapchat-pixel'

const ELIGIBLE_EVENTS = ['PAGE_VIEW', 'INIT_CHECKOUT', 'PURCHASE', 'OFFER_ACCEPTED']

const eventNameForType = type => {
  switch (type) {
    case 'PAGE_VIEW':
      return 'PAGE_VIEW'
    case 'INIT_CHECKOUT':
      return 'START_CHECKOUT'
    case 'PURCHASE':
      return 'PURCHASE'
    case 'OFFER_ACCEPTED':
      return 'PURCHASE'
    default:
      return ''
  }
}

const eventDataForType = event => {
  switch (event.type) {
    case 'INIT_CHECKOUT':
      return {
        item_ids: event.data.checkout.checkoutLineItems.map(item => item.productVariant.sku),
      }
    case 'PURCHASE':
      return {
        price: event.data.value,
        currency: event.data.currency,
        transaction_id: event.data.orderId,
        description: 'Better Cart Original Purchase',
      }
    case 'OFFER_ACCEPTED':
      return {
        item_ids: [event.data.selectedVariant.sku],
        price: event.data.total,
        currency: 'USD',
        description: 'Better Cart Offer Accepted',
        transaction_id: `${event.data.order.id}_${event.data.stepId}`,
      }
    default:
      return {}
  }
}

export const snapInit = trackers => {
  trackers.forEach(tracker => {
    ReactPixel.init(
      tracker.data.trackingId,
      {},
      {
        debug: process.env.NODE_ENV === 'development',
      }
    )
  })
}

export const snapFire = events => {
  events.forEach(event => {
    if (event.type === 'PAGE_VIEW') {
      ReactPixel.pageView()
    } else if (ELIGIBLE_EVENTS.includes(event.type)) {
      ReactPixel.track(eventNameForType(event.type), eventDataForType(event))
    }
  })
}
