/** @jsx jsx */
import { useContext, useEffect } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import { LocationContext } from 'contexts/LocationContext'
import AddressForm from 'components/AddressForm'
import { css, jsx } from '@emotion/core'
import Color from 'color'
import { useField, useFormikContext } from 'formik'

const BillingInformation = ({ checkout }) => {
  const { theme } = useContext(ThemeContext)
  const { location } = useContext(LocationContext)
  const { values, setFieldValue } = useFormikContext()

  // eslint-disable-next-line
  const [field, meta, helpers] = useField('sameBillingAsShipping')

  const handleSameToggle = same => {
    helpers.setValue(same)
  }

  const sameBillingAsShipping = values['sameBillingAsShipping']

  useEffect(() => {
    if (!sameBillingAsShipping && location && !checkout.billingCountry && !checkout.billingRegion) {
      setFieldValue('billingCountry', location.countryCode, true)
      setFieldValue('billingRegion', location.region, true)
    }
  }, [sameBillingAsShipping, location, checkout.billingCountry, checkout.billingRegion, setFieldValue])

  const branding = css`
    background-color: ${theme.primaryBrandColor};
  `

  return (
    <div className="mt-8">
      <div
        className="text-lg font-semibold"
        style={{
          color: Color(theme.primaryBrandColor).darken(0.25).string(),
        }}>
        Billing Address
      </div>

      <div className="mt-4 border border-gray-300 rounded">
        <div
          className="border-b border-gray-300 cursor-pointer hover:bg-gray-200"
          onClick={() => handleSameToggle(true)}>
          <div className="flex items-center p-4">
            <div className="flex-shrink mr-2">
              <div
                css={sameBillingAsShipping ? branding : null}
                className={`flex rounded-full h-4 w-4 text-center cursor-pointer ${
                  sameBillingAsShipping ? '' : 'bg-white border border-gray-400'
                }`}>
                <div className="w-1 h-1 m-auto bg-white rounded-full" />
              </div>
            </div>
            <div className="flex-grow text-sm text-gray-800">Same as shipping address</div>
          </div>
        </div>

        <div
          className={`cursor-pointer hover:bg-gray-200 ${sameBillingAsShipping ? '' : 'border-b border-gray-300'}`}
          onClick={() => handleSameToggle(false)}>
          <div className="flex items-center p-4">
            <div className="flex-shrink mr-2">
              <div
                css={!sameBillingAsShipping ? branding : null}
                className={`flex rounded-full h-4 w-4 text-center cursor-pointer ${
                  sameBillingAsShipping ? 'bg-white border border-gray-400' : ''
                }`}>
                <div className="w-1 h-1 m-auto bg-white rounded-full" />
              </div>
            </div>
            <div className="flex-grow text-sm text-gray-800">Use a different billing address</div>
          </div>
        </div>

        {sameBillingAsShipping ? null : (
          <div className="p-5">
            <AddressForm
              namePrefix="billing"
              phoneOptional={checkout.funnel.phoneOptional}
              googleApiKey={checkout.funnel.shop.googleAutocompleteApiKey}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default BillingInformation
