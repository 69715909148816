import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { fetch } from 'whatwg-fetch'

const CREATE = gql`
  mutation CreatePermalinkCheckout(
    $sid: String!
    $tag: String!
    $items: [PermalinkLineItemInput!]!
    $discount: String
    $email: String
  ) {
    createPermalinkCheckout(sid: $sid, tag: $tag, items: $items, discount: $discount, email: $email) {
      id
      email
    }
  }
`

const PermalinkPage = ({ location, history }) => {
  const { sid, tag, variants, discount, email, ...rest } = queryString.parse(location.search)

  const extraParams = Object.keys(rest).reduce((acc, key, index) => acc += `${index === 0 ? "?" : "&"}${key}=${rest[key]}`, "")

  const [hasUrlErrors, setHasUrlErrors] = useState(false)

  const items = variants.split(',').map(variant => ({
    shopifyVariantId: variant.split(':')[0],
    quantity: parseInt(variant.split(':')[1]),
  }))

  const [createCheckout, { loading, error, called }] = useMutation(CREATE, {
    onCompleted: data => {
      history.replace(`${process.env.PUBLIC_URL}/checkouts/${data.createPermalinkCheckout.id}${extraParams}`)
    },
    onError: err => {
      console.log(err)
      //window.location = `${window.location.protocol}//${window.location.hostname}/`
    },
  })

  const createShopifyCart = useCallback(async () => {
    if (tag && sid && items.length > 0) {
      if (process.env.NODE_ENV === 'production') {
        //Reset Cart
        await (await fetch('/cart/clear.js')).json()

        await fetch('/cart/add.js', {
          method: 'POST',
          body: JSON.stringify({ items: items.map(i => ({ id: i.shopifyVariantId, quantity: i.quantity })) }),
          headers: { 'Content-Type': 'application/json' },
        })
      }

      createCheckout({
        variables: {
          sid,
          items,
          tag,
          discount,
          email
        },
      })
    } else {
      setHasUrlErrors(true)
    }
  }, [items, sid, tag, discount, email, createCheckout])

  useEffect(() => {
    if (!called) {
      createShopifyCart()
    }
  }, [called, createShopifyCart])

  return (
    <div className="flex items-center justify-center w-full h-screen">
      {loading ? <div className="text-lg text-center text-gray-400">Loading Checkout...</div> : null}
      {error || hasUrlErrors ? (
        <div>
          <div className="text-lg text-center text-red-400">Whoops! Something went wrong!</div>
          <a href={`${window.location.protocol}//${window.location.hostname}/`}>
            <div className="p-3 mt-3 text-center text-white bg-black rounded cursor-pointer">Return To Safty</div>
          </a>
        </div>
      ) : null}
    </div>
  )
}

export default withRouter(PermalinkPage)
