import React, { useState, useEffect } from 'react'

const ProductHero = ({ product, selectedProductImageId }) => {
  const [selectedProductImage, setSelectedProductImage] = useState(product.productImages[0])

  useEffect(() => {
    if (selectedProductImageId) {
      const selected = product.productImages.find(i => i.id === selectedProductImageId)
      if (selected) {
        setSelectedProductImage(product.productImages.find(i => i.id === selectedProductImageId))
      }
    } else {
      setSelectedProductImage(product.productImages[0])
    }
  }, [selectedProductImageId, product.productImages])

  return (
    <div className="text-center">
      {selectedProductImage ? (
        <img className="h-64 m-auto object-contain" src={selectedProductImage.src} alt="product" />
      ) : null}
    </div>
  )
}

export default ProductHero
