import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { FiTag } from 'react-icons/fi'

const OrderFinancialsTable = ({ order }) => {
  const { subtotal, savings, discount, taxes, shipping, total } = order

  return (
    <>
      <div className="pt-4 border-t border-gray-300">
        <div className="flex items-center">
          <div className="flex-grow">
            <div className="text-sm text-gray-800">Subtotal</div>
          </div>
          <div className="flex-shrink">
            {subtotal ? (
              <CurrencyFormat
                value={subtotal}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            ) : (
              <div>N/A</div>
            )}
          </div>
        </div>

        {savings > 0 ? (
          <div className="flex items-center mt-4">
            <div className="flex-grow">
              <div className="flex items-center">
                <div className="mr-2 text-sm text-gray-800">Savings</div>

                {discount.discount && discount.discount.code ? (
                  <>
                    <div className="">
                      <FiTag className="text-lg text-gray-800" />
                    </div>
                    <div className="mx-1 text-sm font-semibold text-gray-800 uppercase">{discount.discount.code}</div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="flex-shrink">
              -{' '}
              <CurrencyFormat
                value={savings}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            </div>
          </div>
        ) : null}

        {taxes && taxes > 0 ? (
          <div className="flex items-center mt-4">
            <div className="flex-grow">
              <div className="text-sm text-gray-800">Taxes</div>
            </div>
            <div className="flex-shrink">
              <CurrencyFormat
                value={taxes}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            </div>
          </div>
        ) : null}
        <div className="flex items-center mt-4">
          <div className="flex-grow">
            <div className="text-sm text-gray-800">Shipping</div>
          </div>
          <div className="flex-shrink">
            {shipping > 0 ? (
              <CurrencyFormat
                value={shipping}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            ) : (
              <div className="">FREE</div>
            )}
          </div>
        </div>
        <div className="flex items-center pt-4 mt-4 border-t border-gray-300">
          <div className="flex-grow">
            <div className="text-lg text-gray-800">Total</div>
          </div>
          <div className="flex-shrink">
            <div className="flex items-center">
              <div className="text-xs font-semibold text-gray-600 uppercase">USD</div>
              <div className="ml-2 text-xl font-semibold text-gray-800">
                {total ? (
                  <CurrencyFormat
                    value={total}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                ) : (
                  <div>N/A</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderFinancialsTable
