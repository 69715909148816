import React from 'react'
import ContactInformation from 'components/ContactInformation'
import ShippingInformation from 'components/ShippingInformation'

const ShippingAddressStep = ({ checkout }) => {
  return (
    <>
      <ContactInformation tcpa={checkout.requiresTcpa} />

      <ShippingInformation checkout={checkout} />
    </>
  )
}

export default ShippingAddressStep
