import { gql } from 'apollo-boost'

export const CHECKOUT = gql`
  query checkout($id: ID!) {
    checkout(id: $id) {
      id
      email
      acceptsMarketing
      sameBillingAsShipping
      shippingMethod
      pending
      completed
      requiresTcpa
      subtotal
      savings
      taxes
      total
      currency
      acceptsProductBump

      shippingFirstName
      shippingLastName
      shippingLine1
      shippingLine2
      shippingCity
      shippingRegion
      shippingCountry
      shippingZipcode
      shippingPhone

      billingFirstName
      billingLastName
      billingLine1
      billingLine2
      billingCity
      billingRegion
      billingCountry
      billingZipcode
      billingPhone

      shippingRate {
        id
        name
        price
      }

      shippingRates {
        id
        name
        price
      }

      checkoutLineItems {
        id
        shopifyProductId
        shopifyVariantId
        quantity
        properties
        isBump
        discountedPrice
        discounts

        productVariant {
          id
          title
          price
          sku

          product {
            id
            title
            handle
          }
        }
      }

      appliedDiscount {
        code
        value
      }

      currentFunnelStep {
        id
      }

      order {
        id
      }

      funnel {
        id
        name
        tag
        checkoutTemplate
        phoneOptional
        bumpProductDescription
        bumpProductBgColor
        bumpProductTextColor
        bumpProductPriceColor
        bumpProductPriceBgColor
        bumpProductImage
        showSummary
        showTimer
        timerDescription
        timerText
        timerBgColor
        timerTextColor
        showExpressCheckouts

        bumpProduct {
          id
          title
          shopifyId

          productImages {
            id
            src
          }

          productVariants {
            id
            shopifyId
            default
            price
            compareAtPrice

            productImage {
              id
              src
            }
          }
        }

        shop {
          id
          name
          shopifyHostname
          logo
          headerBackground
          trustBadge
          primaryBrandColor
          googleAutocompleteApiKey
          googleTagManagerContainerId
          fontFamily
          hasShippingZones

          customScripts {
            id
            body
            async
            enabled
            location
          }
        }

        shopPaymentGateway {
          id
          publicCreds

          paymentGateway {
            id
            name
            logo
            slug
            gatewayType
            appPublicCreds
          }
        }

        paypal {
          clientId
        }
      }
    }
  }
`

export const CHECKOUT_SHIPPING_RATES = gql`
  query checkout($id: ID!) {
    checkout(id: $id) {
      id
      shippingCountry
      shippingRegion

      shippingRate {
        id
      }

      shippingRates {
        id
        name
        price
      }
    }
  }
`

export const SIMPLE_LINE_ITEMS = gql`
  query checkout($id: ID!) {
    checkout(id: $id) {
      id

      checkoutLineItems {
        id
        shopifyProductId
        shopifyVariantId
        quantity
        properties
        isBump
        discountedPrice
        discounts
      }
    }
  }
`

export const CHECKOUT_LINE_ITEMS = gql`
  query checkout($id: ID!) {
    checkout(id: $id) {
      id

      appliedDiscount {
        code
        value
      }

      checkoutLineItems {
        id
        shopifyProductId
        shopifyVariantId
        quantity
        subtotal
        savings
        total
        properties
        isBump
        discountedPrice
        discounts

        productVariant {
          id
          barcode
          sku
          price
          compareAtPrice
          title
          default
          shopifyId
          option1
          option2
          option3
          requiresShipping
          taxable

          productImage {
            id
            src
            width
            height
          }

          product {
            id
            title
            handle
            description
            shopifyId

            productImages {
              id
              src
              width
              height
            }
          }
        }
      }
    }
  }
`

export const CHECKOUT_FINANCIALS = gql`
  query checkout($id: ID!) {
    checkout(id: $id) {
      id
      shippingMethod
      subtotal
      savings
      taxes
      shipping
      total

      funnel {
        id
        shop {
          id
          hasShippingZones
        }
      }

      shippingRate {
        id
        price
      }

      appliedDiscount {
        code
        value
      }
    }
  }
`

export const CHECKOUT_LINE_ITEM_FINANCIALS = gql`
  query checkout($id: ID!) {
    checkout(id: $id) {
      id
      shippingMethod
      subtotal
      savings
      taxes
      shipping
      total
      acceptsProductBump

      checkoutLineItems {
        id
        shopifyProductId
        shopifyVariantId
        quantity
        subtotal
        savings
        total
        isBump
        properties
        discountedPrice
        discounts

        productVariant {
          id
          barcode
          sku
          price
          compareAtPrice
          title
          default
          shopifyId
          option1
          option2
          option3
          requiresShipping
          taxable

          productImage {
            id
            src
            width
            height
          }

          product {
            id
            title
            handle
            description
            shopifyId

            productImages {
              id
              src
              width
              height
            }
          }
        }
      }
    }
  }
`

export const UPDATE_CHECKOUT = gql`
  mutation UpdateCheckout($id: ID!, $checkout: UpdateCheckoutInput, $shippingMethod: UpdateShippingMethodInput) {
    updateCheckout(id: $id, checkout: $checkout, shippingMethod: $shippingMethod) {
      id
      email
      acceptsMarketing
      completed
      sameBillingAsShipping
      shippingMethod

      subtotal
      savings
      taxes
      shipping
      total

      shippingRate {
        id
        name
        price
      }

      shippingRates {
        id
        name
        price
      }

      shippingFirstName
      shippingLastName
      shippingLine1
      shippingLine2
      shippingCity
      shippingRegion
      shippingCountry
      shippingZipcode
      shippingPhone

      billingFirstName
      billingLastName
      billingLine1
      billingLine2
      billingCity
      billingRegion
      billingCountry
      billingZipcode
      billingPhone
    }
  }
`

export const UPDATE_LINE_ITEM = gql`
  mutation UpdateLineItem($id: ID!, $lineItem: UpdateLineItemInput!) {
    updateLineItem(id: $id, lineItem: $lineItem) {
      id
      subtotal
      savings
      taxes
      shipping
      total

      checkoutLineItems {
        id
        subtotal
        savings
        total
        shopifyVariantId
        quantity
        properties
        isBump
        discountedPrice
        discounts
      }
    }
  }
`

export const UPDATE_CHECKOUT_BUMP = gql`
  mutation UpdateCheckoutBump($id: ID!, $accepted: Boolean!) {
    updateCheckoutBump(id: $id, accepted: $accepted) {
      id
      subtotal
      savings
      taxes
      shipping
      total
      acceptsProductBump

      checkoutLineItems {
        id
        subtotal
        savings
        total
        shopifyVariantId
        quantity
        properties
        isBump
        discountedPrice
        discounts
      }
    }
  }
`

export const APPLY_DISCOUNT = gql`
  mutation ApplyDiscount($checkoutId: ID!, $code: String!) {
    applyDiscount(checkoutId: $checkoutId, code: $code) {
      id
      shippingMethod
      subtotal
      savings
      taxes
      shipping
      total

      appliedDiscount {
        code
        value
      }

      checkoutLineItems {
        id
        subtotal
        savings
        total
        properties
        isBump
        discountedPrice
        discounts
      }

      shippingRate {
        id
        name
        price
      }

      shippingRates {
        id
        name
        price
      }
    }
  }
`

export const DETATCH_DISCOUNT = gql`
  mutation DetatchDiscount($checkoutId: ID!) {
    detatchDiscount(checkoutId: $checkoutId) {
      id
      shippingMethod
      subtotal
      taxes
      shipping
      total

      appliedDiscount {
        code
        value
      }

      checkoutLineItems {
        id
        subtotal
        savings
        total
        properties
        isBump
        discountedPrice
        discounts
      }

      shippingRate {
        id
        name
        price
      }

      shippingRates {
        id
        name
        price
      }
    }
  }
`

export const CREATE_ORDER = gql`
  mutation createOrder(
    $checkoutId: ID!
    $idempotencyKey: String!
    $paymentNonce: String!
    $paymentNonceSource: String!
    $browserIp: String
    $cid: String
  ) {
    createOrder(
      checkoutId: $checkoutId
      idempotencyKey: $idempotencyKey
      paymentNonce: $paymentNonce
      paymentNonceSource: $paymentNonceSource
      browserIp: $browserIp
      cid: $cid
    ) {
      id
      total
      currency
      shipping
      taxes
      discount

      orderLineItems {
        id
        productTitle
        variantTitle
        shopifyProductId
        shopifyVariantId
        quantity
        price
        properties

        productVariant {
          id
          price
          sku
        }
      }

      checkout {
        id

        currentFunnelStep {
          id
        }

        funnel {
          id
          name
          tag
        }
      }
    }
  }
`

export const FUNNEL_STEP = gql`
  query publicFunnelStep($id: ID!) {
    publicFunnelStep(id: $id) {
      id
      offerType
      offerValue
      shippingCost
      enableQuantity
      firstUpsell

      funnel {
        id
        path

        shop {
          id
          fontFamily
        }
      }

      product {
        id
        shopifyId
        title
        handle

        productVariants {
          id
          shopifyId
          price
          sku
          compareAtPrice
          option1
          option2
          option3
          taxable
          soldOut

          productImage {
            id
            src
          }

          product {
            id
            shopifyId
          }
        }

        productOptions {
          id
          name
          position
          values
        }

        productImages {
          id
          src
          position
        }
      }

      design {
        id
        status
        productTitle
        productTitleHtml
        headline
        headlineColor
        headlineBgColor
        offerHeadline
        offerSubHeadline
        offerDescription
        timerText
        timerColor
        timerTextColor
        timerBgColor
        wasLabel
        nowLabel
        nowColor
        saveLabel
        saveColor
        variantPlaceholder
        confirmText
        confirmTextColor
        confirmBgColor
        declineText
        declineColor
        highlightBannerText
        highlightBannerColor
        highlightBannerBgColor
        productDetails
        descriptionHtml
        enableConfetti
      }
    }
  }
`

export const FUNNEL_STEP_ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      shopifyId
      shopifyOrderNumber
      subtotal
      total
      savings
      shipping
      taxes
      shopifyTaxes

      customer {
        id
        email
        phone
      }

      shippingAddress {
        id
        firstName
        lastName
        line1
        line2
        city
        region
        country
        zipcode
        phone
      }

      orderLineItems {
        id
        productTitle
        variantTitle
        quantity
        price
        totalDiscount
        total
        properties

        productVariant {
          id
          shopifyId
          sku
          title
          default
          option1
          option2
          option3
          taxable

          productImage {
            id
            src
          }

          product {
            id
            title
            handle
            shopifyId

            productImages {
              id
              src
            }
          }
        }
      }

      customerPaymentMethod {
        gatewayType
      }

      shop {
        id
        fontFamily
      }

      checkout {
        id
        completed

        funnel {
          id
          name
          tag

          paypal {
            clientId
          }

          shop {
            id

            customScripts {
              id
              enabled
              location
              body
            }
          }
        }
      }
    }
  }
`

export const ACCEPT_OFFER = gql`
  mutation acceptOffer(
    $orderId: ID!
    $funnelStepId: ID!
    $productVariantId: ID!
    $quantity: Int!
    $idempotencyKey: String!
    $paymentNonceSource: String!
    $paymentNonce: String
  ) {
    acceptOffer(
      orderId: $orderId
      funnelStepId: $funnelStepId
      productVariantId: $productVariantId
      quantity: $quantity
      idempotencyKey: $idempotencyKey
      paymentNonceSource: $paymentNonceSource
      paymentNonce: $paymentNonce
    ) {
      id
      sku
      productTitle
      variantTitle
      quantity
      price
      total
    }
  }
`

export const DECLINE_OFFER = gql`
  mutation declineOffer($orderId: ID!, $funnelStepId: ID!) {
    declineOffer(orderId: $orderId, funnelStepId: $funnelStepId)
  }
`

export const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      shopifyId
      shopifyOrderNumber
      subtotal
      savings
      shipping
      shippingMethod
      taxes
      total
      discount
      currency

      shippingRate {
        id
        name
        price
      }

      customer {
        id
        email
        phone
      }

      customerPaymentMethod {
        id
        gatewayType
      }

      shippingAddress {
        id
        firstName
        lastName
        line1
        line2
        city
        region
        country
        zipcode
      }

      billingAddress {
        id
        firstName
        lastName
        line1
        line2
        city
        region
        country
        zipcode
      }

      orderLineItems {
        id
        productTitle
        variantTitle
        quantity
        price
        totalDiscount
        total
        properties

        productVariant {
          id
          shopifyId
          sku
          title
          default
          option1
          option2
          option3

          productImage {
            id
            src
          }

          product {
            id
            title
            handle
            shopifyId

            productImages {
              id
              src
            }
          }
        }
      }

      shop {
        id
        name
        shopifyHostname
        logo
        headerBackground
        primaryBrandColor
        fontFamily

        customScripts {
          id
          enabled
          body
          location
        }
      }

      checkout {
        id
        shopifyCheckoutToken

        funnel {
          id
          name
          tag
        }
      }
    }
  }
`

export const SHOP_POLICIES = gql`
  query publicShop($id: ID!) {
    publicShop(id: $id) {
      id

      shopifyPolicies {
        title
        url
        body
      }
    }
  }
`

export const SHOP_TRACKING_PIXELS = gql`
  query publicShop($id: ID!) {
    publicShop(id: $id) {
      id
      googleTagManagerContainerId

      trackingPixels {
        id
        name
        platform
        enabled
        data
      }
    }
  }
`
