import React, { useState, useEffect, useContext } from 'react'
import { EventTrackerContext } from 'contexts/EventTrackerContext'
import { ThemeContext } from 'contexts/ThemeContext'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { ORDER } from 'graphql/checkouts'
import CurrencyFormat from 'react-currency-format'
import { FiShoppingCart, FiChevronUp, FiChevronDown, FiCheck } from 'react-icons/fi'
import CheckoutHeader from 'components/CheckoutHeader'
import OrderLineItems from 'components/OrderLineItems'
import OrderFinancialsTable from 'components/OrderFinancialsTable'
import Footer from 'components/Footer'
import PageWindowData from 'components/PageWindowData'
import CustomScripts from 'components/CustomScripts'

const ThankYouPage = ({ match, location }) => {
  const { theme, themeDispatch } = useContext(ThemeContext)

  const { setCheckoutIds, track } = useContext(EventTrackerContext)

  const { orderId } = match.params

  const [showSummary, setShowSummary] = useState(false)
  const [showSummaryManual, setShowSummaryManual] = useState(false)

  const { loading, error, data } = useQuery(ORDER, {
    variables: {
      id: orderId,
    },
  })

  const handleResize = () => {
    setShowSummary(window.innerWidth > 1024 || showSummaryManual)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Set Tracking Checkout IDs for events
  useEffect(() => {
    if (data && data.order) {
      setCheckoutIds([data.order.checkout.id, data.order.shop.id, data.order.checkout.funnel.id])
    }
  }, [data, setCheckoutIds])

  useEffect(() => {
    track({
      type: 'PAGE_VIEW',
      data: {
        uri: location.pathname,
        search: location.search,
      },
    })
  }, [track, location])

  useEffect(() => {
    if (data && data.order) {
      track({
        type: 'ORDER_COMPLETED',
        data,
      })
    }
  }, [track, data])

  useEffect(() => {
    if (data && data.order.shop.primaryBrandColor) {
      themeDispatch({ type: 'UPDATE_PRIMARY_COLOR', color: data.order.shop.primaryBrandColor })
    }
  }, [data, themeDispatch])

  useEffect(() => {
    setShowSummary(showSummaryManual)
  }, [showSummaryManual])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    handleResize()
  })

  if (error) {
    return (
      <div className="p-6 text-red-400">Whoops! There was an error loading your order. Please contact support.</div>
    )
  }

  //TODO: THIS SUCKS
  if (loading) {
    return <div className="w-full mt-8 text-center text-gray-500">Loading Your Order...</div>
  }

  return (
    <div className="w-full h-full text-gray-800">
      <div className="flex flex-col h-full">
        <CheckoutHeader
          headerBackground={data.order.shop.headerBackground}
          logo={data.order.shop.logo}
          name={data.order.shop.name}
        />

        <div
          className="flex-shrink py-4 border-b border-gray-300 cursor-pointer select-none lg:hidden"
          onClick={() => setShowSummaryManual(!showSummaryManual)}>
          <div className="flex items-center max-w-xl px-4 m-auto lg:px-12 lg:max-w-6xl">
            <div className="flex-shrink">
              <FiShoppingCart className="text-xl" style={{ color: theme.primaryBrandColor }} />
            </div>
            <div className="flex-grow ml-2 text-sm" style={{ color: theme.primaryBrandColor }}>
              {showSummary ? (
                <div className="flex items-center">
                  <div className="flex-shrink mr-1">Hide order summary</div>
                  <div className="flex-shrink">
                    <FiChevronUp />
                  </div>
                </div>
              ) : (
                <div className="flex items-center">
                  <div className="flex-shrink mr-1">Show order summary</div>
                  <div className="flex-shrink">
                    <FiChevronDown />
                  </div>
                </div>
              )}
            </div>
            <div className="flex-shrink text-lg font-normal text-gray-800">
              <CurrencyFormat
                value={data.order.total}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            </div>
          </div>
        </div>

        <div className="flex-grow w-full h-auto max-w-xl m-auto lg:px-12 lg:max-w-6xl">
          <div className="flex flex-col h-full lg:flex-row">
            {/* LEFT */}
            <div className="flex-grow order-2 w-full lg:flex-grow lg:w-7/12 lg:order-1 lg:pt-12 lg:pr-16 lg:border-r lg:border-gray-300">
              <div className="flex flex-col h-full px-4 lg:px-0">
                <div className="flex-grow">
                  <div className="flex items-center mt-4 lg:mt-0">
                    <div
                      className="p-2 text-4xl border-2 rounded-full"
                      style={{ color: theme.primaryBrandColor, borderColor: theme.primaryBrandColor }}>
                      <FiCheck />
                    </div>
                    <div className="ml-3">
                      {data.order.shopifyOrderNumber ? (
                        <div className="text-base font-light text-gray-600">
                          <span className="align-top">Order {data.order.shopifyOrderNumber}</span>
                        </div>
                      ) : null}
                      <div className="text-3xl text-gray-800">Thank you {data.order.shippingAddress.firstName}!</div>
                    </div>
                  </div>

                  <div className="p-4 mt-6 text-gray-800 border border-gray-300 rounded">
                    <div className="text-xl font-semibold">Customer Information</div>

                    <div className="flex flex-wrap mt-4 lg:flex-no-wrap">
                      <div className="w-full lg:w-1/2">
                        <div className="mb-2 text-base font-semibold">Contact information</div>
                        <div className="text-sm">{data.order.customer.email}</div>
                      </div>
                      <div className="w-full mt-6 lg:w-1/2 lg:mt-0">
                        <div className="mb-2 text-base font-semibold">Payment method</div>
                        {data.order.customerPaymentMethod.gatewayType === 'PAYPAL_CHECKOUT' ? (
                          <div className="text-sm">PayPal - {data.order.customer.email}</div>
                        ) : (
                          <div className="text-sm">Credit Card</div>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap mt-4 lg:flex-no-wrap">
                      <div className="w-full lg:w-1/2">
                        <div className="mb-2 text-base font-semibold">Shipping address</div>
                        <div className="mb-1 text-sm">{`${data.order.shippingAddress.firstName} ${data.order.shippingAddress.lastName}`}</div>
                        <div className="mb-1 text-sm">{`${data.order.shippingAddress.line1} ${
                          data.order.shippingAddress.line2 || ''
                        }`}</div>
                        <div className="mb-1 text-sm">{`${data.order.shippingAddress.city} ${data.order.shippingAddress.region}`}</div>
                        <div className="mb-1 text-sm">{data.order.shippingAddress.country}</div>
                        <div className="text-sm">{data.order.shippingAddress.phone}</div>
                      </div>
                      <div className="w-full mt-6 lg:w-1/2 lg:mt-0">
                        <div className="mb-2 text-base font-semibold">Billing address</div>
                        <div className="mb-1 text-sm">{`${data.order.billingAddress.firstName} ${data.order.billingAddress.lastName}`}</div>
                        <div className="mb-1 text-sm">{`${data.order.billingAddress.line1} ${
                          data.order.billingAddress.line2 || ''
                        }`}</div>
                        <div className="mb-1 text-sm">{`${data.order.billingAddress.city} ${data.order.billingAddress.region}`}</div>
                        <div className="mb-1 text-sm">{data.order.billingAddress.country}</div>
                        <div className="text-sm">{data.order.billingAddress.phone}</div>
                      </div>
                    </div>

                    <div className="flex flex-wrap mt-4 lg:flex-no-wrap">
                      <div className="w-full lg:w-1/2">
                        <div className="mb-2 text-base font-semibold">Shipping Method</div>
                        {data.order.shippingRate ? (
                          <div className="flex items-center text-sm">
                            <div>{data.order.shippingRate.name}</div>
                            <div className="ml-2 font-bold">
                              <CurrencyFormat
                                value={data.order.shippingRate.price}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'$'}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center text-sm">
                            <div>{data.order.shippingMethod.name}</div>
                            <div className="ml-2 font-bold">
                              <CurrencyFormat
                                value={data.order.shippingMethod.price}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={'$'}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-full mt-6 lg:w-1/2 lg:mt-0" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <a href={`${window.location.protocol}//${window.location.hostname}/`}>
                      <div
                        style={{ backgroundColor: theme.primaryBrandColor }}
                        className="p-4 text-lg font-semibold text-center text-white rounded">
                        Continue Shopping
                      </div>
                    </a>
                  </div>
                </div>

                <div className="flex-shrink">
                  <Footer shopId={data.order.shop.id} />
                </div>
              </div>
            </div>

            {/* RIGHT */}
            <div className="flex-shrink order-1 w-full bg-gray-200 lg:flex-grow lg:w-5/12 lg:order-2 lg:pt-12 lg:px-8 sm:bg-white">
              {showSummary ? (
                <div className="px-4 pb-4 mt-4 border-b border-gray-300">
                  <OrderLineItems lineItems={data.order.orderLineItems} />

                  <div className="mt-4">
                    <OrderFinancialsTable order={data.order} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <PageWindowData page="THANK_YOU_PAGE" data={data.order} />
      <CustomScripts
        data={{
          funnelName: data.order.checkout.funnel.name,
          funnelTag: data.order.checkout.funnel.tag,
          orderTotal: data.order.total,
          orderNumber: data.order.shopifyOrderNumber,
        }}
        scripts={data.order.shop.customScripts.filter(
          s => s.enabled && (s.location === 'ALL' || s.location === 'THANK_YOU')
        )}
      />
    </div>
  )
}

export default withRouter(ThankYouPage)
