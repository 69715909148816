import React from 'react'
import { withRouter } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'

const BreadCrumbs = ({ history, match }) => {
  const navigate = step => {
    history.push({ pathname: `${match.url}`, search: step ? `?step=${step}` : '' })
  }

  return (
    <div className="flex items-center">
      <a href={`${window.location.protocol}//${window.location.hostname}/cart`}>
        <div className="flex-shrink text-xs font-light text-gray-700 cursor-pointer hover:underline" onClick={() => {}}>
          Cart
        </div>
      </a>
      <div className="flex-shrink mx-2">
        <FiChevronRight className="text-xs text-gray-400" />
      </div>
      <div
        className="flex-shrink text-xs font-light text-gray-700 cursor-pointer hover:underline"
        onClick={() => navigate()}>
        Information
      </div>
      <div className="flex-shrink mx-2">
        <FiChevronRight className="text-xs text-gray-400" />
      </div>
      <div
        className="flex-shrink text-xs font-light text-gray-700 cursor-pointer hover:underline"
        onClick={() => navigate('shipping')}>
        Shipping
      </div>
      <div className="flex-shrink mx-2">
        <FiChevronRight className="text-xs text-gray-400" />
      </div>
      <div
        className="flex-shrink text-xs font-light text-gray-700 cursor-pointer hover:underline"
        onClick={() => navigate('payment')}>
        Payment
      </div>
    </div>
  )
}

export default withRouter(BreadCrumbs)
