import React from 'react'
import BreadCrumbs from 'pages/checkouts/templates/ShopifyCheckout/BreadCrumbs'
import ShippingAddressStep from 'pages/checkouts/templates/ShopifyCheckout/steps/ShippingAddressStep'
import ShippingMethodStep from 'pages/checkouts/templates/ShopifyCheckout/steps/ShippingMethodStep'
import PaymentStep from 'pages/checkouts/templates/ShopifyCheckout/steps/PaymentStep'

const LeftContent = ({ step, checkout }) => {
  switch (step) {
    case 'payment':
      return <PaymentStep checkout={checkout} />
    case 'shipping':
      return <ShippingMethodStep checkout={checkout} />
    default:
      return <ShippingAddressStep checkout={checkout} />
  }
}

const LeftNavigator = ({ step, checkout }) => {
  return (
    <>
      <div className="hidden lg:inline-block">
        <BreadCrumbs />
      </div>

      <div className="p-4 lg:pt-4 lg:pl-0">
        <LeftContent step={step} checkout={checkout} />
      </div>
    </>
  )
}

export default LeftNavigator
