import React, { useContext, useState, useMemo } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { FiLoader } from 'react-icons/fi'
import { APPLY_DISCOUNT } from 'graphql/checkouts'
import { useMutation } from '@apollo/react-hooks'
import { buildPermalink } from 'utils/shopify'

const DiscountCodeInput = ({ checkout }) => {
  const { theme } = useContext(ThemeContext)
  const [code, setCode] = useState('')
  const [errors, setErrors] = useState({})

  const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors])

  const [applyDiscount, { loading, error }] = useMutation(APPLY_DISCOUNT, {
    onError: error => {
      if (error.graphQLErrors) {
        const errors = error.graphQLErrors.reduce(
          (hash, e) => ({
            ...hash,
            discountCode: e.message,
          }),
          {}
        )

        setErrors(errors)
      } else {
        errors({
          discountCode: 'Invalid Discount Code',
        })
      }
    },
    onCompleted: () => {
      if (!error) {
        setCode('')
        setErrors({})
      }
    },
  })

  const handleSubmit = () => {
    if (code.length > 0) {
      applyDiscount({
        variables: {
          checkoutId: checkout.id,
          code,
        },
      })
    }
  }

  const handleGiftCard = async () => {
    const url = await buildPermalink(checkout)
    window.location = url
  }

  return (
    <div>
      <div className={`flex ${hasErrors ? 'items-start' : 'items-center'}`}>
        <div className="flex-grow w-4/6 mr-2">
          <FloatingLabelInput
            name="discountCode"
            showErrors={hasErrors}
            label="Discount Code"
            placeholder="Discount Code"
            value={code}
            onChange={e => setCode(e.target.value)}
            errors={errors}
          />
        </div>
        <div className="flex-shrink w-2/6">
          <div
            className="text-sm text-center text-white bg-gray-500 rounded cursor-pointer select-none"
            style={{ padding: '0.85rem', backgroundColor: code.length > 0 ? theme.primaryBrandColor : undefined }}
            onClick={handleSubmit}>
            {loading ? <FiLoader className="m-auto text-lg icon-spin" /> : 'Apply'}
          </div>
        </div>
      </div>
      <div onClick={handleGiftCard} className="mt-3 text-sm text-gray-600 underline cursor-pointer hover:text-gray-800">
        Have a gift card?
      </div>
    </div>
  )
}

export default DiscountCodeInput
