import React, { createContext, useReducer } from 'react'

const ProcessorContext = createContext()

const ProcessorContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_SELECTED_METHOD': {
        return { ...state, selectedMethod: action.method }
      }
      case 'SET_CREDIT_CARD_REQUEST_NONCE': {
        return { ...state, handleCreditCardRequestNonce: action.callback }
      }
      case 'SET_CREDIT_CARD_NONCE_RECEIVED': {
        return { ...state, handleCreditCardNonceReceived: action.callback }
      }
      case 'SET_PAYPAL_APPROVED': {
        return { ...state, handlePaypalApproved: action.callback }
      }
      case 'SET_PAYPAL_ORDER_ID': {
        return { ...state, paypalOrderId: action.orderId }
      }
      case 'SET_ERRORS': {
        return { ...state, errors: action.errors }
      }
      case 'RESET_ERRORS': {
        return { ...state, errors: [] }
      }
      default:
        return state
    }
  }

  const setSelectedMethod = method => {
    dispatch({ type: 'SET_SELECTED_METHOD', method })
  }

  const setCreditCardRequestNonce = callback => {
    dispatch({ type: 'SET_CREDIT_CARD_REQUEST_NONCE', callback }) }

  const setCreditCardNonceReceived = callback => {
    dispatch({ type: 'SET_CREDIT_CARD_NONCE_RECEIVED', callback })
  }

  const setPaypalApproved = callback => {
    dispatch({ type: 'SET_PAYPAL_APPROVED', callback })
  }

  const setPaypalOrderId = orderId => {
    dispatch({ type: 'SET_PAYPAL_ORDER_ID', orderId })

    if (orderId) {
      window.localStorage.setItem('BC_PAYPAL_ORDER_ID', orderId)
    } else {
      window.localStorage.removeItem('BC_PAYPAL_ORDER_ID')
    }
  }

  const setErrors = errors => {
    dispatch({ type: 'SET_ERRORS', errors })
  }

  const resetErrors = () => {
    dispatch({ type: 'RESET_ERRORS' })
  }

  const handleCreditCardRequestNonce = () => {}

  const handleCreditCardNonceReceived = (nonce, errors) => {}

  const handlePaypalApproved = orderId => {}

  const context = {
    selectedMethod: window.localStorage.getItem('BC_PAYPAL_ORDER_ID') ? 'PAYPAL' : 'CREDIT_CARD',
    setSelectedMethod,
    setCreditCardRequestNonce,
    setCreditCardNonceReceived,
    setPaypalApproved,
    setPaypalOrderId,
    handleCreditCardRequestNonce,
    handleCreditCardNonceReceived,
    handlePaypalApproved,
    paypalOrderId: window.localStorage.getItem('BC_PAYPAL_ORDER_ID'),
    errors: [],
    setErrors,
    resetErrors,
  }

  const [state, dispatch] = useReducer(reducer, context)

  return <ProcessorContext.Provider value={state}>{children}</ProcessorContext.Provider>
}

const ProcessorContextConsumer = ProcessorContext.Consumer

export { ProcessorContext, ProcessorContextProvider, ProcessorContextConsumer }
