/** @jsx jsx */
import { useState, useCallback, useEffect, useMemo, useContext } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import { loadStripe } from '@stripe/stripe-js'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import { css, jsx } from '@emotion/core'

const FormLoading = () => {
  return <div className="text-sm text-gray-600 text-center w-full m-4">Securing Payment Form...</div>
}

const options = {
  style: {
    base: {
      fontSize: '18px',

      '::placeholder': {
        color: '#A0AEC0',
      },
    },
  },
}

const CardForm = ({ nonceReceived, setRequestNonce }) => {
  const { theme } = useContext(ThemeContext)

  const normal = css`
    & {
      box-shadow: 0 0 0 1px #cbd5e0;
    }
  `

  const focusBranding = css`
    & {
      box-shadow: 0 0 0 2px ${theme.primaryBrandColor};
    }
  `

  const [formSetup, setFormSetup] = useState(false)
  const [focus, setFocus] = useState({ card: false, exp: false, cvv: false })

  const stripe = useStripe()
  const elements = useElements()

  const createForm = useCallback(() => {
    if (!formSetup) {
      setFormSetup(true) // Need to unhack this shit
      setRequestNonce(async () => {
        const cardElement = elements.getElement(CardNumberElement)
        const { token, error } = await stripe.createToken(cardElement)

        if (error) {
          nonceReceived(null, ([{ field: 'CREDIT_CARD', message: error.message }]: false))
        } else {
          nonceReceived(token.id, null)
        }
      })
    }
  }, [setRequestNonce, stripe, elements, formSetup, nonceReceived])

  useEffect(() => {
    if (stripe && elements && !formSetup) {
      createForm()
    }
  }, [stripe, elements, formSetup, createForm])

  if (!stripe || !elements) return <FormLoading />

  return (
    <div className="">
      <div css={focus.card ? focusBranding : normal} className="w-full p-3 rounded">
        <CardNumberElement
          options={options}
          onFocus={() => setFocus(f => ({ ...f, card: true }))}
          onBlur={() => setFocus(f => ({ ...f, card: false }))}
        />
      </div>
      <div className="flex items-center mt-3">
        <div className="w-1/2 pr-1">
          <div css={focus.exp ? focusBranding : normal} className="w-full p-3 rounded">
            <CardExpiryElement
              options={options}
              onFocus={() => setFocus(f => ({ ...f, exp: true }))}
              onBlur={() => setFocus(f => ({ ...f, exp: false }))}
            />
          </div>
        </div>
        <div className="w-1/2 pl-1">
          <div css={focus.cvv ? focusBranding : normal} className="w-full p-3 rounded">
            <CardCvcElement
              options={options}
              onFocus={() => setFocus(f => ({ ...f, cvv: true }))}
              onBlur={() => setFocus(f => ({ ...f, cvv: false }))}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const StripePayments = ({ shopPaymentGateway, nonceReceived, setRequestNonce }) => {
  const stripePromise = useMemo(() => {
    return loadStripe(shopPaymentGateway.paymentGateway.appPublicCreds.public_key)
  }, [shopPaymentGateway])

  return (
    <Elements stripe={stripePromise}>
      <CardForm nonceReceived={nonceReceived} setRequestNonce={setRequestNonce} />
    </Elements>
  )
}

export default StripePayments
