import ReactPixel from 'react-facebook-pixel'

const ELIGIBLE_EVENTS = ['PAGE_VIEW', 'INIT_CHECKOUT', 'ADD_PAYMENT', 'PURCHASE']
const ELIGIBLE_CUSTOM_EVENTS = ['OFFER_ACCEPTED', 'OFFER_DECLINED']

const eventNameForType = type => {
  switch (type) {
    case 'PAGE_VIEW':
      return 'PageView'
    case 'INIT_CHECKOUT':
      return 'InitiateCheckout'
    case 'ADD_PAYMENT':
      return 'AddPaymentInfo'
    case 'PURCHASE':
      return 'Purchase'
    case 'OFFER_ACCEPTED':
      return 'OfferAccepted'
    case 'OFFER_DECLINED':
      return 'OfferDeclined'
    default:
      return ''
  }
}

const eventDataForType = event => {
  switch (event.type) {
    case 'INIT_CHECKOUT':
      return {
        contents: event.data.checkout.checkoutLineItems.map(item => ({
          id: item.shopifyProductId,
          quantity: item.quantity,
        })),
        content_type: 'product',
        content_ids: event.data.checkout.checkoutLineItems.map(item => item.shopifyProductId),
      }
    case 'PURCHASE':
      return {
        value: event.data.value,
        currency: event.data.currency,
        contents: event.data.order.orderLineItems.map(item => ({ id: item.shopifyProductId, quantity: item.quantity })),
        content_type: 'product',
        content_ids: event.data.order.orderLineItems.map(item => item.shopifyProductId),
      }
    case 'OFFER_ACCEPTED':
      return {
        sku: event.data.selectedVariant.sku,
        value: event.data.total,
        currency: 'USD',
        contents: [{ id: event.data.selectedVariant.product.shopifyId, quantity: event.data.quantity }],
        content_type: 'product',
        content_ids: [event.data.selectedVariant.product.shopifyId],
      }
    case 'OFFER_DECLINED':
      return {
        product: event.data.productTitle,
      }
    default:
      return {}
  }
}

const eventSecondaryDataForType = event => {
  switch (event.type) {
    case 'INIT_CHECKOUT':
      return {
        eventId: `checkout.${event.data.checkout.id}`
      }
    case 'PURCHASE':
      return {
        eventId: `purchase.${event.data.order.id}`
      }
    case 'OFFER_ACCEPTED':
      return {}
    case 'OFFER_DECLINED':
      return {}
    default:
      return {}
  }
}

export const facebookInit = trackers => {
  trackers.forEach(tracker => {
    ReactPixel.init(
      tracker.data.trackingId,
      {},
      {
        autoConfig: true,
        debug: process.env.NODE_ENV === 'development',
      }
    )
  })
}

export const facebookFire = events => {
  events.forEach(event => {
    if (event.type === 'PAGE_VIEW') {
      ReactPixel.pageView()
    } else if (ELIGIBLE_EVENTS.includes(event.type)) {
      ReactPixel.track(eventNameForType(event.type), eventDataForType(event), eventSecondaryDataForType(event))
    } else if (ELIGIBLE_CUSTOM_EVENTS.includes(event.type)) {
      ReactPixel.trackCustom(eventNameForType(event.type), eventDataForType(event))
    }
  })
}
