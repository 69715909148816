import React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi'

const QuantitySelector = ({ value, onChange }) => {
  return (
    <div className="select-none">
      <div className="text-sm">Quantity:</div>

      <div className="mt-1 w-48 border border-gray-500 rounded-sm">
        <div className="flex justify-center items-center">
          <div
            className="p-2 cursor-pointer hover:bg-gray-200"
            onClick={() => onChange(value - 1 >= 1 ? value - 1 : 1)}>
            <FiMinus className="text-xl fill-current text-gray-800 hover:text-gray-700" />
          </div>
          <div className="flex-grow text-center text-base">{value}</div>
          <div className="p-2 cursor-pointer hover:bg-gray-200" onClick={() => onChange(value + 1)}>
            <FiPlus className="text-xl fill-current text-gray-800 hover:text-gray-700" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuantitySelector
