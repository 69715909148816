import React from 'react'
import { Field, useField } from 'formik'

const CheckBox = ({ color, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props.name)

  const handleToggle = () => {
    helpers.setValue(!field.value)
  }

  return (
    <Field type="checkbox" {...props}>
      {({ field }) => (
        <>
          <div className="relative w-5 h-5 flex-0">
            <div
              className="w-full h-full border border-gray-300 rounded cursor-pointer"
              style={{ backgroundColor: field.value ? color || '#000000' : '#FFFFFF' }}
              onClick={handleToggle}>
              <img
                src="https://s3.amazonaws.com/bettercart.assets.shops.prod/assets/checkbox-tick.svg"
                alt="Tick"
                className="w-8/12 h-full m-auto cover"
              />
            </div>
          </div>
          <input {...field} {...props} checked={field.value} type="checkbox" hidden="hidden" />
        </>
      )}
    </Field>
  )
}

export default CheckBox
