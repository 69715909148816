import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { FUNNEL_STEP } from 'graphql/checkouts'
import FunnelStepContent from 'components/funnel-steps/FunnelStepContent'
import queryString from 'query-string'

const FunnelStepPreview = ({ match, location }) => {
  const { funnelStepId } = match.params
  const { design } = queryString.parse(location.search)

  const { loading, error, data } = useQuery(FUNNEL_STEP, { variables: { id: funnelStepId } })

  const [quantity, setQuantity] = useState(1)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState({})

  const designData = JSON.parse(design)

  const handleAccept = () => {
    setAcceptLoading(true)
    setTimeout(() => {
      setAcceptLoading(false)
    }, 700)
  }
  const handleDecline = () => {}
  const acceptError = null

  if (error) return <div>There was an error loading preview</div>
  if (loading) return <div>Loading Preview ...</div>

  return (
    <div>
      <FunnelStepContent
        order={{ checkout: {} }}
        step={data.publicFunnelStep}
        design={designData}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        quantity={quantity}
        setQuantity={setQuantity}
        handleAccept={handleAccept}
        handleDecline={handleDecline}
        acceptLoading={acceptLoading}
        acceptError={acceptError}
        fontFamily={data.publicFunnelStep.funnel.shop.fontFamily}
      />
    </div>
  )
}

export default withRouter(FunnelStepPreview)
