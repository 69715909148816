import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext } from 'contexts/ThemeContext'
import DigitalWallets from 'components/digital-wallets/DigitalWallets'
import ProductBump from 'components/ProductBump'
import CountDownTimer from 'components/CountDownTimer'
import ContactInformation from 'components/ContactInformation'
import ShippingInformation from 'components/ShippingInformation'
import { FiChevronLeft } from 'react-icons/fi'
import Button from 'components/Button'
import { useFormikContext } from 'formik'

const ShippingAddressStep = ({ checkout, history, match }) => {
  const { theme } = useContext(ThemeContext)

  const { validateForm, setFieldTouched } = useFormikContext()

  const handleSubmit = async () => {
    const errors = await validateForm()

    const stepValues = [
      'email',
      'acceptsMarketing',
      'shippingFirstName',
      'shippingLastName',
      'shippingLine1',
      'shippingLine2',
      'shippingCity',
      'shippingCountry',
      'shippingRegion',
      'shippingZipcode',
      'shippingPhone',
    ]

    const scopedErrors = Object.keys(errors).filter(k => stepValues.includes(k))

    if (scopedErrors.length > 0) {
      scopedErrors.forEach(key => setFieldTouched(key, true))
    } else {
      history.push({ pathname: `${match.url}`, search: '?step=shipping' })
    }
  }

  return (
    <>
      {checkout.funnel.showExpressCheckouts ? <DigitalWallets checkout={checkout} /> : null}

      {checkout.funnel.bumpProduct ? (
        <div className="mt-4 mb-4">
          <ProductBump checkout={checkout} />
        </div>
      ) : null}

      {checkout.funnel.showTimer ? (
        <div className="mb-4">
          <CountDownTimer checkout={checkout} />
        </div>
      ) : null}

      <ContactInformation tcpa={checkout.requiresTcpa} />

      <ShippingInformation checkout={checkout} />

      <div className="w-full my-4 lg:mt-8">
        <div className="w-full lg:flex lg:items-center">
          <div className="lg:flex-shrink lg:order-2">
            <Button style={{ backgroundColor: theme.primaryBrandColor }} onClick={handleSubmit}>
              <div>Continue to shipping</div>
            </Button>
          </div>

          <div className="mt-5 lg:mt-0 lg:flex-grow lg:order-1">
            <div className="flex items-center cursor-pointer hover:underline">
              <div>
                <FiChevronLeft className="text-xl text-gray-800" />
              </div>
              <a href={`${window.location.protocol}//${window.location.hostname}/cart`}>
                <div className="ml-1 text-sm text-gray-800 select-none">Return to cart</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(ShippingAddressStep)
