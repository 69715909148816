import React, { useState } from 'react'
import { SHOP_POLICIES } from 'graphql/checkouts'
import { useQuery } from '@apollo/react-hooks'
import { FiX } from 'react-icons/fi'

const Footer = ({ shopId, tcpa, accepts }) => {
  const [showPolicy, setShowPolicy] = useState(false)
  const [policy, setPolicy] = useState({})

  const { loading, error, data } = useQuery(SHOP_POLICIES, { variables: { id: shopId } })

  const handleOpenPolicy = policy => {
    setPolicy(policy)
    setShowPolicy(true)
  }

  if (error) {
    return <div />
  }

  return (
    <div className="">
      <div className="flex items-center h-16 w-full border-t border-gray-300">
        <div className="flex flex-wrap items-center justify-center w-full text-xs text-gray-800 text-center lg:justify-start">
          {loading ? (
            <div className="text-xs text-gray-400">Loading...</div>
          ) : (
            data.publicShop.shopifyPolicies.map(policy => {
              return (
                <div
                  className="flex-shrink mr-3 my-1 cursor-pointer hover:text-gray-700"
                  key={policy.title}
                  onClick={() => handleOpenPolicy(policy)}>
                  {policy.title}
                </div>
              )
            })
          )}
        </div>

        {showPolicy ? (
          <>
            <div
              className="fixed left-0 top-0 right-0 bottom-0 bg-black opacity-50 z-40"
              onClick={() => setShowPolicy(false)}
            />

            <div className="fixed left-0 top-0 right-0 bottom-0 bg-white m-6 rounded shaddow-2xl z-50 overflow-hidden">
              <div className="absolute top-0 bg-white flex items-center p-4 border-b border-gray-300 w-full z-30">
                <div className="flex-grow text-xl font-bold">{policy.title}</div>
                <div className="flex-shrink">
                  <FiX
                    className="text-3xl text-gray-800 cursor-pointer hover:text-gray-700"
                    onClick={() => setShowPolicy(false)}
                  />
                </div>
              </div>
              <div
                className="absolute px-4 py-20 overflow-auto h-full z-20 whitespace-normal"
                dangerouslySetInnerHTML={{ __html: policy.body }}
              />
            </div>
          </>
        ) : null}
      </div>
      {tcpa && accepts ? (
        <div className="text-xs text-gray-500 mb-6 px-4">
          I consent to receive recurring automated marketing by text message through an automatic telephone dialing
          system. Consent is not a condition to purchase. STOP to cancel, HELP for help. Message and Data rates apply.
          View Privacy Policy & ToS.
        </div>
      ) : null}
    </div>
  )
}

export default Footer
