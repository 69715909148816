import { fetch } from 'whatwg-fetch'

export const validate = email => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = process.env.REACT_APP_EMAIL_MX_VALIDATE_API_URL

      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      })
      const data = await response.json()
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}
