import React from 'react'

const CheckoutHeader = ({ headerBackground, logo, name }) => {
  return (
    <div
      className={`flex-shrink ${headerBackground ? "py-6" : "p-3"} bg-center bg-no-repeat bg-cover lg:pt-11 lg:pb-8`}
      style={headerBackground ? { backgroundImage: `url(${headerBackground})` } : null}>
      <div className="max-w-xl px-4 m-auto lg:px-12 lg:max-w-6xl">
        {logo ? (
          <a href={`${window.location.protocol}//${window.location.hostname}`} className="inline-block">
            <img className="lg:h-20" src={logo} alt="Logo" style={{maxHeight: "3.5em"}} />
          </a>
        ) : (
          <a href={`${window.location.protocol}//${window.location.hostname}`} className="inline-block">
            <div className={`text-2xl font-semibold lg:text-4xl ${headerBackground ? 'text-white' : 'text-gray-800'}`}>
              {name ? name : 'Checkout'}
            </div>
          </a>
        )}
      </div>
    </div>
  )
}

export default CheckoutHeader
