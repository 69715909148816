import loadjs from 'loadjs'

export const googleAdsInit = trackers => {
  if (trackers && trackers.length > 0 && trackers[0].data) {
    loadjs(`https://www.googletagmanager.com/gtag/js?id=${trackers[0].data.trackingId}`, () => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag('js', new Date())

      trackers.forEach(tracker => {
        gtag('config', tracker.data.trackingId)
      })
    })
  }
}

export const googleAdsFire = (events, trackers) => {
  if (trackers && trackers.length > 0 && trackers[0].data) {
    events.forEach(event => {
      if (event.type === 'PURCHASE') {
        loadjs(`https://www.googletagmanager.com/gtag/js?id=${trackers[0].data.trackingId}`, () => {
          window.dataLayer = window.dataLayer || []
          function gtag() {
            window.dataLayer.push(arguments)
          }
          gtag('js', new Date())

          trackers.forEach(tracker => {
            gtag('config', tracker.data.trackingId)

            if (tracker.data.purchaseLabel) {
              gtag('event', 'conversion', {
                send_to: tracker.data.purchaseLabel,
                value: event.data.order.total,
                currency: 'USD',
                transaction_id: event.data.order.id,
              })
            }
          })
        })
      }
    })
  }
}
