import React, { useContext } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import ShippingMethods from 'components/ShippingMethods'
import ShippingMethodsInternal from 'components/ShippingMethodsInternal'
import Color from 'color'

const ShippingMethodStep = ({ checkout }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <>
      <div className="mt-6">
        <div
          className="text-lg font-semibold"
          style={{
            color: Color(theme.primaryBrandColor).darken(0.25).string(),
          }}>
          Shipping Method
        </div>
        <div className="mt-4">
          {checkout.funnel.shop.hasShippingZones ? (
            <ShippingMethodsInternal checkout={checkout} />
          ) : (
            <ShippingMethods checkout={checkout} />
          )}
        </div>
      </div>
    </>
  )
}

export default ShippingMethodStep
