import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { EventTrackerContext } from 'contexts/EventTrackerContext'
import cryptoRandomString from 'crypto-random-string'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { FUNNEL_STEP, FUNNEL_STEP_ORDER, ACCEPT_OFFER, DECLINE_OFFER } from 'graphql/checkouts'
import FunnelStepContent from 'components/funnel-steps/FunnelStepContent'
import PageWindowData from 'components/PageWindowData'
import CustomScripts from 'components/CustomScripts'

const FunnelStepPage = ({ history, location, match }) => {
  const { checkoutId, orderId, stepId } = match.params

  const { setCheckoutIds, track } = useContext(EventTrackerContext)

  const [selectedVariant, setSelectedVariant] = useState({})
  const [quantity, setQuantity] = useState(1)
  const [idempotencyKey, setIdempotencyKey] = useState(cryptoRandomString({ length: 25, type: 'url-safe' }))

  const { loading, error, data } = useQuery(FUNNEL_STEP, { variables: { id: stepId } })
  const { loading: orderLoading, error: orderError, data: orderData } = useQuery(FUNNEL_STEP_ORDER, {
    variables: { id: orderId },
  })

  const isFirstUpsell = useMemo(() => {
    if (data && data.publicFunnelStep) {
      return data.publicFunnelStep.firstUpsell
    } else {
      return false
    }
  }, [data])

  const [acceptOffer, { loading: acceptLoading, error: acceptError }] = useMutation(ACCEPT_OFFER, {
    onError: err => {
      console.log(err)
      setIdempotencyKey(cryptoRandomString({ length: 25, type: 'url-safe' }))
    },
    onCompleted: acceptData => {
      track({
        type: 'OFFER_ACCEPTED',
        data: {
          stepId,
          quantity,
          productTitle: data.publicFunnelStep.product.title,
          selectedVariant,
          order: orderData.order,
          price: acceptData.acceptOffer.price,
          total: acceptData.acceptOffer.total,
        },
      })

      navigateToNextStep(true)
    },
  })

  const handleAccept = () => {
    acceptOffer({
      variables: {
        orderId,
        funnelStepId: stepId,
        productVariantId: selectedVariant.id,
        quantity,
        idempotencyKey,
        paymentNonceSource: 'CREDIT_CARD',
      },
    })
  }

  const onApprovePaypal = useCallback(
    (data, actions) => {
      acceptOffer({
        variables: {
          orderId,
          funnelStepId: stepId,
          productVariantId: selectedVariant.id,
          quantity,
          idempotencyKey,
          paymentNonceSource: 'PAYPAL_CHECKOUT',
          paymentNonce: data.orderID,
        },
      })
    },
    [orderId, stepId, idempotencyKey, selectedVariant, quantity, acceptOffer]
  )

  const [declineOffer] = useMutation(DECLINE_OFFER, {
    onError: err => {},
    onCompleted: () => {
      track({
        type: 'OFFER_DECLINED',
        data: {
          stepId,
          productTitle: data.publicFunnelStep.product.title,
        },
      })
      navigateToNextStep(false)
    },
  })

  const handleDecline = () => {
    declineOffer({
      variables: {
        orderId,
        funnelStepId: stepId,
      },
    })
  }

  const navigateToNextStep = response => {
    const nextStepId = data.publicFunnelStep.funnel.path[stepId][response ? 'YES' : 'NO']

    if (nextStepId === 'THANK_YOU') {
      history.replace(`${process.env.PUBLIC_URL}/orders/${orderId}/thank-you`)
    } else {
      history.replace(`${process.env.PUBLIC_URL}/checkouts/${checkoutId}/order/${orderId}/step/${nextStepId}`)
    }
  }

  //New Idempotency Key
  useEffect(() => {
    setIdempotencyKey(cryptoRandomString({ length: 25, type: 'url-safe' }))
  }, [stepId])

  // Set Tracking Checkout IDs for events
  useEffect(() => {
    if (orderData && orderData.order) {
      setCheckoutIds([orderData.order.checkout.id, orderData.order.shop.id, orderData.order.checkout.funnel.id])
    }
  }, [orderData, setCheckoutIds])

  // Handle checkout already completed
  useEffect(() => {
    if (orderData && orderData.order.checkout.completed) {
      window.location.href = `${window.location.protocol}//${window.location.hostname}/`
    }
  }, [orderData])

  // Page Views
  useEffect(() => {
    track({
      type: 'PAGE_VIEW',
      data: {
        uri: location.pathname,
        search: location.search,
      },
    })
  }, [track, location, stepId])

  // Reset Scroll Position
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [stepId])

  useEffect(() => {
    if (data && data.publicFunnelStep.funnel) {
      setQuantity(1)
    }
  }, [data, stepId])

  if (error || orderError) {
    return (
      <div className="p-6 text-red-400">
        Uh oh! There was an unexpected error that occured, but dont worry, your order was placed successfully!
      </div>
    )
  }
  if (loading || orderLoading) return <div className="p-6 text-center text-gray-400">Loading...</div>

  return (
    <div>
      <FunnelStepContent
        order={orderData.order}
        step={data.publicFunnelStep}
        design={data.publicFunnelStep.design}
        selectedVariant={selectedVariant}
        setSelectedVariant={setSelectedVariant}
        quantity={quantity}
        setQuantity={setQuantity}
        handleAccept={handleAccept}
        handleDecline={handleDecline}
        acceptLoading={acceptLoading}
        acceptError={acceptError}
        onApprovePaypal={onApprovePaypal}
        fontFamily={data.publicFunnelStep.funnel.shop.fontFamily}
      />

      <PageWindowData page="OFFER_PAGE" data={data.publicFunnelStep} orderData={orderData.order} />
      <CustomScripts
        data={{
          funnelTag: orderData.order.checkout.funnel.tag,
          funnelName: orderData.order.checkout.funnel.name,
          productTitle: data.publicFunnelStep.product ? data.publicFunnelStep.product.title : '',
          productHandle: data.publicFunnelStep.product ? data.publicFunnelStep.product.handle : '',
          orderTotal: orderData.order.total,
        }}
        scripts={orderData.order.checkout.funnel.shop.customScripts.filter(
          s =>
            s.enabled &&
            (s.location === 'ALL' || s.location === 'OFFER' || (isFirstUpsell ? s.location === 'FIRST_OFFER' : false))
        )}
      />
    </div>
  )
}

export default withRouter(FunnelStepPage)
