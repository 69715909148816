import React, { useEffect, useState } from 'react'

const replaceTokens = (code, data) => {
  return code
    .replace(/{{\s*funnel\.tag\s*}}/gm, data.funnelTag ? data.funnelTag : '')
    .replace(/{{\s*funnel\.name\s*}}/gm, data.funnelName ? data.funnelName : '')
    .replace(/{{\s*checkout\.total\s*}}/gm, data.checkoutTotal ? data.checkoutTotal : '')
    .replace(/{{\s*product\.title\s*}}/gm, data.productTitle ? data.productTitle : '')
    .replace(/{{\s*product\.handle\s*}}/gm, data.productHandle ? data.productHandle : '')
    .replace(/{{\s*order\.total\s*}}/gm, data.orderTotal ? data.orderTotal : '')
    .replace(/{{\s*order\.number\s*}}/gm, data.orderNumber ? data.orderNumber : '')
}

const composeScriptTags = script => {
  return script.body.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gim) || []
}

const composeScriptIncludesAsUrls = (script, data) => {
  return composeScriptTags(script)
    .map(t => {
      const url = t.replace(/"/g, "'").match(/<script.*?src='(.*?)'/)
      return url && url[1] ? replaceTokens(url[1], data) : false
    })
    .filter(f => f)
}

const composeHtmlTags = (script, data) => {
  const cleanBody = replaceTokens(script.body.replace(/<!--(.*?)-->/gim, ''), data)
  const tags = cleanBody.match(/<(?!\/?script(?=>|\s.*>))\/?.*>[\s\S]*?<\/.*>/gim) || []
  const selfClosingTags = cleanBody.match(/<(?!\/?script(?=>|\s.*>))\/?.*\/>/gim) || []

  return [...tags, ...selfClosingTags]
}

export default ({ data, scripts }) => {
  const [htmlTags, setHtmlTags] = useState([])

  useEffect(() => {
    const createdURLTags = []
    const createdScriptTags = []

    // Init
    if (!window.loadedCustomScripts) window.loadedCustomScripts = []

    if (scripts) {
      scripts.forEach(s => {
        if (window.loadedCustomScripts && !window.loadedCustomScripts.includes(s.id)) {
          window.loadedCustomScripts.push(s.id)
          // Script Includes
          composeScriptIncludesAsUrls(s, data).forEach(src => {
            const script = document.createElement('script')
            script.src = src
            script.async = s.async
            createdURLTags.push(script)
            document.head.appendChild(script)
          })

          // Script Tags
          composeScriptTags(s).forEach(tag => {
            const code = tag.replace(/<script\b[^<]*>/gi, '').replace(/<\/script>/gi, '')
            if (code.length > 0) {
              const script = document.createElement('script')
              script.innerHTML = replaceTokens(code, data)
              script.async = s.async
              createdScriptTags.push(script)
              document.head.appendChild(script)
            }
          })

          // Other HTML
          setHtmlTags(t => [...t, ...composeHtmlTags(s, data)])
        }
      })
    }

    // return () => {
    //   createdHeadTags.forEach(t => {
    //     document.head.removeChild(t)
    //   })

    //   createdBodyTags.forEach(t => {
    //     document.body.removeChild(t)
    //   })
    // }
  }, [data, scripts])

  return htmlTags ? htmlTags.map((t, index) => <div key={index} dangerouslySetInnerHTML={{ __html: t }} />) : null
}
