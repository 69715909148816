import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import CurrencyFormat from 'react-currency-format'
import { FaTruck } from 'react-icons/fa'
import { requestShippingMethods } from 'utils/shopify'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_CHECKOUT } from 'graphql/checkouts'
import { CountryRegionData } from '@bettercart/react-country-region-selector'

const ShippingMethods = ({ checkout, errors }) => {
  const { theme } = useContext(ThemeContext)

  const [methods, setMethods] = useState([])
  const [selected, setSelected] = useState(checkout.shippingMethod)
  const [methodsLoading, setMethodsLoading] = useState(true)
  const [internalError, setInternalError] = useState(false)

  const shippingCountryData = CountryRegionData.find(c => c.code === checkout.shippingCountry)
  const shippingRegionOptional = shippingCountryData && shippingCountryData.regions.length < 1
  const shippingZipcodeOptional = shippingCountryData && !shippingCountryData.zipRequired

  const hasErrors = useMemo(() => errors && Object.keys(errors).length > 0, [errors])

  const [updateCheckout] = useMutation(UPDATE_CHECKOUT, {
    onError: () => {
      console.log('Error updating checkout')
    },
  })

  const handleSelectMethod = useCallback(
    shippingMethod => {
      setSelected(shippingMethod)
      updateCheckout({
        variables: {
          id: checkout.id,
          shippingMethod: {
            code: shippingMethod.code,
            price: parseFloat(shippingMethod.price),
            name: shippingMethod.name,
          },
        },
      })
    },
    [updateCheckout, checkout.id]
  )

  const requestMethods = useCallback(
    async checkout => {
      setMethodsLoading(true)

      if (
        (shippingZipcodeOptional ||
          (checkout.shippingZipcode && checkout.shippingZipcode !== '' && checkout.shippingZipcode.length >= 0)) &&
        checkout.shippingCountry &&
        checkout.shippingCountry !== '' &&
        (shippingRegionOptional || (checkout.shippingRegion && checkout.shippingRegion !== ''))
      ) {
        try {
          const rates = await requestShippingMethods({
            shippingRegion: checkout.shippingRegion,
            shippingCountry: checkout.shippingCountry,
            shippingZipcode: checkout.shippingZipcode,
          })
          setMethods(rates)
          setInternalError(false)
        } catch (error) {
          setInternalError(error.message)
          setMethods([])
        }
      }

      setMethodsLoading(false)
    },
    [shippingZipcodeOptional, shippingRegionOptional]
  )

  const canSelectShippingMethod = useMemo(() => {
    if (!checkout) return false

    return (
      (shippingZipcodeOptional ||
        (checkout.shippingZipcode && checkout.shippingZipcode !== '' && checkout.shippingZipcode.length >= 0)) &&
      checkout.shippingCountry &&
      checkout.shippingCountry !== '' &&
      (shippingRegionOptional || (checkout.shippingRegion && checkout.shippingRegion !== ''))
    )
  }, [checkout, shippingZipcodeOptional, shippingRegionOptional])

  // Effects //

  // First Load & Shipping Address Updates
  useEffect(() => {
    requestMethods({
      shippingRegion: checkout.shippingRegion,
      shippingCountry: checkout.shippingCountry,
      shippingZipcode: checkout.shippingZipcode,
    })
  }, [
    requestMethods,
    checkout.shippingRegion,
    checkout.shippingCountry,
    checkout.shippingZipcode,
    checkout.subtotal,
    checkout.savings,
    checkout.shippingRates,
  ])

  // Update if no selection or selection changed price
  useEffect(() => {
    // has shipping methods
    if (methods && methods.length > 0) {
      // not set yet
      if (!selected) {
        handleSelectMethod(methods[0])
      } else {
        // updated selected method from request
        const selectedMatch = methods.find(m => m.code === selected.code)

        if (selectedMatch) {
          // price has changed
          if (parseFloat(selectedMatch.price) !== parseFloat(selected.price)) {
            handleSelectMethod(selectedMatch)
          }
        } else {
          // selected not found, select first
          handleSelectMethod(methods[0])
        }
      }
    }
  }, [selected, methods, handleSelectMethod, checkout.shippingRates])

  if (methodsLoading) {
    return (
      <>
        <div className="p-6 border border-gray-300 rounded">
          <div className="text-sm text-gray-600">Loading Shipping Rates...</div>
        </div>
      </>
    )
  }

  if (!canSelectShippingMethod) {
    return (
      <>
        <div className="flex items-center p-4 border border-gray-300 rounded">
          <div className="text-xl" style={{ color: theme.primaryBrandColor }}>
            <FaTruck />
          </div>
          <div className="ml-4 text-sm text-gray-800">Enter your shipping address to see shipping options...</div>
        </div>
      </>
    )
  }

  if (!methods || methods.length < 1) {
    return (
      <>
        <div className="p-4 border border-gray-300 rounded">
          <div className="text-lg text-red-400">No Shipping Methods Available</div>
          <div className="mt-1 text-xs text-gray-500">
            This can be caused by an invalid shipping address on the prior step or a shipping address not supported by
            this store. If you think this is a mistake, please click "Try Again" to check for rates again.
          </div>
          <div className="mt-2 text-sm text-gray-500">
            <span>Mesage: </span> {internalError ? internalError : 'Unknown Error'}
          </div>
          <div className="my-4">
            <div
              onClick={() => window.location.reload()}
              className="px-4 py-2 text-center text-white bg-gray-500 rounded cursor-pointer hover:bg-gray-400">
              Try Again
            </div>
          </div>
          <div className="mt-1 text-xs text-gray-500">Please contact our support if you think this is a mistake</div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={`border rounded ${hasErrors ? 'border-red-400' : 'border-gray-300'}`}>
        {methods.map((method, index) => {
          const matched = selected ? method.code === selected.code : false
          const last = index === methods.length - 1

          return (
            <div
              className={`cursor-pointer hover:bg-gray-200 ${last ? '' : 'border-b border-gray-300 '}`}
              key={`shipping-method-${method.code}`}
              onClick={() => handleSelectMethod(method)}>
              <div className="flex items-center p-4">
                <div className="flex-shrink mr-2">
                  <div
                    style={{ backgroundColor: matched ? theme.primaryBrandColor : '#FFFFFF' }}
                    className={`flex rounded-full h-4 w-4 text-center cursor-pointer ${
                      selected ? '' : 'border border-gray-400'
                    }`}>
                    <div className="w-1 h-1 m-auto bg-white rounded-full" />
                  </div>
                </div>
                <div className="flex-grow text-sm text-gray-800">{method.name}</div>
                <div className="flex-shrink text-sm">
                  <CurrencyFormat value={method.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {hasErrors ? (
        <div className="mt-1 text-sm text-red-400">
          {Object.keys(errors)
            .map(e => errors[e])
            .join(', ')}
        </div>
      ) : null}
    </>
  )
}

export default ShippingMethods
