import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { UPDATE_CHECKOUT_BUMP, CHECKOUT_LINE_ITEM_FINANCIALS } from 'graphql/checkouts'
import { useMutation } from '@apollo/react-hooks'
import { TiArrowRightThick } from 'react-icons/ti'
import { FaCheck } from 'react-icons/fa'
import CurrencyFormat from 'react-currency-format'

const ProductBump = ({ checkout }) => {
  const {
    bumpProductBgColor,
    bumpProductTextColor,
    bumpProductPriceColor,
    bumpProductPriceBgColor,
    bumpProductImage,
  } = checkout.funnel

  const [accept, setAccept] = useState(checkout.acceptsProductBump)

  const product = useMemo(() => checkout.funnel.bumpProduct, [checkout.funnel.bumpProduct])

  const [updateCheckoutBump, { loading }] = useMutation(UPDATE_CHECKOUT_BUMP, {
    onError: error => {},
    refetchQueries: [
      {
        query: CHECKOUT_LINE_ITEM_FINANCIALS,
        variables: {
          id: checkout.id,
        },
      },
    ],
  })

  const handleBump = useCallback(
    accepted => {
      updateCheckoutBump({
        variables: {
          id: checkout.id,
          accepted,
        },
      })
    },
    [updateCheckoutBump, checkout.id]
  )

  useEffect(() => {
    if (checkout.acceptsProductBump !== accept) {
      handleBump(accept)
    }
  }, [checkout.acceptsProductBump, accept, handleBump])

  return (
    <div className="relative z-10 overflow-hidden">
      <div className="flex items-center p-2 rounded-t" style={{ backgroundColor: bumpProductBgColor }}>
        <div className="slide-left">
          <TiArrowRightThick className="text-2xl text-white" />
        </div>
        <div className="ml-1 felx-shrink">
          <div className="flex items-center">
            <div className="relative w-5 h-5 flex-0">
              <div
                className="flex items-center justify-center w-full h-full bg-white border border-gray-300 rounded cursor-pointer"
                onClick={() => setAccept(a => !a)}>
                {accept ? <FaCheck className="text-base" style={{ color: bumpProductBgColor }} /> : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className="ml-3 text-xs cursor-pointer felx-shrink lg:ml-2 lg:text-sm"
          onClick={() => setAccept(a => !a)}
          style={{ color: bumpProductTextColor }}>
          Yes! Add {product.title} to my order!
        </div>
        <div className="flex-grow text-right">
          <div
            className="inline-block w-auto px-2 py-1 ml-2 text-xs text-center rounded-full lg:text-sm"
            style={{ color: bumpProductPriceColor, backgroundColor: bumpProductPriceBgColor }}>
            <CurrencyFormat
              value={product.productVariants[0].price}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center p-3 overflow-hidden border-b border-l border-r border-gray-300 rounded-b">
        <div className="flex items-center">
          {bumpProductImage ? (
            <div className="w-1/5">
              <div className="flex items-center justify-center">
                <img className="object-cover w-auto" src={bumpProductImage} alt="Product" />
              </div>
            </div>
          ) : null}
          <div className={`ml-2 text-xs lg:text-sm text-gray-800 ${bumpProductImage ? 'w-4/5' : 'w-full'}`}>
            {checkout.funnel.bumpProductDescription}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center bg-gray-800 opacity-50">
          <div className="text-white text-center text-xl">Loading...</div>
        </div>
      ) : null}
    </div>
  )
}

export default ProductBump
