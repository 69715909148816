import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'config/ApolloClient'
import CheckoutsRouter from 'routers/CheckoutsRouter'
import CartRedirectRouter from 'routers/CartRedirectRouter'
import ThankYouPage from 'pages/ThankYouPage'
import FunnelStepPreview from 'pages/previews/FunnelStepPreview'

import { LocationContextProvider } from 'contexts/LocationContext'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { ProcessorContextProvider } from 'contexts/ProcessorContext'
import { CheckoutContextProvider } from 'contexts/CheckoutContext'
import { EventTrackerContextProvider } from 'contexts/EventTrackerContext'

const App = () => {
  return (
    <ApolloProvider client={ApolloClient}>
      <ThemeContextProvider>
        <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
          <LocationContextProvider>
            <ProcessorContextProvider>
              <CheckoutContextProvider>
                <EventTrackerContextProvider>
                  <BrowserRouter>
                    <Route path={`${process.env.PUBLIC_URL}/checkouts`} component={CheckoutsRouter} />
                    <Route path={`${process.env.PUBLIC_URL}/orders/:orderId/thank-you`} component={ThankYouPage} />
                    <Route exact path="/" component={CartRedirectRouter} />
                  </BrowserRouter>
                </EventTrackerContextProvider>
              </CheckoutContextProvider>
            </ProcessorContextProvider>
          </LocationContextProvider>

          <BrowserRouter>
            <Route path={`/preview/funnel/:funnelId/step/:funnelStepId`} component={FunnelStepPreview} />
          </BrowserRouter>
        </IconContext.Provider>
      </ThemeContextProvider>
    </ApolloProvider>
  )
}

export default App
