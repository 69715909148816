import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext } from 'contexts/ThemeContext'
import ProductBump from 'components/ProductBump'
import CustomerSummary from 'components/CustomerSummary'
import ShippingMethods from 'components/ShippingMethods'
import ShippingMethodsInternal from 'components/ShippingMethodsInternal'
import { FiChevronLeft } from 'react-icons/fi'
import Button from 'components/Button'
import Color from 'color'

const ShippingMethodStep = ({ checkout, history, match }) => {
  const { theme } = useContext(ThemeContext)

  const [errors, setErrors] = useState({})

  const handleSubmit = () => {
    if (
      checkout.funnel.shop.hasShippingZones &&
      (checkout.shippingRate === undefined || checkout.shippingRate === null)
    ) {
      setErrors({ shippingMethod: 'Shipping method is required' })
    } else if (
      checkout.shippingMethod === {} ||
      checkout.shippingMethod === undefined ||
      checkout.shippingMethod === null
    ) {
      setErrors({ shippingMethod: 'Shipping method is required' })
    } else {
      setErrors({})
      history.push({ pathname: `${match.url}`, search: '?step=payment' })
    }
  }

  const handleBack = () => {
    history.push({ pathname: `${match.url}`, search: '' })
  }

  return (
    <>
      {checkout.funnel.bumpProduct ? (
        <div className="mb-4">
          <ProductBump checkout={checkout} />
        </div>
      ) : null}

      <CustomerSummary checkout={checkout} />

      <div className="mt-4 lg:mt-12">
        <div
          className="text-lg font-semibold"
          style={{
            color: Color(theme.primaryBrandColor).darken(0.25).string(),
          }}>
          Shipping Method
        </div>
        <div className="mt-4">
          {checkout.funnel.shop.hasShippingZones ? (
            <ShippingMethodsInternal checkout={checkout} errors={errors} />
          ) : (
            <ShippingMethods checkout={checkout} errors={errors} />
          )}
        </div>
      </div>

      <div className="w-full my-4 lg:mt-8">
        <div className="w-full lg:flex lg:items-center">
          <div className="lg:flex-shrink lg:order-2">
            <Button style={{ backgroundColor: theme.primaryBrandColor }} onClick={handleSubmit}>
              <div>Continue to payment</div>
            </Button>
          </div>
          <div className="mt-5 lg:mt-0 lg:flex-grow lg:order-1">
            <div className="flex items-center cursor-pointer hover:underline">
              <div>
                <FiChevronLeft className="text-xl text-gray-800" />
              </div>
              <div className="ml-1 text-sm text-gray-800" onClick={handleBack}>
                Return to information
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(ShippingMethodStep)
