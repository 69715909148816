import React, { useContext } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import FloatingLabelInput from 'components/FloatingLabelInput'
import CheckBox from 'components/Checkbox'
import Color from 'color'
import { correctEmail } from 'utils/nlp'
import { Field, useField } from 'formik'

const ContactInformation = ({ tcpa }) => {
  const { theme } = useContext(ThemeContext)

  // eslint-disable-next-line
  const [field, meta, helpers] = useField('email')

  const onBlur = (e, errors) => {
    if (Object.keys(errors).filter(f => f === 'email').length > 0) {
      helpers.setValue(correctEmail(e.target.value, 0.35), true)
      helpers.setTouched(true, false)
    }
  }

  return (
    <div>
      <div
        className="text-lg font-semibold"
        style={{
          color: Color(theme.primaryBrandColor).darken(0.25).string(),
        }}>
        Contact Information
      </div>
      <div className="mt-4">
        <Field name="email">
          {({ field, form: { errors, touched } }) => (
            <FloatingLabelInput
              {...field}
              onBlur={e => {
                onBlur(e, errors)
              }}
              errors={errors}
              showErrors={touched.email}
              label="Email"
              placeholder="Email"
              type="email"
            />
          )}
        </Field>
      </div>
      <div className="flex items-center mt-4">
        <CheckBox color={theme.primaryBrandColor} name="acceptsMarketing" id="acceptsMarketing" />
        <label htmlFor="acceptsMarketing">
          <div className="flex-1 ml-2 text-sm text-gray-800 cursor-pointer select-none">
            {tcpa
              ? 'Sign up for exclusive offers and news via text messages & email.'
              : 'Keep me up to date on news and exclusive offers'}
          </div>
        </label>
      </div>
    </div>
  )
}

export default ContactInformation
