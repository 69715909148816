import React from 'react'
import OrderLineItem from 'components/OrderLineItem'

const OrderLineItems = ({ lineItems }) => {
  return (
    <div>
      {lineItems.map(lineItem => {
        return <OrderLineItem key={`line-item-${lineItem.id}`} lineItem={lineItem} />
      })}
    </div>
  )
}

export default OrderLineItems
