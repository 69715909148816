import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { FiTag, FiXCircle, FiLoader } from 'react-icons/fi'
import { CHECKOUT_FINANCIALS, DETATCH_DISCOUNT } from 'graphql/checkouts'
import { useQuery, useMutation } from '@apollo/react-hooks'

const FinancialsTable = ({ checkoutId }) => {
  const { loading, error, data } = useQuery(CHECKOUT_FINANCIALS, {
    variables: { id: checkoutId },
  })

  const [detatchDiscount, { loading: detatchDiscountLoading }] = useMutation(DETATCH_DISCOUNT, {
    onError: err => {},
    refetchQueries: [
      {
        query: CHECKOUT_FINANCIALS,
        variables: { id: checkoutId },
      },
    ],
  })

  const handleDetatch = () => {
    detatchDiscount({
      variables: {
        checkoutId: id,
      },
    })
  }

  if (error) {
    return <div>Whoops... There was an error</div>
  }

  if (loading) {
    return <div>Loading...</div>
  }

  const { id, subtotal, savings, taxes, total, shippingMethod, appliedDiscount, shippingRate, funnel } = data.checkout

  return (
    <>
      <div>
        <div className="flex items-center">
          <div className="flex-grow">
            <div className="text-sm text-gray-800">Subtotal</div>
          </div>
          <div className="flex-shrink">
            {subtotal ? (
              <CurrencyFormat
                value={subtotal}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            ) : (
              <div>N/A</div>
            )}
          </div>
        </div>

        {savings > 0 ? (
          <div className="flex items-center mt-4">
            <div className="flex-grow">
              <div className="flex items-center">
                <div className="mr-2 text-sm text-gray-800">Discount</div>
                {appliedDiscount && appliedDiscount.code ? (
                  <>
                    <div className="">
                      <FiTag className="text-lg text-gray-800" />
                    </div>
                    <div className="mx-1 text-sm font-semibold text-gray-800 uppercase">{appliedDiscount.code}</div>
                    <div className="cursor-pointer" onClick={handleDetatch}>
                      {detatchDiscountLoading ? (
                        <FiLoader className="text-lg text-gray-800 icon-spin" />
                      ) : (
                        <FiXCircle className="text-lg text-gray-800" />
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="flex-shrink">
              -{' '}
              {savings ? (
                <CurrencyFormat
                  value={savings}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              ) : (
                <div>N/A</div>
              )}
            </div>
          </div>
        ) : null}

        {taxes && taxes > 0 ? (
          <div className="flex items-center mt-4">
            <div className="flex-grow">
              <div className="text-sm text-gray-800">Taxes</div>
            </div>
            <div className="flex-shrink">
              <div className="">
                <CurrencyFormat
                  value={taxes}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex items-center mt-4">
          <div className="flex-grow">
            <div className="text-sm text-gray-800">Shipping</div>
          </div>
          <div className="flex-shrink">
            {funnel.shop.hasShippingZones ? (
              <>
                {shippingRate ? (
                  <CurrencyFormat
                    value={shippingRate.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                ) : (
                  <div className="text-xs text-gray-600">Select shipping rate</div>
                )}
              </>
            ) : shippingMethod.code ? (
              <CurrencyFormat
                value={shippingMethod.price}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            ) : (
              <div className="text-xs text-gray-600">Calculated at next step</div>
            )}
          </div>
        </div>

        <div className="flex items-center pt-4 mt-4 border-t border-gray-300">
          <div className="flex-grow">
            <div className="text-lg text-gray-800">Total</div>
          </div>
          <div className="flex-shrink">
            <div className="flex items-center">
              <div className="text-xs font-semibold text-gray-600 uppercase">USD</div>
              <div className="ml-2 text-xl font-semibold text-gray-800">
                <CurrencyFormat
                  value={total}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinancialsTable
