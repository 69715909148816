import { useEffect, useState, useRef } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { SHOP_TRACKING_PIXELS } from 'graphql/checkouts'
import { facebookInit, facebookFire } from 'pixels/facebook'
import { googleInit, googleFire } from 'pixels/google'
import { googleAdsInit, googleAdsFire } from 'pixels/google-ads'
import { googleTagManagerInit, googleTagManagerFire } from 'pixels/google-tag-manager'
import { snapInit, snapFire } from 'pixels/snap'

const ThirdPartyPixels = ({ shopId, events }) => {
  const [facebookReady, setFacebookReady] = useState(false)
  const [googleReady, setGoogleReady] = useState(false)
  const [googleTagManagerReady, setGoogleTagManagerReady] = useState(false)
  const [snapReady, setSnapReady] = useState(false)

  const [loadPixels, { data }] = useLazyQuery(SHOP_TRACKING_PIXELS)

  // Get Trackers
  useEffect(() => {
    if (shopId) {
      loadPixels({
        variables: {
          id: shopId,
        },
      })
    }
  }, [shopId, loadPixels])

  // Init Facebook
  useEffect(() => {
    if (data && data.publicShop) {
      const fbTrackers = data.publicShop.trackingPixels.filter(t => t.platform === 'FACEBOOK')
      if (fbTrackers.length > 0) {
        facebookInit(fbTrackers)
        setFacebookReady(true)
      }
    }
  }, [data])

  // Init Google Analytics
  useEffect(() => {
    if (data && data.publicShop) {
      const googleTrackers = data.publicShop.trackingPixels.filter(t => t.platform === 'GOOGLE_ANALYTICS')
      if (googleTrackers.length > 0) {
        googleInit(googleTrackers)
        setGoogleReady(true)
      }
    }
  }, [data])

  // Init Google Ads
  useEffect(() => {
    if (data && data.publicShop) {
      const googleAdsTrackers = data.publicShop.trackingPixels.filter(t => t.platform === 'GOOGLE_ADS')
      if (googleAdsTrackers.length > 0) {
        googleAdsInit(googleAdsTrackers)
      }
    }
  }, [data])

  // Init Google Tag Manager
  useEffect(() => {
    if (data && data.publicShop && data.publicShop.googleTagManagerContainerId) {
      googleTagManagerInit(data.publicShop.googleTagManagerContainerId)
      setGoogleTagManagerReady(true)
    }
  }, [data])

  // Init Snapchat
  useEffect(() => {
    if (data && data.publicShop) {
      const snapTrackers = data.publicShop.trackingPixels.filter(t => t.platform === 'SNAPCHAT')
      if (snapTrackers.length > 0) {
        snapInit(snapTrackers)
        setSnapReady(true)
      }
    }
  }, [data])

  // Completed Event Queues
  const fbCompleted = useRef([])
  const googleCompleted = useRef([])
  const googleAdsCompleted = useRef([])
  const googleTagCompleted = useRef([])
  const snapCompleted = useRef([])

  // Fire Events
  useEffect(() => {
    // Facebook
    if (facebookReady) {
      const uncompletedFacebookEvents = events.filter(e => !fbCompleted.current.includes(e.id))
      facebookFire(uncompletedFacebookEvents)
      fbCompleted.current = [...fbCompleted.current, ...uncompletedFacebookEvents.map(e => e.id)]
    }

    // Google Analytics
    if (googleReady) {
      const uncompletedGoogleEvents = events.filter(e => !googleCompleted.current.includes(e.id))
      googleFire(uncompletedGoogleEvents)
      googleCompleted.current = [...googleCompleted.current, ...uncompletedGoogleEvents.map(e => e.id)]
    }

    // Google Ads
    if (data && data.publicShop) {
      const googleAdsTrackers = data.publicShop.trackingPixels.filter(t => t.platform === 'GOOGLE_ADS')
      const uncompletedGoogleAdsEvents = events.filter(e => !googleAdsCompleted.current.includes(e.id))

      googleAdsFire(uncompletedGoogleAdsEvents, googleAdsTrackers)
      googleAdsCompleted.current = [...googleAdsCompleted.current, ...uncompletedGoogleAdsEvents.map(e => e.id)]
    }

    // Google Tag Manager
    if (googleTagManagerReady) {
      const uncompletedGoogleTagEvents = events.filter(e => !googleTagCompleted.current.includes(e.id))
      googleTagManagerFire(uncompletedGoogleTagEvents)
      googleTagCompleted.current = [...googleTagCompleted.current, ...uncompletedGoogleTagEvents.map(e => e.id)]
    }

    // Snapchat
    if (snapReady) {
      const uncompletedSnapEvents = events.filter(e => !snapCompleted.current.includes(e.id))
      snapFire(uncompletedSnapEvents)
      snapCompleted.current = [...snapCompleted.current, ...uncompletedSnapEvents.map(e => e.id)]
    }
  }, [events, facebookReady, googleReady, googleTagManagerReady, snapReady, data])

  return null
}

export default ThirdPartyPixels
