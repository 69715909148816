import React, { useState, useEffect, useCallback } from 'react'
import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import { Base64 } from 'js-base64'

const GenericCreditCardForm = ({ nonceReceived, setRequestNonce }) => {
  // State //

  const [creditCard, setCreditCard] = useState({
    ccn: '',
    exp: '',
    cvc: '',
  })

  const { meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } = usePaymentInputs()

  const handleNonceReceived = useCallback(
    generateCardNonce => {
      const cardNonce = Base64.encode(
        `${creditCard.ccn.replace(/\s/g, '')}|${creditCard.exp.replace(/\s/g, '')}|${creditCard.cvc.replace(/\s/g, '')}`
      )
      const errors = Object.keys(meta.erroredInputs).filter(key => key !== 'zip' && meta.erroredInputs[key])

      nonceReceived(
        cardNonce,
        errors.length > 0
          ? errors.map(key => {
              return { field: 'CREDIT_CARD', message: meta.erroredInputs[key] }
            })
          : false
      )
    },
    [nonceReceived, meta.erroredInputs, creditCard]
  )

  useEffect(() => {
    setRequestNonce(handleNonceReceived)
  }, [setRequestNonce, handleNonceReceived])

  const handleCcn = e => {
    setCreditCard({ ...creditCard, ccn: e.target.value })
  }

  const handleExp = e => {
    setCreditCard({ ...creditCard, exp: e.target.value })
  }

  const handleCvc = e => {
    setCreditCard({ ...creditCard, cvc: e.target.value })
  }

  return (
    <div>
      <div className="w-full">
        <div className="w-full">
          <div className="flex items-center p-3 border rounded" style={{ borderColor: '#ccc' }}>
            <svg {...getCardImageProps({ images })} />
            <input
              className="w-full ml-2 text-lg outline-none"
              {...getCardNumberProps({ onChange: handleCcn })}
              value={creditCard.ccn}
            />
          </div>
        </div>

        <div className="flex flex-col items-start w-full lg:flex-row lg:justify-between">
          <div className="flex-none w-full p-3 mt-2 border rounded lg:mr-1 lg:flex-1" style={{ borderColor: '#ccc' }}>
            <input
              className="w-full text-lg outline-none"
              {...getExpiryDateProps({ onChange: handleExp })}
              value={creditCard.exp}
            />
          </div>
          <div className="flex-none w-full p-3 mt-2 border rounded lg:ml-1 lg:flex-1" style={{ borderColor: '#ccc' }}>
            <input
              className="w-full text-lg outline-none"
              {...getCVCProps({ onChange: handleCvc })}
              value={creditCard.cvc}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenericCreditCardForm
