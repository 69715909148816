/** @jsx jsx */
import { useState, useEffect, useContext } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import { client, hostedFields } from 'braintree-web'
import { css, jsx } from '@emotion/core'

const FormLoading = () => {
  return <div className="text-sm text-gray-600 text-center w-full m-4">Securing Payment Form...</div>
}

const Braintree = ({ shopPaymentGateway, nonceReceived, setRequestNonce }) => {
  const [formCreated, setFormCreated] = useState(false)
  const [braintreeLoaded, setBraintreeLoaded] = useState(false)

  const { theme } = useContext(ThemeContext)

  const branding = css`
    & {
      box-shadow: 0 0 0 1px #cbd5e0;
    }
    &:focus {
      box-shadow: 0 0 0 2px ${theme.primaryBrandColor};
    }
  `

  useEffect(() => {
    if (!formCreated) {
      setFormCreated(true)
      client
        .create({
          authorization: shopPaymentGateway.publicCreds.client_token,
        })
        .then(clientInstance => {
          hostedFields.create(
            {
              client: clientInstance,
              styles: {
                input: {
                  color: '#282c37',
                  'font-size': '16pt',
                  transition: 'color 0.1s',
                },
                '.invalid': {
                  color: '#FC8181',
                },
                '::-webkit-input-placeholder': {
                  color: '#A0AEC0',
                },
                ':-moz-placeholder': {
                  color: '#A0AEC0',
                },
                '::-moz-placeholder': {
                  color: '#A0AEC0',
                },
                ':-ms-input-placeholder': {
                  color: '#A0AEC0',
                },
              },
              fields: {
                number: {
                  selector: '#braintree-card-number',
                  placeholder: '1111 1111 1111 1111',
                },
                cvv: {
                  selector: '#braintree-cvv',
                  placeholder: '123',
                },
                expirationDate: {
                  selector: '#braintree-exp',
                  placeholder: '10 / 23',
                },
              },
            },
            (err, hostedFields) => {
              if (err) {
                console.log(err)
                return
              }

              hostedFields.on('validityChange', event => {
                Object.keys(event.fields).forEach(key => {
                  console.log(key)
                })
              })

              setBraintreeLoaded(true)

              setRequestNonce(() => {
                hostedFields.tokenize((error, payload) => {
                  if (error) {
                    nonceReceived(null, ([{ field: 'CREDIT_CARD', message: error.message }]: false))
                  } else {
                    nonceReceived(payload.nonce, null)
                  }
                })
              })
            }
          )
        })
    }
  }, [formCreated, shopPaymentGateway, setRequestNonce, nonceReceived])

  return (
    <div>
      {!braintreeLoaded ? <FormLoading /> : null}
      <div className={`${braintreeLoaded ? 'opacity-100' : 'opacity-0 h-0'}`}>
        <div id="braintree-card-number" className="h-12 rounded outline-none w-full p-3" css={branding} />
        <div className="flex items-center mt-2">
          <div className="w-1/2 pr-1">
            <div id="braintree-exp" className="h-12 rounded outline-none w-full p-3" css={branding} />
          </div>
          <div className="w-1/2 pl-1">
            <div id="braintree-cvv" className="h-12 rounded outline-none w-full p-3" css={branding} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Braintree
