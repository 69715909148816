import React, { useState, useContext, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext } from 'contexts/ThemeContext'
import { ProcessorContext } from 'contexts/ProcessorContext'
import { CheckoutContext } from 'contexts/CheckoutContext'
import { EventTrackerContext } from 'contexts/EventTrackerContext'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { CHECKOUT, CREATE_ORDER, APPLY_DISCOUNT } from 'graphql/checkouts'
import cryptoRandomString from 'crypto-random-string'
import Template from './Template'
import PageWindowData from 'components/PageWindowData'
import CustomScripts from 'components/CustomScripts'
import { updateLineItem } from 'utils/shopify'
import queryString from 'query-string'
import GoogleFontLoader from 'react-google-font-loader'
import CheckoutForm from 'components/CheckoutForm'

const CheckoutTemplate = ({ match, history, location }) => {
  // Params //

  const { checkoutId } = match.params
  const { cid, discount } = queryString.parse(location.search)

  // State //

  const [checkoutInit, setCheckoutInit] = useState(false)

  // Contexts //

  const { themeDispatch } = useContext(ThemeContext)
  const { setCreditCardNonceReceived, setPaypalApproved, setPaypalOrderId, setErrors: setProcessorErrors } = useContext(
    ProcessorContext
  )
  const { setLoading, setErrors: setOrderErrors } = useContext(CheckoutContext)
  const { setCheckoutIds, track } = useContext(EventTrackerContext)

  // Data //

  const { loading, error, data } = useQuery(CHECKOUT, {
    variables: { id: checkoutId },
  })

  const [applyDiscount] = useMutation(APPLY_DISCOUNT, {
    onError: () => {
      console.log('Error auto applying discount')
    },
  })

  // Functions //

  const createShopifyCart = useCallback(async items => {
    await Promise.all(
      items.map(async item => {
        return await updateLineItem(item.shopifyVariantId, item.quantity)
      })
    )
  }, [])

  const [createOrder, { loading: createOrderLoading }] = useMutation(CREATE_ORDER, {
    onError: error => {
      if (error.graphQLErrors) {
        //Check for pending checkout error
        const pendingError = error.graphQLErrors.find(error =>
          error.extensions && error.extensions.reason ? error.extensions.reason === 'CHECKOUT_PENDING' : false
        )
        if (pendingError) {
          if (pendingError.extensions.current_step_id) {
            history.replace(
              `${process.env.PUBLIC_URL}/checkouts/${pendingError.extensions.checkout.id}/order/${pendingError.extensions.order.id}/step/${pendingError.extensions.current_step_id}`
            )
          } else {
            history.replace(`${process.env.PUBLIC_URL}/orders/${pendingError.extensions.order.id}/thank-you`)
          }
        } else {
          const errors = error.graphQLErrors.map(e => ({
            message: e.message,
          }))

          setOrderErrors(errors)
        }
      } else {
        setOrderErrors([
          {
            message: 'There was an unknown error that occured while checking out, please contact customer support.',
          },
        ])
      }
    },
    onCompleted: async data => {
      track({
        type: 'PURCHASE',
        data: {
          orderId: data.createOrder.id,
          value: data.createOrder.total,
          currency: data.createOrder.currency,
          order: data.createOrder,
        },
      })

      window.localStorage.removeItem('cid')
      window.localStorage.removeItem('checkoutId')

      if (process.env.NODE_ENV === 'production') {
        await fetch('/cart/clear.js', { method: 'POST' })
      }

      setPaypalOrderId(null)

      const nextStepId = data.createOrder.checkout.currentFunnelStep
        ? data.createOrder.checkout.currentFunnelStep.id
        : 'THANK_YOU'

      if (nextStepId === 'THANK_YOU') {
        history.replace(`${process.env.PUBLIC_URL}/orders/${data.createOrder.id}/thank-you`)
      } else {
        history.replace(
          `${process.env.PUBLIC_URL}/checkouts/${data.createOrder.checkout.id}/order/${data.createOrder.id}/step/${nextStepId}`
        )
      }
    },
  })

  const createOrderFromCreditCard = useCallback(
    nonce => {
      const browserIp = window.localStorage.getItem('clientLocation')
        ? JSON.parse(window.localStorage.getItem('clientLocation')).query
        : ''

      createOrder({
        variables: {
          checkoutId,
          idempotencyKey: cryptoRandomString({ length: 25, type: 'url-safe' }),
          paymentNonce: nonce,
          paymentNonceSource: 'CREDIT_CARD',
          cid: window.localStorage.getItem('cid'),
          browserIp,
        },
      })
    },
    [createOrder, checkoutId]
  )

  const createOrderFromPayPal = useCallback(
    paypalOrderID => {
      const browserIp = window.localStorage.getItem('clientLocation')
        ? JSON.parse(window.localStorage.getItem('clientLocation')).query
        : ''

      createOrder({
        variables: {
          checkoutId,
          idempotencyKey: cryptoRandomString({ length: 25, type: 'url-safe' }),
          paymentNonce: paypalOrderID,
          paymentNonceSource: 'PAYPAL_CHECKOUT',
          cid: window.localStorage.getItem('cid'),
          browserIp,
        },
      })
    },
    [createOrder, checkoutId]
  )

  // Effects //

  // Setup Create Order Callbacks
  useEffect(() => {
    setCreditCardNonceReceived((nonce, errors) => {
      if (errors && errors.length > 0) {
        setProcessorErrors(errors)
      } else {
        createOrderFromCreditCard(nonce)
      }
    })

    setPaypalApproved(orderId => {
      createOrderFromPayPal(orderId)
    })
  }, [
    createOrderFromCreditCard,
    setCreditCardNonceReceived,
    createOrderFromPayPal,
    setPaypalApproved,
    setProcessorErrors,
  ])

  useEffect(() => {
    setLoading(createOrderLoading)
  }, [setLoading, createOrderLoading])

  // Update Shop Primary Color
  useEffect(() => {
    if (data && data.checkout.funnel.shop.primaryBrandColor) {
      themeDispatch({ type: 'UPDATE_PRIMARY_COLOR', color: data.checkout.funnel.shop.primaryBrandColor })
    }
  }, [data, themeDispatch])

  // Check for pending or completed order, send to appropriate location
  useEffect(() => {
    if (data) {
      if (data.checkout.completed) {
        window.location.replace(`${window.location.protocol}//${window.location.hostname}/`)
      }

      if (data.checkout.pending) {
        alert('Looks like you already completed this checkout, please click OK to continue.')

        if (data.checkout.currentFunnelStep) {
          history.push(
            `${process.env.PUBLIC_URL}/checkouts/${data.checkout.id}/order/${data.checkout.order.id}/step/${data.checkout.currentFunnelStep.id}`
          )
        } else {
          window.location.replace(`${window.location.protocol}//${window.location.hostname}/`)
        }
      }
    }
  }, [data, history])

  // Set Tracking Checkout IDs for events
  useEffect(() => {
    if (data && data.checkout) {
      setCheckoutIds([data.checkout.id, data.checkout.funnel.shop.id, data.checkout.funnel.id])
    }
  }, [data, setCheckoutIds])

  // Track Page View
  useEffect(() => {
    track({
      type: 'PAGE_VIEW',
      data: {
        uri: location.pathname,
        search: location.search,
      },
    })
  }, [track, location])

  // Track Init Checkout pixels
  useEffect(() => {
    if (!checkoutInit && data && data.checkout) {
      setCheckoutInit(true)

      track({
        type: 'INIT_CHECKOUT',
        data: {
          checkout: data.checkout,
        },
      })

      createShopifyCart(data.checkout.checkoutLineItems)
    }
  }, [track, data, checkoutInit, createShopifyCart])

  // Set CID from SMS Automations
  useEffect(() => {
    if (cid) {
      window.localStorage.setItem('cid', cid)
    }
  }, [cid])

  // Auto Apply Discount from URL
  useEffect(() => {
    if (discount && checkoutId) {
      applyDiscount({
        variables: {
          checkoutId,
          code: discount,
        },
      })
    }
  }, [discount, checkoutId, applyDiscount])

  // Auto Apply from window.localStorage.bcDiscountCode
  useEffect(() => {
    if (window.localStorage.getItem('bcDiscountCode')) {
      applyDiscount({
        variables: {
          checkoutId,
          code: window.localStorage.getItem('bcDiscountCode'),
        },
      })
    }
  }, [checkoutId, applyDiscount])

  if (error) {
    return <div className="w-full mt-8 text-center text-red-400">There was an error loading your checkout.</div>
  }

  if (loading || !data || !data.checkout) {
    return <div className="w-full mt-8 text-center text-gray-500">Loading Your Checkout...</div>
  }

  return (
    <div className="select-none">
      <CheckoutForm checkout={data.checkout}>
        <Template checkout={data.checkout} />
      </CheckoutForm>

      {data.checkout.funnel.shop.fontFamily ? (
        <GoogleFontLoader
          fonts={[
            {
              font: data.checkout.funnel.shop.fontFamily,
              weights: [200, 300, 400, 600],
            },
          ]}
        />
      ) : null}

      <PageWindowData page="CHECKOUT_PAGE" data={data} />

      <CustomScripts
        data={{
          funnelTag: data.checkout.funnel.tag,
          funnelName: data.checkout.funnel.name,
          checkoutTotal: data.checkout.total,
        }}
        scripts={data.checkout.funnel.shop.customScripts.filter(
          s => s.enabled && (s.location === 'ALL' || s.location === 'CHECKOUT')
        )}
      />
    </div>
  )
}

export default withRouter(CheckoutTemplate)
