/** @jsx jsx */
import { useContext, useState } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import InputMask from 'react-input-mask'
import { css, jsx } from '@emotion/core'

const FloatingLabelInput = ({
  label,
  name,
  placeholder,
  value,
  defaultValue,
  errors = {},
  showErrors = false,
  onChange,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value || defaultValue)

  const hasErrors = Object.keys(errors).includes(name) && showErrors

  const { theme } = useContext(ThemeContext)

  const branding = css`
    & {
      box-shadow: 0 0 0 1px #cbd5e0;
    }
    &:focus {
      box-shadow: 0 0 0 2px ${theme.primaryBrandColor};
    }
  `

  const errorCss = css`
    & {
      box-shadow: 0 0 0 2px #fc8181;
    }
  `

  const handleOnChange = e => {
    setLocalValue(e.target.value)
    if (onChange) onChange(e)
  }

  return (
    <div className="relative w-full">
      <label
        className={`absolute mt-1 mb-2 px-3 text-xs w-full text-gray-600 ${localValue ? 'inline-block' : 'hidden'}`}>
        {label}
      </label>
      <InputMask
        {...props}
        value={value}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder ? placeholder : label}
        className={`rounded outline-none w-full p-3 ${localValue ? 'pt-5 pb-1' : ''}`}
        css={hasErrors ? errorCss : branding}
        onChange={handleOnChange}
      />
      {hasErrors ? (
        <div className="mt-1 text-sm text-red-400">
          {Object.keys(errors)
            .filter(k => k === name)
            .map(e => errors[e])
            .join(', ')}
        </div>
      ) : null}
    </div>
  )
}

export default FloatingLabelInput
