import domains from 'utils/domains'

const trigram = string => {
  const padded = `  ${string} `.toLowerCase()

  let gramStart = 0
  let gramEnd = 3
  const grams = []

  while (gramEnd <= padded.length) {
    grams.push(padded.substring(gramStart, gramEnd))
    gramStart += 1
    gramEnd += 1
  }

  return grams
}

const distance = (aGrams, bGrams) => {
  const matches = aGrams.filter(value => bGrams.includes(value))
  const uniqueGrams = [...new Set(aGrams.concat(bGrams))]
  return Number((matches.length / uniqueGrams.length).toFixed(2))
}

const compare = (a, b) => {
  const aGrams = trigram(a)
  const bGrams = trigram(b)
  return distance(aGrams, bGrams)
}

const domainForEmail = (email = '') => {
  return email.split('@')[1]
}

const emailForDomain = (email = '', domain) => {
  return `${email.split('@')[0]}@${domain}`
}

const emailMatch = (email, threshold) => {
  const scores = domains
    .map(d => ({ domain: d, score: compare(trigram(d), trigram(domainForEmail(email))) }))
    .filter(d => d.score >= threshold)
    .sort((a, b) => b.score - a.score)

  return scores[0] ? scores[0].domain : null
}

export const correctEmail = (email = '', threshold = 0.3) => {
  const match = emailMatch(email, threshold)
  return match ? emailForDomain(email, match) : email
}
