import React, { useState, useEffect, useMemo, useCallback } from 'react'
import ProductHero from 'components/funnel-steps/ProductHero'
import CurrencyFormat from 'react-currency-format'
import QuantitySelector from 'components/funnel-steps/QuantitySelector'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import Confetti from 'react-dom-confetti'
import RichText from 'components/RichText'
import GoogleFontLoader from 'react-google-font-loader'
import { PayPalButton } from 'react-paypal-button-v2'

import 'animate.css'

const FunnelStepContent = ({
  order,
  step,
  design,
  selectedVariant,
  setSelectedVariant,
  quantity,
  setQuantity,
  handleAccept,
  handleDecline,
  acceptLoading,
  acceptError,
  onApprovePaypal,
  fontFamily,
}) => {
  const [addAnimation, setAddAnimation] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [timeLeft, setTimeLeft] = useState(5 * 60 + 2)
  const [showShippingTax, setShowShippingTax] = useState(false)
  const [options, setOptions] = useState({
    option1: selectedVariant ? selectedVariant.option1 : '',
    option2: selectedVariant ? selectedVariant.option2 : '',
    option3: selectedVariant ? selectedVariant.option3 : '',
  })
  const [showSoldOut, setShowSoldOut] = useState(false)

  const product = useMemo(() => step.product, [step.product])
  const usingPaypal = useMemo(
    () => order.customerPaymentMethod && order.customerPaymentMethod.gatewayType === 'PAYPAL_CHECKOUT',
    [order.customerPaymentMethod]
  )

  const [paypalLoading, setPaypalLoading] = useState(window.paypal === undefined && usingPaypal)

  const secondsToTime = time => {
    const mins =
      parseInt(time / 60) === 0 ? '00' : parseInt(time / 60) < 10 ? `0${parseInt(time / 60)}` : parseInt(time / 60)
    const secs = time % 60 < 10 ? `0${time % 60}` : time % 60
    return `${mins}:${secs}`
  }

  const offerValueString = useMemo(() => {
    if (!step) return ''

    return step.offerType === 'PERCENTAGE'
      ? `${step.offerValue}% OFF`
      : step.offerType === 'FIXED_AMOUNT'
      ? `$${step.offerValue} OFF`
      : ''
  }, [step])

  const unitSavings = useMemo(() => {
    if (!step) return 0.0

    return selectedVariant
      ? step.offerType === 'PERCENTAGE'
        ? selectedVariant.price * (step.offerValue / 100)
        : step.offerType === 'FIXED_AMOUNT'
        ? step.offerValue
        : 0.0
      : step.offerType === 'PERCENTAGE'
      ? product.productVariants[0].price * (step.offerValue / 100)
      : step.offerType === 'FIXED_AMOUNT'
      ? step.offerValue
      : 0.0
  }, [selectedVariant, product, step])

  const subtotal = useMemo(() => {
    return selectedVariant ? selectedVariant.price - unitSavings : product.productVariants[0].price - unitSavings
  }, [selectedVariant, product, unitSavings])

  const taxes = useMemo(() => {
    try {
      if (
        !order ||
        !order.checkout ||
        !order.shopifyTaxes ||
        order.shopifyTaxes === {} ||
        !order.shopifyTaxes.tax_lines ||
        !selectedVariant ||
        !selectedVariant.taxable
      )
        return 0.0

      return order.shopifyTaxes.tax_lines.reduce((acc, taxLine) => {
        if (taxLine && taxLine.rate && taxLine.rate > 0) {
          return acc + Math.round(subtotal * taxLine.rate * 100) / 100
        } else {
          return acc
        }
      }, 0)
    } catch {
      return 0.0
    }
  }, [subtotal, order, selectedVariant])

  const total = useMemo(() => {
    if (!step) return 0.0

    return subtotal + taxes + (step.shippingCost ? step.shippingCost : 0.0)
  }, [subtotal, taxes, step])

  const hasComparePricing = useMemo(() => {
    if (
      (selectedVariant && selectedVariant.compareAtPrice && selectedVariant.compareAtPrice > selectedVariant.price) ||
      (product.productVariants[0].compareAtPrice &&
        product.productVariants[0].compareAtPrice > product.productVariants[0].price)
    ) {
      return true
    } else {
      return false
    }
  }, [selectedVariant, product])

  const handleOptionSelect = (optionPosition, optionValue) => {
    setOptions({ ...options, [`option${optionPosition}`]: optionValue })
  }

  const createPaypalOrder = useCallback(
    (data, actions) => {
      const quantityTotal = subtotal * quantity + taxes + (step.shippingCost ? step.shippingCost : 0.0)
      const amount = Math.round(quantityTotal * 100) / 100
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: amount,
              currency_code: 'USD',
            },
          },
        ],
      })
    },
    [subtotal, taxes, step.shippingCost, quantity]
  )

  // Set Options and Default Variant
  useEffect(() => {
    const product = step.product

    if (product && product.productVariants[0]) {
      const variant = product.productVariants.filter(v => !v.soldOut)[0]
      setSelectedVariant(variant)
      setOptions({
        option1: variant.option1,
        option2: variant.option2,
        option3: variant.option3,
      })
    }
  }, [step, setSelectedVariant])

  // Set Variant based on selected options
  useEffect(() => {
    const product = step.product

    if (product) {
      const selectedPerm = product.productVariants.find(
        variant =>
          (!variant.option1 || variant.option1 === options['option1']) &&
          (!variant.option2 || variant.option2 === options['option2']) &&
          (!variant.option3 || variant.option3 === options['option3'])
      )
      if (selectedPerm && !selectedPerm.soldOut) {
        setSelectedVariant(selectedPerm)
      } else {
        setSelectedVariant(null)
      }

      if (selectedPerm && selectedPerm.soldOut) {
        setShowSoldOut(true)
      } else {
        setShowSoldOut(false)
      }
    }
  }, [step, options, setSelectedVariant])

  // New Funnel Step
  useEffect(() => {
    // Confetti
    const confettiTimer = setTimeout(() => {
      setShowConfetti(true)
      setTimeout(() => {
        setAddAnimation(false)
      }, 3000)
    }, 400)

    // Price Pulse
    const priceTimer = setTimeout(() => {
      setAddAnimation(true)
      setTimeout(() => {
        setAddAnimation(false)
      }, 1000)
    }, 1000)

    setTimeLeft(5 * 60 + 2)

    return () => {
      clearTimeout(confettiTimer)
      clearTimeout(priceTimer)
    }
  }, [step])

  // Count Down Timer
  useEffect(() => {
    if (!timeLeft) return
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  return (
    <div className="overflow-hidden" style={{ fontFamily }}>
      {fontFamily ? (
        <GoogleFontLoader
          fonts={[
            {
              font: fontFamily,
              weights: [200, 300, 400, 600],
            },
          ]}
        />
      ) : null}

      <div
        className="flex items-center justify-center h-12 lg:h-16"
        style={{ backgroundColor: design.headlineBgColor, color: design.headlineColor }}>
        <div className="text-lg lg:text-2xl">{design.headline}</div>
      </div>

      <div className="flex items-center justify-center">
        {design.enableConfetti ? (
          <Confetti
            active={showConfetti}
            config={{
              angle: '270',
              spread: window.innerWidth * 1.2,
              startVelocity: '30',
              elementCount: '150',
              dragFriction: '0.15',
              duration: '4000',
              stagger: 0,
              width: '10px',
              height: '10px',
              colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
            }}
          />
        ) : null}
      </div>

      <div className="w-full p-4 mx-auto lg:max-w-4xl">
        <div className="w-full text-center">
          <div className="text-xl font-semibold lg:text-3xl">{design.offerHeadline}</div>
          <div className="mt-2 text-base lg:text-2xl">{design.offerSubHeadline}</div>
        </div>
      </div>

      <div
        className="flex items-center justify-center h-16 px-4 lg:h-20"
        style={{ backgroundColor: design.timerBgColor }}>
        <div className="text-sm font-semibold lg:text-xl" style={{ color: design.timerTextColor }}>
          {design.timerText}
        </div>
        <div className="ml-2 text-lg font-bold lg:text-2xl" style={{ color: design.timerColor }}>
          {secondsToTime(timeLeft)}
        </div>
      </div>

      <div className="w-full p-6 mx-auto lg:max-w-4xl">
        <div className="flex flex-wrap items-start justify-center lg:flex-no-wrap">
          <div className="w-full lg:my-4 lg:w-1/2">
            <ProductHero
              product={product}
              selectedProductImageId={
                selectedVariant ? (selectedVariant.productImage ? selectedVariant.productImage.id : null) : null
              }
            />
          </div>
          <div className="w-full mx-auto mt-4 lg:mt-0 lg:w-1/2" style={{ maxWidth: '750px' }}>
            {design.productTitleHtml ? (
              <RichText html={design.productTitleHtml} />
            ) : (
              <div className="text-lg font-bold lg:text-2xl">{design.productTitle || product.title}</div>
            )}

            <div className="flex mt-2">
              <div className="flex-shrink">
                <div className="flex flex-col items-stretch justify-between h-full">
                  <div className="flex-grow text-xl font-bold" style={{ color: design.nowColor }}>
                    <div className={`animated ${addAnimation ? 'heartBeat' : ''}`}>
                      <CurrencyFormat
                        value={subtotal}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                  </div>
                  <div className="flex-shrink text-xs">{design.nowLabel}</div>
                </div>
              </div>

              {step.offerType !== 'NONE' ? (
                <div className="flex-shrink ml-4">
                  <div className="flex flex-col items-stretch justify-between h-full">
                    <div className="flex-grow text-base font-light line-through">
                      <CurrencyFormat
                        value={selectedVariant ? selectedVariant.price : product.productVariants[0].price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                    <div className="flex-shrink text-xs">{offerValueString}</div>
                  </div>
                </div>
              ) : null}
              {hasComparePricing ? (
                <div className="flex-shrink ml-4">
                  <div className="flex flex-col items-stretch justify-between h-full">
                    <div className="flex-grow text-sm font-light line-through">
                      <CurrencyFormat
                        value={
                          selectedVariant
                            ? selectedVariant.compareAtPrice
                              ? selectedVariant.compareAtPrice
                              : 0
                            : product.productVariants[0].compareAtPrice
                            ? product.productVariants[0].compareAtPrice
                            : 0
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                    <div className="flex-shrink text-xs">{design.wasLabel}</div>
                  </div>
                </div>
              ) : null}

              <div className="flex-shrink ml-4">
                <div
                  className="flex items-center cursor-pointer select-none"
                  onClick={() => setShowShippingTax(!showShippingTax)}>
                  <div className="flex-shrink text-sm text-gray-500 underline">+ Tax & Shipping</div>
                  {showShippingTax ? (
                    <FiChevronUp className="text-lg text-gray-500" />
                  ) : (
                    <FiChevronDown className="text-lg text-gray-500" />
                  )}
                </div>
              </div>
            </div>

            {showShippingTax ? (
              <div className="flex mt-2">
                <div className="flex-shrink">
                  <div className="flex items-center">
                    <div className="mr-1 text-sm text-gray-800">Shipping: </div>
                    <div className="text-sm text-gray-500">
                      {step.shippingCost && step.shippingCost > 0 ? (
                        <CurrencyFormat
                          value={step.shippingCost}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      ) : (
                        <div className="text-sm text-gray-500">FREE</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex-shrink ml-4">
                  <div className="flex items-center justify-start ">
                    <div className="mr-1 text-sm text-gray-800">Taxes: </div>
                    <div className="text-sm text-gray-500">
                      <CurrencyFormat
                        value={taxes}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-shrink ml-4">
                  <div className="flex items-center justify-start ">
                    <div className="mr-1 text-sm text-gray-800">Total: </div>
                    <div className="text-sm text-gray-500">
                      <CurrencyFormat
                        value={total}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {design.offerDescription && design.offerDescription.length > 0 ? (
              <div className="flex mt-2 text-sm">{design.offerDescription}</div>
            ) : null}

            {product.productVariants.filter(v => !v.soldOut).length > 1 ? (
              <div className="mt-2">
                {product.productOptions.map(option => {
                  return (
                    <div className="w-full" key={option.id}>
                      <div className="text-sm font-normal">{option.name}:</div>
                      <select
                        className="w-full min-w-full p-2 mb-2 border border-gray-500 rounded-sm lg:max-w-xs"
                        value={options[`option${option.position}`]}
                        onChange={e => handleOptionSelect(option.position, e.target.value)}>
                        <option value="">Select {option.name}</option>
                        {option.values.map(value => {
                          return (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  )
                })}
              </div>
            ) : null}

            {step.enableQuantity ? (
              <div className="mt-2">
                <QuantitySelector value={quantity} onChange={setQuantity} />
              </div>
            ) : null}

            {step.offerType !== 'NONE' ? (
              <div className="mt-4">
                <div className="flex items-center">
                  <div className="mr-2 text-sm">{design.saveLabel}</div>
                  <div className="text-sm font-semibold" style={{ color: design.saveColor }}>
                    <CurrencyFormat
                      value={unitSavings * quantity}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {acceptError ? (
              <div className="mt-3">
                {acceptError.graphQLErrors
                  ? acceptError.graphQLErrors.map(({ message }, index) => {
                      return (
                        <div className="text-sm text-red-400" key={index}>
                          {message}
                        </div>
                      )
                    })
                  : null}
              </div>
            ) : null}

            {showSoldOut ? (
              <div className="mt-3">
                <div className="text-sm text-red-400">
                  This option is currently sold out.
                </div>
              </div>
            ) : null}

            <div className={`${selectedVariant ? '' : 'hidden'}`}>
              <div className="mt-3">
                {order &&
                order.customerPaymentMethod &&
                order.customerPaymentMethod.gatewayType === 'PAYPAL_CHECKOUT' &&
                order.checkout &&
                order.checkout.funnel.paypal ? (
                  <>
                    <div className="relative">
                      {acceptLoading ? (
                        <div
                          className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-lg font-bold text-center text-white uppercase bg-gray-400 pointer-events-none"
                          style={{
                            zIndex: '9999',
                          }}>
                          Processing Order...
                        </div>
                      ) : paypalLoading ? (
                        <div
                          className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-lg font-bold text-center text-white uppercase bg-gray-400 pointer-events-none"
                          style={{
                            zIndex: '9999',
                          }}>
                          Loading...
                        </div>
                      ) : (
                        <div
                          className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center mx-auto text-lg font-bold text-center uppercase cursor-pointer pointer-events-none"
                          style={{
                            zIndex: '9999',
                            color: design.confirmTextColor,
                            backgroundColor: design.confirmBgColor,
                            maxWidth: '750px',
                            height: '55px',
                          }}>
                          {design.confirmText}
                        </div>
                      )}

                      <div className="z-10 mx-auto" style={{ maxWidth: '750px' }}>
                        <PayPalButton
                          options={{
                            clientId: order.checkout.funnel.paypal.clientId,
                            disableFunding: 'credit,card,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort',
                            commit: false,
                          }}
                          style={{
                            label: 'checkout',
                          }}
                          amount={total}
                          createOrder={createPaypalOrder}
                          onApprove={onApprovePaypal}
                          onButtonReady={() => setPaypalLoading(false)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {acceptLoading ? (
                      <div className="p-4 text-lg font-bold text-center text-white bg-gray-500">Loading...</div>
                    ) : (
                      <>
                        <div
                          className="p-4 text-lg font-bold text-center uppercase cursor-pointer"
                          style={{ color: design.confirmTextColor, backgroundColor: design.confirmBgColor }}
                          onClick={handleAccept}>
                          {design.confirmText}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className={`${selectedVariant ? 'hidden' : ''}`}>
              <div className="p-4 mt-3 text-lg font-bold text-center text-white uppercase bg-gray-500">
                Select Options Above
              </div>
            </div>

            <div className="mt-6">
              <div
                className="text-base text-center underline cursor-pointer"
                style={{ color: design.declineColor }}
                onClick={handleDecline}>
                {design.declineText}
              </div>
            </div>

            <div className="mt-6">
              <RichText html={design.descriptionHtml} />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <div
          className="w-full p-6 text-lg font-semibold text-center lg:text-2xl"
          style={{ color: design.highlightBannerColor, backgroundColor: design.highlightBannerBgColor }}>
          {design.highlightBannerText}
        </div>
      </div>
    </div>
  )
}

export default FunnelStepContent
