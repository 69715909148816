import React, { createContext, useReducer } from 'react'

const CheckoutContext = createContext()

const CheckoutContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_LOADING': {
        return { ...state, loading: action.loading }
      }
      case 'SET_ERRORS': {
        return { ...state, errors: action.errors }
      }
      case 'RESET_ERRORS': {
        return { ...state, errors: state.errors.filter(e => !action.fields.includes(e.field)), payPalOrderId: null }
      }
      default: {
        return state
      }
    }
  }

  const setLoading = loading => {
    dispatch({ type: 'SET_LOADING', loading })
  }

  const setErrors = errors => {
    dispatch({ type: 'SET_ERRORS', errors })
  }

  const resetErrors = () => {
    dispatch({ type: 'RESET_ERRORS' })
  }

  const context = {
    loading: false,
    setLoading,
    errors: [],
    setErrors,
    resetErrors,
  }

  const [state, dispatch] = useReducer(reducer, context)

  return <CheckoutContext.Provider value={state}>{children}</CheckoutContext.Provider>
}

const CheckoutContextConsumer = CheckoutContext.Consumer

export { CheckoutContext, CheckoutContextProvider, CheckoutContextConsumer }
