import React from 'react'

// Checkout Templates
import ShopifyCheckout from 'pages/checkouts/templates/ShopifyCheckout/ShopifyCheckout'
import ShopifySinglePageCheckout from 'pages/checkouts/templates/ShopifySinglePageCheckout/ShopifySinglePageCheckout'

const Template = ({ checkout }) => {
  switch (checkout.funnel.checkoutTemplate) {
    case 'SHOPIFY': {
      return <ShopifyCheckout checkout={checkout} />
    }
    case 'SHOPIFY_SINGLE_PAGE': {
      return <ShopifySinglePageCheckout checkout={checkout} />
    }
    default:
      return <ShopifyCheckout checkout={checkout} />
  }
}

export default Template
