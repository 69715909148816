import React, { useCallback, useContext } from 'react'
import { ProcessorContext } from 'contexts/ProcessorContext'
import { useFormikContext } from 'formik'
import { PayPalButton } from 'react-paypal-button-v2'

const TwoStepPayPal = React.memo(
  ({ history, total, clientId }) => {
    const { setPaypalOrderId, setSelectedMethod } = useContext(ProcessorContext)
    const { setValues, values } = useFormikContext()

    const createOrder = useCallback(
      (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: parseFloat(total).toFixed(2),
                currency_code: 'USD',
              },
            },
          ],
        })
      },
      [total]
    )

    const onApprove = useCallback(
      async (data, actions) => {
        const order = await actions.order.get()
        const address = order.purchase_units[0].shipping.address

        setPaypalOrderId(data.orderID)

        setValues({
          ...values,
          email: order.payer.email_address,
          shippingFirstName: order.payer.name.given_name,
          shippingLastName: order.payer.name.surname,
          shippingLine1: address.address_line_1,
          shippingLine2: address.address_line_2 ? address.address_line_2 : '',
          shippingCity: address.admin_area_2,
          shippingRegion: address.admin_area_1,
          shippingCountry: address.country_code,
          shippingZipcode: address.postal_code,
        })

        setSelectedMethod('PAYPAL')
      },
      [setPaypalOrderId, setValues, values, setSelectedMethod]
    )

    return (
      <PayPalButton
        options={{
          clientId,
          disableFunding: 'credit,card,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort',
          commit: false,
        }}
        amount={total}
        createOrder={createOrder}
        onApprove={onApprove}
      />
    )
  },
  (prevProps, nextProps) => {
    return prevProps.total === nextProps.total
  }
)

export default TwoStepPayPal
