import ApolloClient from 'apollo-boost'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { fetch } from 'whatwg-fetch'

const client = new ApolloClient({
  link: createHttpLink({ uri: process.env.REACT_APP_API_URL, fetch }),
  cache: new InMemoryCache(),
  headers: {
    'Content-Type': 'application/json',
  },
  uri: process.env.REACT_APP_API_URL,
})

export default client
