import React, { useState, useEffect, createContext } from 'react'
import { fetch } from 'whatwg-fetch'

const LocationContext = createContext()

const LocationContextProvider = ({ children }) => {
  const [data, setData] = useState(JSON.parse(window.localStorage.getItem('clientLocation')))

  useEffect(() => {
    if (!data) {
      try {
        fetch('https://pro.ip-api.com/json?key=wbMXEwu6tQIMeVU&fields=8450047').then(response => {
          response.json().then(data => {
            window.localStorage.setItem('clientLocation', JSON.stringify(data))
            setData(data)
          })
        })
      } catch {}
    }
  }, [data])

  const value = { location: data }

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
}

const LocationContextConsumer = LocationContext.Consumer

export { LocationContext, LocationContextProvider, LocationContextConsumer }
