import { useEffect } from 'react'

const PageWindowData = ({ page, data, orderData }) => {
  useEffect(() => {
    if (data) {
      switch (page) {
        case 'CHECKOUT_PAGE': {
          window.bcData = {
            checkout: {
              id: data.checkout.id,
              currency: data.checkout.currency,
              subtotal_price: data.checkout.subtotal,
              total_price: data.checkout.total,
              total_coupons_amount: data.checkout.savings,
              line_items: data.checkout.checkoutLineItems.map(l => ({
                product_id: l.shopifyProductId,
                variant_id: l.shopifyVariantId,
                quantity: l.quantity,
                line_price: l.quantity * l.productVariant.price,
                price: l.productVariant.price,
                sku: l.productVariant.sku,
                title: l.productVariant.product.title,
                handle: l.productVariant.product.handle,
                variant_title: l.productVariant.title,
              })),
            },
          }
          break
        }
        case 'OFFER_PAGE': {
          window.bcData = {
            product: {
              product_id: data.product.shopifyId,
              title: data.product.title,
            },
            order: {
              id: orderData.id,
              order_id: orderData.shopifyId,
              order_number: orderData.shopifyOrderNumber,
              subtotal_price: orderData.subtotal,
              total_price: orderData.total,
              total_shipping: orderData.shipping,
              total_taxes: orderData.taxes,
              total_coupons_amount: orderData.savings,
              payment_gateway: orderData.customerPaymentMethod.gatewayType,
              customer: {
                email: orderData.customer.email,
                phone: orderData.customer.phone,
              },
              shipping_address: {
                first_name: orderData.shippingAddress.firstName,
                last_name: orderData.shippingAddress.lastName,
                address1: orderData.shippingAddress.line1,
                address2: orderData.shippingAddress.line2,
                city: orderData.shippingAddress.city,
                province: orderData.shippingAddress.region,
                zipcode: orderData.shippingAddress.zipcode,
                country: orderData.shippingAddress.country,
                phone: orderData.shippingAddress.phone,
              },
              line_items: orderData.orderLineItems.map(l => ({
                product_id: l.productVariant.product.shopifyId,
                variant_id: l.productVariant.shopifyId,
                quantity: l.quantity,
                line_price: l.total,
                price: l.price,
                sku: l.productVariant.sku,
                title: l.productVariant.product.title,
                handle: l.productVariant.product.handle,
                variant_title: l.productVariant.title,
              })),
            },
          }
          break
        }
        case 'THANK_YOU_PAGE': {
          window.bcData = {
            order: {
              id: data.id,
              shopify_cart_token: data.checkout.shopifyCheckoutToken || '',
              order_id: data.shopifyId,
              order_number: data.shopifyOrderNumber,
              currency: data.currency,
              subtotal_price: data.subtotal,
              total_price: data.total,
              total_shipping: data.shipping,
              total_taxes: data.taxes,
              total_coupons_amount: data.savings,
              coupon: data.discount.discount
                ? {
                    coupon_type: data.discount.price_rule.value_type,
                    total_minus: data.savings,
                    coupon_code: data.discount.discount.code,
                  }
                : false,
              payment_gateway: data.customerPaymentMethod.gatewayType,
              customer: {
                email: data.customer.email,
                phone: data.customer.phone,
              },
              shipping_address: {
                first_name: data.shippingAddress.firstName,
                last_name: data.shippingAddress.lastName,
                address1: data.shippingAddress.line1,
                address2: data.shippingAddress.line2,
                city: data.shippingAddress.city,
                province: data.shippingAddress.region,
                zipcode: data.shippingAddress.zipcode,
                country: data.shippingAddress.country,
                phone: data.shippingAddress.phone,
              },
              line_items: data.orderLineItems.map(l => ({
                product_id: l.productVariant.product.shopifyId,
                variant_id: l.productVariant.shopifyId,
                quantity: l.quantity,
                line_price: l.total,
                price: l.price,
                sku: l.productVariant.sku,
                title: l.productVariant.product.title,
                handle: l.productVariant.product.handle,
                variant_title: l.productVariant.title,
              })),
            },
          }
          break
        }
        default: {
          break
        }
      }
    }
  }, [page, data, orderData])

  return null
}

export default PageWindowData
