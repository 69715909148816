/** @jsx jsx */
import { useContext, useMemo } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import { css, jsx } from '@emotion/core'

const RadioButton = ({ value, setValue, globalValue }) => {
  const { theme } = useContext(ThemeContext)

  const branding = css`
    background-color: ${theme.primaryBrandColor};
  `

  const selected = useMemo(() => value === globalValue, [value, globalValue])

  return (
    <div
      css={selected ? branding : null}
      onClick={() => setValue(value)}
      className={`flex items-center justify-center h-4 w-4 rounded-full cursor-pointer ${
        selected ? '' : 'bg-white border border-gray-400'
      }`}>
      {selected ? <div className="w-1 h-1 bg-white rounded-full" /> : null}
    </div>
  )
}

export default RadioButton
