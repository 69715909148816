import TagManager from 'react-gtm-module'

export const googleTagManagerInit = containerId => {
  TagManager.initialize({ gtmId: containerId })
}
export const googleTagManagerFire = events => {
  events.forEach(event => {
    if (event.type === 'INIT_CHECKOUT') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'checkout',
          ecommerce: {
            checkout: {
              actionField: {
                checkoutId: event.data.checkout.id,
                funnelTag: event.data.checkout.funnel.tag,
                checkoutTemplate: event.data.checkout.funnel.checkoutTemplate,
                revenue: event.data.checkout.total,
                tax: event.data.checkout.taxes,
              },
              products: event.data.checkout.checkoutLineItems.map(item => ({
                id: item.productVariant.id,
                name: item.productVariant.title,
                quantity: item.quantity,
                variant: item.productVariant.sku,
                price: item.productVariant.price,
              })),
            },
          },
        },
      })
    }

    if (event.type === 'PURCHASE') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: event.data.order.id,
                orderId: event.data.order.id,
                revenue: event.data.order.total,
                shipping: event.data.order.shipping,
                tax: event.data.order.taxes,
              },
              products: event.data.order.orderLineItems.map(item => ({
                id: item.productVariant.id,
                name: item.productTitle,
                quantity: item.quantity,
                variant: item.productVariant.sku,
                price: item.productVariant.price,
              })),
            },
          },
        },
      })
    }

    if (event.type === 'OFFER_ACCEPTED') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'offer_accepted',
          ecommerce: {
            purchase: {
              actionField: {
                id: `${event.data.order.id}_${event.data.stepId}`,
                orderId: event.data.order.id,
                stepId: event.data.stepId,
                stepProduct: event.data.productTitle,
                revenue: event.data.total,
              },
              products: [
                {
                  id: event.data.selectedVariant.id,
                  name: event.data.productTitle,
                  quantity: event.data.quantity,
                  variant: event.data.selectedVariant.sku,
                  price: event.data.price,
                },
              ],
            },
          },
        },
      })
    }

    if (event.type === 'ORDER_COMPLETED') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'order_completed',
          ecommerce: {
            purchase: {
              actionField: {
                id: event.data.order.id,
                orderId: event.data.order.id,
                revenue: event.data.order.total,
                shipping: event.data.order.shipping,
                tax: event.data.order.taxes,
              },
              products: event.data.order.orderLineItems.map(item => ({
                id: item.productVariant.id,
                name: item.productTitle,
                quantity: item.quantity,
                variant: item.productVariant.sku,
                price: item.productVariant.price,
              })),
            },
          },
        },
      })
    }
  })
}
