import React, { useContext, useEffect } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import CurrencyFormat from 'react-currency-format'
import { FiShoppingCart, FiChevronUp, FiChevronDown } from 'react-icons/fi'
import { useLazyQuery } from '@apollo/react-hooks'
import { CHECKOUT_LINE_ITEMS } from 'graphql/checkouts'

const CheckoutSummary = ({ checkoutId, showSummary, showSummaryManual, setShowSummaryManual, total }) => {
  const { theme } = useContext(ThemeContext)

  const [loadLineItems] = useLazyQuery(CHECKOUT_LINE_ITEMS, {
    variables: {
      id: checkoutId,
    },
  })

  useEffect(() => {
    loadLineItems()
  }, [loadLineItems])

  return (
    <div
      className="flex-shrink py-4 border-b border-gray-300 cursor-pointer lg:hidden"
      onClick={() => setShowSummaryManual(!showSummaryManual)}>
      <div className="flex items-center max-w-xl px-4 m-auto lg:px-12 lg:max-w-6xl">
        <div className="flex-shrink">
          <FiShoppingCart className="text-xl" style={{ color: theme.primaryBrandColor }} />
        </div>
        <div className="flex-grow ml-2 text-sm" style={{ color: theme.primaryBrandColor }}>
          {showSummary ? (
            <div className="flex items-center">
              <div className="flex-shrink mr-1 select-none">Hide order summary</div>
              <div className="flex-shrink">
                <FiChevronUp />
              </div>
            </div>
          ) : (
            <div className="flex items-center">
              <div className="flex-shrink mr-1 select-none">Show order summary</div>
              <div className="flex-shrink">
                <FiChevronDown />
              </div>
            </div>
          )}
        </div>
        <div className="flex-shrink text-lg font-normal text-gray-800">
          <CurrencyFormat
            value={total}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        </div>
      </div>
    </div>
  )
}

export default CheckoutSummary
