import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext } from 'contexts/ThemeContext'
import { ProcessorContext } from 'contexts/ProcessorContext'
import { CheckoutContext } from 'contexts/CheckoutContext'
import ProductBump from 'components/ProductBump'
import CustomerSummary from 'components/CustomerSummary'
import PaymentMethodSelector from 'components/PaymentMethodSelector'
import BillingInformation from 'components/BillingInformation'
import { FiChevronLeft } from 'react-icons/fi'
import Button from 'components/Button'
import { useFormikContext } from 'formik'

const PaymentStep = ({ checkout, history, match }) => {
  const { theme } = useContext(ThemeContext)
  const { handleCreditCardRequestNonce, handlePaypalApproved, paypalOrderId, selectedMethod, resetErrors } = useContext(
    ProcessorContext
  )
  const { loading, errors } = useContext(CheckoutContext)
  const { validateForm, setFieldTouched } = useFormikContext()

  const handleSubmit = async () => {
    resetErrors()

    const errors = await validateForm()

    const shippingStepValues = [
      'email',
      'acceptsMarketing',
      'shippingFirstName',
      'shippingLastName',
      'shippingLine1',
      'shippingLine2',
      'shippingCity',
      'shippingCountry',
      'shippingRegion',
      'shippingZipcode',
      'shippingPhone',
    ]

    const billingStepValues = [
      'billingFirstName',
      'billingLastName',
      'billingLine1',
      'billingLine2',
      'billingCity',
      'billingCountry',
      'billingRegion',
      'billingZipcode',
      'billingPhone',
    ]

    const shippingScopedErrors = Object.keys(errors).filter(k => shippingStepValues.includes(k))
    const billingScopedErrors = Object.keys(errors).filter(k => billingStepValues.includes(k))

    if (shippingScopedErrors.length > 0) {
      history.push({ pathname: `${match.url}`, search: '' })
      shippingScopedErrors.forEach(key => setFieldTouched(key, true))
    } else if (billingScopedErrors.length > 0) {
      billingScopedErrors.forEach(key => setFieldTouched(key, true))
    } else {
      if (selectedMethod === 'PAYPAL') {
        handlePaypalApproved(paypalOrderId)
      } else {
        handleCreditCardRequestNonce()
      }
    }
  }

  const handleBack = () => {
    history.push({ pathname: `${match.url}`, search: '?step=shipping' })
  }

  return (
    <>
      {checkout.funnel.bumpProduct ? (
        <div className="mb-4">
          <ProductBump checkout={checkout} />
        </div>
      ) : null}

      <CustomerSummary checkout={checkout} />

      <PaymentMethodSelector checkout={checkout} />

      <BillingInformation checkout={checkout} />

      <div className="w-full my-4 lg:mt-8">
        <div className="w-full lg:flex lg:items-center">
          <div className="lg:flex-shrink lg:order-2">
            <div className="my-4 text-base text-red-400">
              {errors.map((e, index) => {
                return <div key={index}>{e.message}</div>
              })}
            </div>

            {loading ? (
              <div className="p-4 text-sm text-center text-white bg-gray-500 rounded">Processing Your Order...</div>
            ) : paypalOrderId || selectedMethod !== 'PAYPAL' ? (
              <Button style={{ backgroundColor: theme.primaryBrandColor }} onClick={handleSubmit}>
                <div>Complete Purchase</div>
              </Button>
            ) : null}
          </div>

          <div className="mt-5 lg:mt-0 lg:flex-grow lg:order-1">
            <div className="flex items-center cursor-pointer hover:underline">
              <div>
                <FiChevronLeft className="text-xl text-gray-800" />
              </div>
              <div className="ml-1 text-sm text-gray-800" onClick={handleBack}>
                Return to shipping
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(PaymentStep)
