import React, { useState, useEffect, useContext, useMemo } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import CurrencyFormat from 'react-currency-format'
import { FaTruck } from 'react-icons/fa'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { CHECKOUT_SHIPPING_RATES, UPDATE_CHECKOUT } from 'graphql/checkouts'
import { CountryRegionData } from '@bettercart/react-country-region-selector'

const ShippingMethodsInternal = ({ checkout, errors }) => {
  const [selected, setSelected] = useState(null)

  const { theme } = useContext(ThemeContext)

  const hasErrors = useMemo(() => errors && Object.keys(errors).length > 0, [errors])

  const { data, loading } = useQuery(CHECKOUT_SHIPPING_RATES, {
    variables: {
      id: checkout.id,
    },
  })

  const [updateCheckout] = useMutation(UPDATE_CHECKOUT, {})

  const handleSelectRate = rate => {
    setSelected(rate)

    updateCheckout({
      variables: {
        id: checkout.id,
        checkout: {
          shippingRateId: rate.id,
        },
      },
    })
  }

  const shippingCountryData = CountryRegionData.find(c => c.code === data.checkout.shippingCountry)
  const shippingRegionOptional = shippingCountryData && shippingCountryData.regions.length < 1

  const canSelectShippingMethod = useMemo(() => {
    return data && data.checkout.shippingCountry && (data.checkout.shippingRegion || shippingRegionOptional)
  }, [data, shippingRegionOptional])

  useEffect(() => {
    if (data && data.checkout.shippingRate) {
      setSelected(data.checkout.shippingRate)
    }
  }, [data, setSelected])

  if (loading) {
    return (
      <>
        <div className="p-6 border border-gray-300 rounded">
          <div className="text-sm text-gray-600">Loading Shipping Rates...</div>
        </div>
      </>
    )
  }

  if (!canSelectShippingMethod) {
    return (
      <>
        <div className="flex items-center p-4 border border-gray-300 rounded">
          <div className="text-xl" style={{ color: theme.primaryBrandColor }}>
            <FaTruck />
          </div>
          <div className="ml-4 text-sm text-gray-800">Enter your shipping address to see shipping options...</div>
        </div>
      </>
    )
  }

  if (!data || !data.checkout.shippingRates || data.checkout.shippingRates.length < 1) {
    return (
      <>
        <div className={`relative p-4 border rounded ${hasErrors ? 'border-red-400' : 'border-gray-300'}`}>
          <div className="text-lg text-red-400">No Shipping Methods Available</div>

          <div className="mt-1 text-xs text-gray-500">
            Looks like we are currently not shipping to your country or region.
          </div>
          <div className="mt-1 text-xs text-gray-500">Please contact our support if you think this is a mistake</div>
        </div>
        {hasErrors ? (
          <div className="mt-1 text-sm text-red-400">
            {Object.keys(errors)
              .map(e => errors[e])
              .join(', ')}
          </div>
        ) : null}
      </>
    )
  }

  return (
    <>
      <div className="relative">
        <div className={`relative border rounded ${hasErrors ? 'border-red-400' : 'border-gray-300'}`}>
          {data.checkout.shippingRates.map((rate, index) => {
            const isSelected = selected && selected.id === rate.id
            const last = index === data.checkout.shippingRates.length - 1

            return (
              <div
                className={`cursor-pointer hover:bg-gray-200 ${last ? '' : 'border-b border-gray-300 '}`}
                key={`shipping-method-${rate.id}`}
                onClick={() => handleSelectRate(rate)}>
                <div className="flex items-center p-4">
                  <div className="flex-shrink mr-2">
                    <div
                      style={{ backgroundColor: isSelected ? theme.primaryBrandColor : '#FFFFFF' }}
                      className={`flex rounded-full h-4 w-4 text-center cursor-pointer ${
                        isSelected ? '' : 'border border-gray-400'
                      }`}>
                      <div className="w-1 h-1 m-auto bg-white rounded-full" />
                    </div>
                  </div>
                  <div className="flex-grow text-sm text-gray-800">{rate.name}</div>
                  <div className="flex-shrink text-sm">
                    <CurrencyFormat value={rate.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {hasErrors ? (
        <div className="mt-1 text-sm text-red-400">
          {Object.keys(errors)
            .map(e => errors[e])
            .join(', ')}
        </div>
      ) : null}
    </>
  )
}

export default ShippingMethodsInternal
