import React from 'react'
import { Formik, Form } from 'formik'
import CheckoutFormContext from 'contexts/CheckoutFormContext'
import * as Yup from 'yup'
import { validate } from 'utils/email-mx'
import { CountryRegionData } from '@bettercart/react-country-region-selector'

const CheckoutForm = ({ checkout, children }) => {
  const shippingCountryData = CountryRegionData.find(c => c.code === checkout.shippingCountry)
  const shippingRegionOptional = shippingCountryData && shippingCountryData.regions.length < 1
  const shippingZipcodeOptional = shippingCountryData && !shippingCountryData.zipRequired

  const billingCountryData = CountryRegionData.find(c => c.code === checkout.billingCountry)
  const billingRegionOptional = !billingCountryData || billingCountryData.regions.length < 1
  const billingZipcodeOptional = billingCountryData && !billingCountryData.zipRequired

  const CheckoutSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email not valid')
      .required('Email is required')
      .test('email-mx', 'Invalid email address domain, check for typos!', async value => {
        try {
          const response = await validate(value)
          return response.valid
        } catch {
          return true
        }
      }),
    acceptsMarketing: Yup.boolean().required('Must accept or decline marketing'),
    shippingFirstName: Yup.string().required('Shipping first name is required'),
    shippingLastName: Yup.string().required('Shipping last name is required'),
    shippingLine1: Yup.string()
      .min(2, 'Shipping Line 1 must be at least 2 characters')
      .required('Shipping line 1 is required'),
    shippingLine2: Yup.string().notRequired(),
    shippingCity: Yup.string().required('Shipping city is required'),
    shippingRegion: shippingRegionOptional
      ? Yup.string().nullable()
      : Yup.string().required('Shipping region is required'),
    shippingCountry: Yup.string().required('Shipping country is required'),
    shippingZipcode: shippingZipcodeOptional
      ? Yup.string().nullable()
      : Yup.string().required('Shipping zipcode is required'),
    shippingPhone: checkout.funnel.phoneOptional
      ? Yup.string().notRequired()
      : Yup.string().required('Shipping phone is required'),
    sameBillingAsShipping: Yup.boolean().required('Billing address setting required'),
    billingFirstName: Yup.string().when('sameBillingAsShipping', {
      is: false,
      then: Yup.string().required('Billing first name is required'),
      otherwise: Yup.string().nullable(),
    }),
    billingLastName: Yup.string().when('sameBillingAsShipping', {
      is: false,
      then: Yup.string().required('Billing last name is required'),
      otherwise: Yup.string().nullable(),
    }),
    billingLine1: Yup.string().when('sameBillingAsShipping', {
      is: false,
      then: Yup.string().min(2, 'Billing Line 1 must be at least 2 characters').required('Billing line 1 is required'),
      otherwise: Yup.string().nullable(),
    }),
    billingLine2: Yup.string().nullable(),
    billingCity: Yup.string().when('sameBillingAsShipping', {
      is: false,
      then: Yup.string().required('Billing city is required'),
      otherwise: Yup.string().nullable(),
    }),
    billingRegion: Yup.string().when('sameBillingAsShipping', {
      is: false,
      then: billingRegionOptional ? Yup.string().nullable() : Yup.string().required('Billing region is required'),
      otherwise: Yup.string().nullable(),
    }),
    billingCountry: Yup.string().when('sameBillingAsShipping', {
      is: false,
      then: Yup.string().required('Billing country is required'),
      otherwise: Yup.string().nullable(),
    }),
    billingZipcode: Yup.string().when('sameBillingAsShipping', {
      is: false,
      then: billingZipcodeOptional ? Yup.string().nullable() : Yup.string().required('Billing zipcode is required'),
      otherwise: Yup.string().nullable(),
    }),
    billingPhone: Yup.string().notRequired(),
  })

  return (
    <Formik
      initialValues={{
        email: checkout.email || '',
        acceptsMarketing: checkout.acceptsMarketing,
        shippingFirstName: checkout.shippingFirstName || '',
        shippingLastName: checkout.shippingLastName || '',
        shippingLine1: checkout.shippingLine1 || '',
        shippingLine2: checkout.shippingLine2 || '',
        shippingCity: checkout.shippingCity || '',
        shippingRegion: checkout.shippingRegion || 'AL',
        shippingCountry: checkout.shippingCountry || 'US',
        shippingZipcode: checkout.shippingZipcode || '',
        shippingPhone: checkout.shippingPhone || '',
        sameBillingAsShipping: checkout.sameBillingAsShipping,
        billingFirstName: checkout.billingFirstName || '',
        billingLastName: checkout.billingLastName || '',
        billingLine1: checkout.billingLine1 || '',
        billingLine2: checkout.billingLine2 || '',
        billingCity: checkout.billingCity || '',
        billingRegion: checkout.billingRegion || '',
        billingCountry: checkout.billingCountry || '',
        billingZipcode: checkout.billingZipcode || '',
        billingPhone: checkout.billingPhone || '',
      }}
      validationSchema={CheckoutSchema}
      onSubmit={(values, actions) => {
        console.log(JSON.stringify(values, actions, 2))
      }}>
      <Form>
        {children}
        <CheckoutFormContext id={checkout.id} />
      </Form>
    </Formik>
  )
}

export default CheckoutForm
