import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import CheckoutHeader from 'components/CheckoutHeader'
import CheckoutSummary from 'components/CheckoutSummary'
import DiscountCodeInput from 'components/DiscountCodeInput'
import FinancialsTable from 'components/FinancialsTable'
import LineItems from 'components/LineItems'
import Footer from 'components/Footer'

import LeftNavigator from 'pages/checkouts/templates/ShopifyCheckout/LeftNavigator'

const ShopifyCheckout = ({ location, checkout }) => {
  const { step } = queryString.parse(location.search)

  // State //

  const [showSummary, setShowSummary] = useState(false)
  const [showSummaryManual, setShowSummaryManual] = useState(false)
  const [isMobile, setIsMobile] = useState(true)

  // Functions

  const handleResize = () => {
    setShowSummary(window.innerWidth > 1024 || showSummaryManual)
    setIsMobile(window.innerWidth < 1024)
  }

  // Effects //

  useEffect(() => {
    setShowSummary(showSummaryManual)
  }, [showSummaryManual])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })

  useEffect(() => {
    handleResize()
  })

  useEffect(() => {
    if (checkout && checkout.funnel.showSummary) {
      setShowSummaryManual(true)
    }
  }, [checkout])

  return (
    <div className="w-full h-full text-gray-800" style={{ fontFamily: checkout.funnel.shop.fontFamily }}>
      <div className="flex flex-col h-full">
        <CheckoutHeader
          headerBackground={checkout.funnel.shop.headerBackground}
          logo={checkout.funnel.shop.logo}
          name={checkout.funnel.shop.name}
        />

        <CheckoutSummary
          checkoutId={checkout.id}
          showSummary={showSummary}
          showSummaryManual={showSummaryManual}
          setShowSummaryManual={setShowSummaryManual}
          total={checkout.total}
        />

        <div className="flex-grow w-full h-auto max-w-xl m-auto lg:px-12 lg:max-w-6xl">
          <div className="flex flex-col h-full lg:flex-row">
            {/* LEFT */}
            <div className="flex-grow order-2 w-full lg:flex-grow lg:w-7/12 lg:order-1 lg:pt-6 lg:pr-16 lg:border-r lg:border-gray-300">
              <div className="flex flex-col h-full">
                <div className="flex-grow">
                  <LeftNavigator step={step} checkout={checkout} />
                </div>

                <div className="flex-shrink">
                  {isMobile && checkout.funnel.shop.trustBadge ? (
                    <div className="mx-4 mb-4">
                      <img src={checkout.funnel.shop.trustBadge} alt="trust" />
                    </div>
                  ) : null}

                  <Footer
                    tcpa={checkout.requiresTcpa}
                    shopId={checkout.funnel.shop.id}
                    accepts={checkout.acceptsMarketing}
                  />
                </div>
              </div>
            </div>

            {/* RIGHT */}
            <div className="flex-shrink order-1 w-full bg-gray-200 lg:flex-grow lg:w-5/12 lg:order-2 lg:pt-8 lg:px-8 sm:bg-white">
              {showSummary ? (
                <div className="px-4 pb-4 mt-4 border-b border-gray-300" id="checkout_summary">
                  <LineItems checkoutId={checkout.id} />

                  <div className="pb-4 mt-4 border-b border-gray-300">
                    <DiscountCodeInput checkout={checkout} />
                  </div>

                  <div className="mt-4">
                    <FinancialsTable checkoutId={checkout.id} />
                  </div>

                  {!isMobile && checkout.funnel.shop.trustBadge ? (
                    <div className="mx-4 mt-4 -mb-2 lg:mt-6 lg:mb-0">
                      <img src={checkout.funnel.shop.trustBadge} alt="trust" />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ShopifyCheckout)
