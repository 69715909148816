import React, { useState, useEffect, useCallback } from 'react'
import Script from 'react-load-script'

const FormLoading = () => {
  return <div className="text-sm text-gray-600 text-center w-full m-4">Securing Payment Form...</div>
}

const SquarePayments = ({ shopPaymentGateway, nonceReceived, setRequestNonce }) => {
  const [paymentForm, setPaymentForm] = useState()
  const [squareLoaded, setSquareLoaded] = useState(false)

  const requestToken = useCallback(
    async card => {
      try {
        const result = await card.tokenize()

        if (result.status === 'OK') {
          console.log(`Payment token is ${result.token}`)

          nonceReceived(result.token, false)
        }

        if (result.status === 'INVALID') {
          nonceReceived(
            '',
            result.errors.map(e => {
              return { field: 'CREDIT_CARD', message: e.message }
            })
          )
        }
      } catch (errors) {
        nonceReceived(
          '',
          errors.map(e => {
            return { field: 'CREDIT_CARD', message: e.message }
          })
        )
      }
    },
    [nonceReceived]
  )

  useEffect(() => {
    const setupCard = async payments => {
      const card = await payments.card()
      const form = await card.attach('#sq-card')

      setPaymentForm(form)

      setRequestNonce(() => {
        requestToken(card)
      })
    }

    if (window.Square && window.Square.payments) {
      const payments = window.Square.payments(shopPaymentGateway.paymentGateway.appPublicCreds.app_id, '')

      if (!paymentForm) {
        setupCard(payments)
      }
    }
  }, [squareLoaded, shopPaymentGateway, paymentForm, setRequestNonce, requestToken])

  return (
    <div>
      <Script url={process.env.REACT_APP_SQUARE_SDK_URL} onLoad={() => setSquareLoaded(true)} />
      {squareLoaded ? (
        <div>
          <div id="sq-card" />
        </div>
      ) : (
        <FormLoading />
      )}
    </div>
  )
}

export default SquarePayments
