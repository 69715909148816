import React, { useContext } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import { useMutation } from '@apollo/react-hooks'
import { CHECKOUT_FINANCIALS, DETATCH_DISCOUNT } from 'graphql/checkouts'
import DiscountCodeInput from 'components/DiscountCodeInput'
import CurrencyFormat from 'react-currency-format'
import { FiTag, FiXCircle, FiLoader } from 'react-icons/fi'
import Color from 'color'

const InlineDiscountCodeInput = ({ checkout }) => {
  // Contexts //

  const { theme } = useContext(ThemeContext)

  // Functions //

  const [detatchDiscount, { loading: detatchDiscountLoading }] = useMutation(DETATCH_DISCOUNT, {
    onError: err => {},
    refetchQueries: [
      {
        query: CHECKOUT_FINANCIALS,
        variables: { id: checkout.id },
      },
    ],
  })

  const handleDetatch = () => {
    detatchDiscount({
      variables: {
        checkoutId: checkout.id,
      },
    })
  }

  return (
    <div className="mt-6">
      <div
        className="text-lg font-semibold"
        style={{
          color: Color(theme.primaryBrandColor).darken(0.25).string(),
        }}>
        Discount Code
      </div>
      <div className="mt-4">
        {checkout.appliedDiscount && checkout.appliedDiscount.code ? (
          <div className="flex items-center">
            <div className="flex-grow">
              <div className="flex items-center ">
                <div className="">
                  <FiTag className="text-xl text-gray-800" />
                </div>
                <div className="mx-1 text-base font-semibold text-gray-800 uppercase">
                  {checkout.appliedDiscount.code}
                </div>
                <div className="cursor-pointer" onClick={handleDetatch}>
                  {detatchDiscountLoading ? (
                    <FiLoader className="text-xl text-gray-800 icon-spin" />
                  ) : (
                    <FiXCircle className="text-xl text-gray-800" />
                  )}
                </div>
              </div>
            </div>

            <div className="">
              <div className="text-right text-gray-500 line-through">
                {checkout.subtotal ? (
                  <CurrencyFormat
                    value={checkout.subtotal}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={'$'}
                  />
                ) : null}
              </div>

              {checkout.total ? (
                <div className="flex item-center">
                  <div className="mr-2">Total</div>
                  <div className="font-semibold">
                    <CurrencyFormat
                      value={checkout.total}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      prefix={'$'}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <DiscountCodeInput checkout={checkout} />
        )}
      </div>
    </div>
  )
}

export default InlineDiscountCodeInput
