import { fetch } from 'whatwg-fetch'
import shippingMethodsData from 'data/shippingMethods.json'

export const shopifyCart = async () => {
  if (process.env.NODE_ENV === 'production') {
    return new Promise(async (resolve, reject) => {
      try {
        const cart = await (await fetch('/cart.js')).json()
        resolve(cart)
      } catch (err) {
        reject(err)
      }
    })
  } else {
    console.log('FAKE CART ITEMS')
    return new Promise(async resolve => {
      setTimeout(() => {
        resolve({
          token: 'f0f1a5e21b259a33c949777c51fc975a',
          note: null,
          attributes: {},
          original_total_price: 59430,
          total_price: 59430,
          total_discount: 0,
          total_weight: 5454.4482,
          item_count: 4,
          items: [
            {
              id: 7435880955938,
              properties: {},
              quantity: 1,
              variant_id: 31497145778248,
              key: '7435880955938:a661ef420cab8b78b3fe98516e8c882d',
              title: "Low 1 | Slate - Men's / Slate / 10",
              price: 17430,
              original_price: 17430,
              discounted_price: 17430,
              line_price: 17430,
              original_line_price: 17430,
              total_discount: 0,
              discounts: [],
              sku: 'OC-SN-LO-0113-43',
              grams: 1724,
              vendor: 'Oliver Cabell',
              taxable: true,
              product_id: 4371173474376,
              product_has_only_default_variant: false,
              gift_card: false,
              final_price: 17430,
              final_line_price: 17430,
              url: '/products/low-1-slate?variant=7435880955938',
              featured_image: {
                aspect_ratio: 1.072,
                alt: 'Low 1 | Slate',
                height: 1399,
                url:
                  'https://cdn.shopify.com/s/files/1/1002/1104/products/Profile_19e75a4f-4abf-4c47-b812-fe633d00bf41.jpg?v=1569139690',
                width: 1500,
              },
              image:
                'https://cdn.shopify.com/s/files/1/1002/1104/products/Profile_19e75a4f-4abf-4c47-b812-fe633d00bf41.jpg?v=1569139690',
              handle: 'low-1-slate',
              requires_shipping: true,
              product_type: 'Male',
              product_title: 'Low 1 | Slate',
              product_description:
                'Construction |\u00a0Hand stitched and lasted in\u00a0Marche, Italy\nUpper |\u00a03oz full grain\u00a0calfskin leather from Marche, Italy\nOutsole |\u00a0Margom Italian outsoles \u2014\u00a0renowned for their durable, lightweight rubber\u00a0\nFootbed | Italian\u00a0calf-skin\u00a0over removable molded in-sole\nLining |\u00a0Italian calfskin\u00a0leather\nCounters | 3oz full grain\u00a0calfskin leather from Marche, Italy\nLaces | Italian cotton',
              variant_title: "Men's / Slate / 10",
              variant_options: ["Men's / Slate", '10'],
              options_with_values: [
                { name: 'Color', value: "Men's / Slate" },
                { name: 'Size', value: '10' },
              ],
              line_level_discount_allocations: [],
              line_level_total_discount: 0,
            },
            {
              id: 31211494244425,
              properties: {},
              quantity: 1,
              variant_id: 31681902444616,
              key: '31211494244425:44ae4d6adb366bd0ad156e7a55d14d3f',
              title: 'Wilson | Waxed Rustic - Wilson | Waxed Rustic / 12',
              price: 22780,
              original_price: 22780,
              discounted_price: 22780,
              line_price: 22780,
              original_line_price: 22780,
              total_discount: 0,
              discounts: [],
              sku: 'OC-BO-SB-0205-45',
              grams: 1724,
              vendor: 'Oliver Cabell',
              taxable: true,
              product_id: 4416079036488,
              product_has_only_default_variant: false,
              gift_card: false,
              final_price: 22780,
              final_line_price: 22780,
              url: '/products/wilson-waxed-rustic?variant=31211494244425',
              featured_image: {
                aspect_ratio: 1.072,
                alt: 'Wilson | Waxed Rustic',
                height: 1399,
                url:
                  'https://cdn.shopify.com/s/files/1/1002/1104/products/Boot_RusticBrown_Profile_1180R2.jpg?v=1582054583',
                width: 1500,
              },
              image:
                'https://cdn.shopify.com/s/files/1/1002/1104/products/Boot_RusticBrown_Profile_1180R2.jpg?v=1582054583',
              handle: 'wilson-waxed-rustic',
              requires_shipping: true,
              product_type: 'Male',
              product_title: 'Wilson | Waxed Rustic',
              product_description:
                '\nConstruction |\u00a0Blake\u00a0stitch\u00a0(resole-able)\nUpper |\u00a0Waxed full-grain\u00a0(fully water-proof)\u00a0from Charles F Stead, Leeds, England\nOutsole |\u00a0Custom water resistant anti-slip studded rubber outsoles from England\nFootbed |\u00a0Vegetable tanned leather\u00a0over removable molded in-sole for comfort (replaceable with orthotics)\nLining |\u00a0Vegetable tanned leather from Venice, Italy\nLaces | Premium Italian leather laces\nMidsole |\u00a0Natural\u00a0vegetable\u00a0tanned leather from Spain (foot forming)\nEyelets |\u00a0Vintage brass eyelets sourced from Spain\nShank | Custom made steel\u00a0shank from Germany\u00a0\nComponents |\u00a0Custom Spanish\u00a0brass nails\nLast |\u00a0812\u00a0\nFinishing | Hand stitched lasted in Albacete, Spain',
              variant_title: 'Wilson | Waxed Rustic / 12',
              variant_options: ['Wilson | Waxed Rustic', '12'],
              options_with_values: [
                { name: 'Color', value: 'Wilson | Waxed Rustic' },
                { name: 'Size', value: '12' },
              ],
              line_level_discount_allocations: [],
              line_level_total_discount: 0,
            },
          ],
          requires_shipping: true,
          currency: 'USD',
          items_subtotal_price: 59430,
          cart_level_discount_applications: [],
        })
      }, 500)
    })
  }
}

export const requestShippingMethods = async checkout => {
  if (process.env.NODE_ENV === 'production') {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(
          `/cart/shipping_rates.json?shipping_address[zip]=${checkout.shippingZipcode}&shipping_address[country]=${checkout.shippingCountry}&shipping_address[province]=${checkout.shippingRegion}`
        )

        if (response.ok) {
          const data = await response.json()
          const methods = data.shipping_rates

          if (methods && methods.length > 0) {
            resolve(methods)
          } else {
            reject({
              message: 'No shipping rates available for shipping address.',
              data: {
                country: checkout.shippingCountry,
                region: checkout.shippingRegion,
                zipcode: checkout.shippingZipcode,
              },
            })
          }
        } else {
          try {
            const body = await response.json()
            const messages = Object.keys(body)
              .map(key => `${key} ${body[key]}`)
              .join(', ')

            reject({
              message: messages,
              data: {
                country: checkout.shippingCountry,
                region: checkout.shippingRegion,
                zipcode: checkout.shippingZipcode,
              },
            })
          } catch (err) {
            reject({
              message: err.message,
              data: {
                country: checkout.shippingCountry,
                region: checkout.shippingRegion,
                zipcode: checkout.shippingZipcode,
              },
            })
          }
        }
      } catch (err) {
        reject({
          message: err.message,
          data: {
            country: checkout.shippingCountry,
            region: checkout.shippingRegion,
            zipcode: checkout.shippingZipcode,
          },
        })
      }
    })
  } else {
    console.log('Development: Get FAKE shipping methods', checkout)
    console.log(
      'URL',
      `/cart/shipping_rates.json?shipping_address[zip]=${checkout.shippingZipcode}&shipping_address[country]=${checkout.shippingCountry}&shipping_address[province]=${checkout.shippingRegion}`
    )

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (checkout && checkout.shippingZipcode === '60607') {
          resolve(shippingMethodsData.shipping_rates_loop)
        } else if (checkout && checkout.total > 250) {
          resolve(shippingMethodsData.shipping_rates_more)
        } else if (checkout && checkout.shippingZipcode === '00000') {
          reject({
            message: 'Zip is not valid for New York and United States',
            data: {
              country: checkout.shippingCountry,
              region: checkout.shippingRegion,
              zipcode: checkout.shippingZipcode,
            },
          })
        } else {
          resolve(shippingMethodsData.shipping_rates)
        }
      }, 600)
    })
  }
}

export const updateLineItem = async (id, quantity) => {
  if (process.env.NODE_ENV === 'production') {
    return new Promise(async (resolve, reject) => {
      try {
        const cart = await (await fetch(`/cart/update.js?updates[${id}]=${quantity}`, { method: 'POST' })).json()
        resolve(cart)
      } catch (e) {
        reject(e)
      }
    })
  } else {
    console.log('Update Line Item', id, quantity)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, 500)
    })
  }
}

export const buildPermalink = async checkout => {
  const cart = await shopifyCart()
  const base = `${window.location.protocol}//${window.location.hostname}/cart`
  const variants = cart.items.reduce((acc, item) => {
    return [...acc, `${item.variant_id}:${item.quantity}`]
  }, [])

  const email = `checkout[email]=${checkout.email}&checkout[shipping_address][first_name]=${checkout.shippingFirstName}`
  const shippingFirstName = `checkout[shipping_address][first_name]=${checkout.shippingFirstName}`
  const shippingLastName = `checkout[shipping_address][last_name]=${checkout.shippingLastName}`
  const shippingCity = `checkout[shipping_address][city]=${checkout.shippingCity}`
  const shippingLine1 = `checkout[shipping_address][line1]=${checkout.shippingLine1}`
  const shippingLine2 = `checkout[shipping_address][line2]=${checkout.shippingLine2}`
  const shippingZipcode = `checkout[shipping_address][zip]=${checkout.shippingZipcode}`

  return `${base}/${variants.join(
    ','
  )}?${email}&${shippingFirstName}&${shippingLastName}&${shippingCity}&${shippingLine1}&${shippingLine2}&${shippingZipcode}`
}
