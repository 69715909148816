import React from 'react'
import { withRouter } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import queryString from 'query-string'

const CustomerSummary = ({ checkout, match, history, location }) => {
  const { step } = queryString.parse(location.search)

  const updateStep = step => {
    history.push({ pathname: `${match.url}`, search: step ? `?step=${step}` : '' })
  }

  return (
    <div className="px-4 border border-gray-300 rounded">
      <div className="flex py-3 border-b border-gray-300">
        <div className="flex-shrink w-16 text-xs text-gray-500">Contact</div>

        <div className="flex-1 mx-2 text-sm text-gray-800">
          {checkout.email ? (
            <div>{checkout.email}</div>
          ) : (
            <div className="text-sm text-grey-400">Please provide your email</div>
          )}
        </div>

        <div
          className="flex-shrink text-xs text-gray-800 cursor-pointer hover:underline"
          onClick={() => updateStep(null)}>
          Change
        </div>
      </div>

      <div className="flex items-center py-3">
        <div className="flex-shrink w-16 text-xs text-gray-500">Ship To</div>

        <div className="flex-1 mx-2 text-sm text-gray-800">
          {checkout.shippingLine1 ? (
            <div>
              {`${checkout.shippingLine1}, ${checkout.shippingCity} ${checkout.shippingRegion || ''}, ${
                checkout.shippingZipcode || ''
              }, ${checkout.shippingCountry}`}
            </div>
          ) : (
            <div className="text-sm text-grey-400">Please provide a shipping address</div>
          )}
        </div>

        <div
          className="flex-shrink text-xs text-gray-800 cursor-pointer hover:underline"
          onClick={() => updateStep(null)}>
          Change
        </div>
      </div>

      {step === 'payment' ? (
        <div className="flex items-center py-3 border-t border-gray-300">
          <div className="flex-shrink w-16 text-xs text-gray-500">Method</div>

          <div className="flex-1 mx-2 text-sm text-gray-800">
            {checkout.shippingRate ? (
              <>
                {`${checkout.shippingRate.name} - `}
                <CurrencyFormat
                  value={checkout.shippingRate.price}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              </>
            ) : checkout.shippingMethod.code ? (
              <>
                {`${checkout.shippingMethod.name} - `}
                <CurrencyFormat
                  value={checkout.shippingMethod.price}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              </>
            ) : (
              <div className="text-sm text-grey-400">Please Select A Shipping Method</div>
            )}
          </div>

          <div
            className="flex-shrink text-xs text-gray-800 cursor-pointer hover:underline"
            onClick={() => updateStep('shipping')}>
            Change
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default withRouter(CustomerSummary)
