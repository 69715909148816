import React, { useMemo } from 'react'
import CurrencyFormat from 'react-currency-format'

const OrderLineItem = ({ lineItem }) => {
  const { productTitle, productVariant, price, quantity, total, totalDiscount } = lineItem

  const image = useMemo(() => {
    if (productVariant.productImage) return productVariant.productImage.src
    if (productVariant.product.productImages[0]) return productVariant.product.productImages[0].src
    return ''
  }, [productVariant])

  return (
    <div className="flex items-center mb-6">
      <div className="flex-shrink">
        <div className="relative w-16">
          <img className="w-16 h-16 object-cover rounded-lg cover border border-gray-300" src={image} alt="Product" />
          <div
            className="absolute top-0 right-0 -mt-2 -mr-2 bg-gray-600 text-white whitespace-no-wrap"
            style={{
              paddingTop: '0.1em',
              paddingRight: '0.6em',
              paddingBottom: '0.1em',
              paddingLeft: '0.6em',
              borderRadius: '1.5em',
              fontSize: '.80714em',
            }}>
            {quantity}
          </div>
        </div>
      </div>
      <div className="flex-grow mx-4">
        <div className="text-sm font-semibold text-gray-800">{productTitle}</div>
        {productVariant.default ? null : (
          <div className="text-xs text-gray-600">{`${productVariant.option1 ? productVariant.option1 : ''} ${
            productVariant.option2 ? productVariant.option2 : ''
          } ${productVariant.option3 ? productVariant.option3 : ''}`}</div>
        )}
        {/* {properties */}
        {/* 	? Object.keys(properties).map(property => ( */}
        {/* 			<div className="text-xs text-gray-600" key={property}> */}
        {/* 				{property}: {properties[property]} */}
        {/* 			</div> */}
        {/* 	  )) */}
        {/* 	: null} */}
      </div>
      <div className="flex-shrink">
        <div className="text-sm text-gray-800 font-medium text-right">
          <CurrencyFormat
            value={total}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={'$'}
          />
        </div>
        {totalDiscount > 0 ? (
          <div className="text-sm text-gray-500 font-medium line-through text-right">
            <CurrencyFormat
              value={price}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default OrderLineItem
