import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { CHECKOUT_LINE_ITEMS } from 'graphql/checkouts'
import LineItem from 'components/LineItem'

const LineItems = ({ checkoutId }) => {
  const { loading, error, data } = useQuery(CHECKOUT_LINE_ITEMS, {
    variables: {
      id: checkoutId,
    },
  })

  if (error) {
    return <div className="text-sm text-red-400 w-full text-center mb-4">There was an error loading your items</div>
  }

  if (loading) {
    return <div className="text-sm text-gray-500 w-full text-center mb-4">Loading Your Items...</div>
  }

  return data.checkout.checkoutLineItems
    .sort((a, b) => a.productVariant.product.title - b.productVariant.product.title)
    .sort((a, b) => a.isBump - b.isBump)
    .map(lineItem => {
      return <LineItem key={`line-item-${lineItem.id}`} lineItem={lineItem} checkoutId={checkoutId} />
    })
}

export default LineItems
