import React, { useContext, useCallback } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import { CheckoutContext } from 'contexts/CheckoutContext'
import { ProcessorContext } from 'contexts/ProcessorContext'
import GenericCreditCardForm from 'components/credit-card-forms/GenericCreditCardForm'
import SquarePayments from 'components/credit-card-forms/SquarePayments'
import StripePayments from 'components/credit-card-forms/StripePayments'
import Braintree from 'components/credit-card-forms/Braintree'
import { ReactComponent as PayPalLogo } from 'images/PayPal.svg'
import { ReactComponent as OffSiteLogo } from 'images/OffSite.svg'
import RadioButton from 'components/RadioButton'
import { PayPalButton } from 'react-paypal-button-v2'
import Color from 'color'

const CreditCardForm = ({ checkout }) => {
  // Params //

  const shopPaymentGateway = checkout.funnel.shopPaymentGateway

  // Context //

  const { setCreditCardRequestNonce, handleCreditCardNonceReceived } = useContext(ProcessorContext)

  switch (shopPaymentGateway.paymentGateway.gatewayType) {
    case 'SQUARE_PAYMENTS':
      return (
        <SquarePayments
          shopPaymentGateway={shopPaymentGateway}
          nonceReceived={handleCreditCardNonceReceived}
          setRequestNonce={setCreditCardRequestNonce}
        />
      )
    case 'STRIPE':
      return (
        <StripePayments
          shopPaymentGateway={shopPaymentGateway}
          nonceReceived={handleCreditCardNonceReceived}
          setRequestNonce={setCreditCardRequestNonce}
        />
      )
    case 'BRAINTREE':
      return (
        <Braintree
          shopPaymentGateway={shopPaymentGateway}
          nonceReceived={handleCreditCardNonceReceived}
          setRequestNonce={setCreditCardRequestNonce}
        />
      )
    case 'NMI':
    case 'AUTHNET':
      return (
        <GenericCreditCardForm
          nonceReceived={handleCreditCardNonceReceived}
          setRequestNonce={setCreditCardRequestNonce}
        />
      )
    default:
      return null
  }
}

const PaymentMethodSelector = ({ checkout }) => {
  // Contexts //

  const { theme } = useContext(ThemeContext)
  const { setSelectedMethod, selectedMethod, handlePaypalApproved, paypalOrderId, errors } = useContext(
    ProcessorContext
  )
  const { loading } = useContext(CheckoutContext)

  // Functions //

  const setSelectedMethodType = method => {
    setSelectedMethod(method)
  }

  const createOrder = useCallback(
    (data, actions) => {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: parseFloat(checkout.total).toFixed(2),
              currency_code: 'USD',
            },
          },
        ],
      })
    },
    [checkout.total]
  )

  const onApprove = useCallback(
    (data, actions) => {
      handlePaypalApproved(data.orderID)
    },
    [handlePaypalApproved]
  )

  return (
    <div className="mt-6">
      <div
        className="text-lg font-semibold"
        style={{
          color: Color(theme.primaryBrandColor).darken(0.25).string(),
        }}>
        Payment
      </div>
      <div className="text-xs text-gray-600">All transactions are secure and encrypted.</div>
      <div className={`mt-4 border rounded ${errors.length > 0 ? 'border-red-400' : 'border-gray-300'}`}>
        <div>
          <div
            className="flex items-center w-full p-3 border-b border-gray-300 cursor-pointer hover:bg-gray-200"
            onClick={() => setSelectedMethodType('CREDIT_CARD')}>
            <div className="flex-shrink">
              <RadioButton value="CREDIT_CARD" setValue={setSelectedMethodType} globalValue={selectedMethod} />
            </div>
            <div className="flex-grow ml-2 text-sm text-gray-800">Credit Card</div>
            {/* <div className="flex-shrink mr-1"> */}
            {/*   <img src="https://better-cart-assets.s3.us-east-2.amazonaws.com/images/cards/visa-icon.svg" alt="visa" /> */}
            {/* </div> */}
            {/* <div className="flex-shrink mr-1"> */}
            {/*   <img */}
            {/*     src="https://better-cart-assets.s3.us-east-2.amazonaws.com/images/cards/master-icon.svg" */}
            {/*     alt="mastercard" */}
            {/*   /> */}
            {/* </div> */}
            {/* <div className="flex-shrink mr-1"> */}
            {/*   <img */}
            {/*     src="https://better-cart-assets.s3.us-east-2.amazonaws.com/images/cards/discover-icon.svg" */}
            {/*     alt="discover" */}
            {/*   /> */}
            {/* </div> */}
            {/* <div className="flex-shrink"> */}
            {/*   <img src="https://better-cart-assets.s3.us-east-2.amazonaws.com/images/cards/amex-icon.svg" alt="amex" /> */}
            {/* </div> */}
          </div>
          {selectedMethod === 'CREDIT_CARD' ? (
            <div className="p-3">
              <CreditCardForm checkout={checkout} />
            </div>
          ) : null}
        </div>

        {checkout.funnel.paypal ? (
          <div className={`${selectedMethod === 'PAYPAL' ? '' : 'border-t border-gray-300'}`}>
            <div
              className={`flex items-center p-3 w-full cursor-pointer hover:bg-gray-200 ${
                selectedMethod === 'PAYPAL' && !selectedMethod ? 'border-b border-gray-300' : ''
              }`}
              onClick={() => setSelectedMethodType('PAYPAL')}>
              <div className="flex-shrink">
                <RadioButton value="PAYPAL" setValue={setSelectedMethodType} globalValue={selectedMethod} />
              </div>
              <div className="flex-grow ml-2">
                <PayPalLogo className="h-6" />
              </div>
            </div>
            {selectedMethod === 'PAYPAL' && !paypalOrderId && !loading ? (
              <div className="flex flex-col items-center justify-center py-8 bg-gray-100">
                <div>
                  <OffSiteLogo className="h-12" />
                </div>
                <div className="px-8 mt-4 text-sm text-center lg:px-16">
                  After clicking “PayPal Button”, you will be redirected to PayPal to complete your purchase securely.
                </div>

                <div className="w-4/5 mt-4">
                  <PayPalButton
                    options={{
                      clientId: checkout.funnel.paypal.clientId,
                      disableFunding: 'credit,card,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort',
                      commit: false,
                    }}
                    style={{
                      label: 'checkout',
                    }}
                    amount={checkout.total}
                    createOrder={createOrder}
                    onApprove={onApprove}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {errors.length > 0 ? (
        <div className="mt-2 text-sm text-red-400">{errors.map(e => e.message).join(', ')}</div>
      ) : null}
    </div>
  )
}

export default PaymentMethodSelector
