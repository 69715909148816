import React, { useState, useEffect, useMemo } from 'react'
import Color from 'color'

const CountDownTimer = ({ checkout }) => {
  const [time, setTime] = useState(8 * 60) // 8 mins

  // Functions //

  const secondsToTime = time => {
    const mins =
      parseInt(time / 60) === 0 ? '00' : parseInt(time / 60) < 10 ? `0${parseInt(time / 60)}` : parseInt(time / 60)
    const secs = time % 60 < 10 ? `0${time % 60}` : time % 60
    return `${mins}:${secs}`
  }

  const borderColor = Color(checkout.funnel.timerBgColor ? checkout.funnel.timerBgColor : '#fff5d2')
    .darken(0.25)
    .string()

  const text = useMemo(() => {
    const string = checkout.funnel.timerText ? checkout.funnel.timerText : 'Your order is reserved for {{time}}'
    return string.replace('{{time}}', secondsToTime(time))
  }, [time, checkout.funnel.timerText])

  // Effects //

  useEffect(() => {
    if (!time) return
    const intervalId = setInterval(() => {
      setTime(time - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [time])

  //{secondsToTime(time)}

  return (
    <>
      <div className="text-sm text-black font-bold">
        {checkout.funnel.timerDescription
          ? checkout.funnel.timerDescription
          : 'An item you ordered is in high demand. No worries, we have reserved your order.'}
      </div>
      <div
        className="mt-4 p-3 text-base font-semibold border rounded"
        style={{
          color: checkout.funnel.timerTextColor ? checkout.funnel.timerTextColor : '#000000',
          backgroundColor: checkout.funnel.timerBgColor ? checkout.funnel.timerBgColor : '#fff5d2',
          borderColor: borderColor,
        }}>
        {text}
      </div>
    </>
  )
}

export default CountDownTimer
