import { useEffect, useCallback } from 'react'
import { useFormikContext } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_CHECKOUT } from 'graphql/checkouts'
import { debounce } from 'lodash'

const CheckoutFormContext = ({ id }) => {
  const { values, dirty, isValidating, errors } = useFormikContext()

  const [updateCheckout] = useMutation(UPDATE_CHECKOUT, {
    onError: () => {
      console.log('Error updating checkout')
    },
  })

  const update = values => {
    updateCheckout({
      variables: {
        id,
        checkout: values,
      },
    })
  }

  const debouncedUpdate = useCallback(debounce(update, 500), [])

  useEffect(() => {
    if (dirty) {
      debouncedUpdate(values)
    }
  }, [values, debouncedUpdate, dirty])

  useEffect(() => {
    if (isValidating) {
      let keys = Object.keys(errors)
      if (keys.length > 0) {
        const selector = `[name=${keys[0]}]`
        const errorElement = document.querySelector(selector)

        if (errorElement) {
          const y = errorElement.getBoundingClientRect().top + window.pageYOffset - 100

          if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ top: y, behavior: 'smooth' })
          } else {
            document.body.scrollTop = y
            document.documentElement.scrollTop = y
          }

          setTimeout(() => {
            errorElement.focus()
          }, 500)
        }
      }
    }
  }, [errors, isValidating])

  return null
}

export default CheckoutFormContext
