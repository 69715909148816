import React, { createContext, useReducer } from 'react'

const ThemeContext = createContext()

const ThemeContextProvider = ({ children }) => {
  const initialState = {
    primaryBrandColor: '#000000',
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_PRIMARY_COLOR': {
        return { ...state, primaryBrandColor: action.color }
      }
      default: {
        return state
      }
    }
  }

  const [theme, themeDispatch] = useReducer(reducer, initialState)
  const value = { theme, themeDispatch }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

const ThemeContextConsumer = ThemeContext.Consumer

export { ThemeContext, ThemeContextProvider, ThemeContextConsumer }
