import React from 'react'

export default ({ type = 'primary', children, ...props }) => {
  const className = () => {
    switch (type) {
      case 'primary':
        return 'text-white bg-gray-800 hover:bg-gray-700'
      case 'loading':
        return 'text-white bg-gray-500 cursor-default'
      case 'danger':
        return 'text-red-400 bg-red-200 hover:bg-red-200'
      default:
        return 'text-white bg-gray-800 hover:bg-gray-700'
    }
  }

  return (
    <div {...props} className={`select-none p-4 rounded text-base text-center cursor-pointer ${className()}`}>
      {children}
    </div>
  )
}
