import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { ReactComponent as BackpackLogo } from 'images/backpack.svg'
import { FiTag } from 'react-icons/fi'

const LineItem = ({ checkoutId, lineItem }) => {
  const { productVariant, quantity, discounts } = lineItem

  const image = productVariant.productImage
    ? productVariant.productImage.src
    : productVariant.product.productImages[0]
    ? productVariant.product.productImages[0].src
    : null

  const inlineDiscount = discounts && discounts.length > 0 ? discounts[0] : false

  return (
    <div className="flex mb-6 items last:mb-0">
      <div className="flex-shrink">
        <div className="relative w-20">
          {image ? (
            <img className="object-cover w-20 h-20 border border-gray-300 rounded-lg cover" src={image} alt="Product" />
          ) : (
            <div className="w-20 text-gray-400 bg-white border border-gray-300 rounded-lg fill-current">
              <BackpackLogo />
            </div>
          )}
          <div
            className="absolute top-0 right-0 -mt-2 -mr-2 text-white whitespace-no-wrap bg-gray-600"
            style={{
              paddingTop: '0.1em',
              paddingRight: '0.6em',
              paddingBottom: '0.1em',
              paddingLeft: '0.6em',
              borderRadius: '1.5em',
              fontSize: '.80714em',
            }}>
            {quantity}
          </div>
        </div>
      </div>
      <div className="flex-grow ml-4">
        <div className="flex">
          <div className="flex-1 text-sm font-semibold text-gray-800">{productVariant.product.title}</div>

          <div className="flex-shrink ml-2">
            <div className="text-sm font-medium text-right text-gray-800 select-none">
              <CurrencyFormat
                value={lineItem.total}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
            </div>
            {lineItem.savings > 0 ? (
              <div className="text-sm font-medium text-right text-gray-500 line-through">
                <CurrencyFormat
                  value={lineItem.subtotal}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={'$'}
                />
              </div>
            ) : null}
          </div>
        </div>

        {productVariant.default ? null : (
          <div className="text-xs text-gray-500">{`${productVariant.option1 ? productVariant.option1 : ''} ${
            productVariant.option2 ? productVariant.option2 : ''
          } ${productVariant.option3 ? productVariant.option3 : ''}`}</div>
        )}

        {lineItem.isBump ? <div className="mt-1 text-sm text-green-400">Special Offer Item</div> : null}

        {inlineDiscount ? (
          <div className="flex items-center mt-2">
            <FiTag className="flex-shrink text-xl text-gray-500" />
            <div className="flex-1 ml-1 text-xs font-semibold text-gray-500 uppercase">{inlineDiscount.title}</div>
            <div className="flex-shrink ml-1 text-xs font-semibold text-gray-500">
              (-
              <CurrencyFormat
                value={inlineDiscount.amount / 100}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                prefix={'$'}
              />
              )
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default LineItem
