import ReactGA from 'react-ga'
import cryptoRandomString from 'crypto-random-string'

const ELIGIBLE_EVENTS = ['PAGE_VIEW', 'INIT_CHECKOUT', 'ADD_PAYMENT', 'PURCHASE', 'OFFER_ACCEPTED', 'OFFER_DECLINED']

const eventNameForType = type => {
  switch (type) {
    case 'INIT_CHECKOUT':
      return 'Order Started'
    case 'ADD_PAYMENT':
      return 'Payment Added'
    case 'PURCHASE':
      return 'Purchase'
    case 'OFFER_ACCEPTED':
      return 'Offer Accepted'
    case 'OFFER_DECLINED':
      return 'Offer Declined'
    default:
      return 'Unknown'
  }
}

export const googleInit = trackers => {
  ReactGA.initialize(
    trackers.map(tracker => {
      return {
        trackingId: tracker.data.trackingId,
      }
    }),
    {
      // debug: process.env.NODE_ENV === 'development',
      debug: false,
    }
  )

  ReactGA.plugin.require('ec')
}

export const googleFire = events => {
  events.forEach(event => {
    if (event.type === 'INIT_CHECKOUT') {
      // Init Checkout //
      ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
        id: event.data.checkout.id,
        revenue: event.data.checkout.total.toString(),
        products: event.data.checkout.checkoutLineItems.map(item => ({
          id: item.productVariant.id,
          name: item.productVariant.title,
          quantity: item.quantity,
          variant: item.productVariant.sku,
          price: item.productVariant.price,
        })),
      })
    }

    if (event.type === 'PURCHASE') {
      // Line Items
      event.data.order.orderLineItems.forEach(lineItem => {
        ReactGA.plugin.execute('ec', 'addProduct', {
          id: lineItem.productVariant.sku,
          name: lineItem.productTitle,
          variant: lineItem.variantTitle,
          sku: lineItem.productVariant.sku,
          price: lineItem.price.toString(),
          quantity: lineItem.quantity,
        })
      })

      // Purchase
      ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
        id: event.data.order.id,
        revenue: event.data.order.total.toString(),
        shipping: event.data.order.shipping.toString(),
        tax: event.data.order.taxes.toString(),
        coupon: event.data.order.discount ? event.data.order.discount?.discount?.code || "" : "", 
      })
    }

    if (event.type === 'OFFER_ACCEPTED') {
      // Line Item
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: event.data.selectedVariant.sku,
        name: event.data.productTitle,
        variant: event.data.selectedVariant.title,
        sku: event.data.selectedVariant.sku,
        price: event.data.price.toString(),
        quantity: event.data.quantity,
      })

      // Purchase
      ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
        id: cryptoRandomString({ length: 15, type: 'url-safe' }),
        revenue: event.data.total.toString(),
      })
    }

    // Page Views / Default Events
    if (event.type === 'PAGE_VIEW') {
      ReactGA.pageview(`${event.data.uri}${event.data.search}`)
    } else if (ELIGIBLE_EVENTS.includes(event.type)) {
      ReactGA.event({
        category: 'BetterCart',
        action: eventNameForType(event.type),
      })
    }

    ReactGA.plugin.execute('ec', 'clear')
  })
}
