/** @jsx jsx */
import { useContext, useMemo } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import FloatingLabelInput from 'components/FloatingLabelInput'
import { CountryDropdown, RegionDropdown, CountryRegionData } from '@bettercart/react-country-region-selector'
import { FiChevronDown } from 'react-icons/fi'
import { css, jsx } from '@emotion/core'
import GoogleAddressField from 'components/GoogleAddressField'
import { Field, useFormikContext } from 'formik'

const AddressForm = ({ namePrefix, phoneOptional, googleApiKey }) => {
  const { theme } = useContext(ThemeContext)
  const { values } = useFormikContext()

  const selectedCountry = values[`${namePrefix}Country`]
  const selectedCountryData = CountryRegionData.find(c => c.code === selectedCountry)

  const countryHasErrors = errors => Object.keys(errors).filter(k => k === `${namePrefix}Country`).length > 0
  const regionHasErrors = errors => Object.keys(errors).filter(k => k === `${namePrefix}Region`).length > 0

  const regionRequired = useMemo(() => {
    const countryData = CountryRegionData.find(c => c.code === selectedCountry)
    if (!countryData || countryData.regions.length < 1) return false
    return true
  }, [selectedCountry])

  const zipRequired = useMemo(() => {
    const countryData = CountryRegionData.find(c => c.code === selectedCountry)
    if (!countryData) return true
    return countryData.zipRequired
  }, [selectedCountry])

  const widthClass = useMemo(() => {
    if (!regionRequired && !zipRequired) return 'w-full'
    if (!regionRequired && zipRequired) return 'w-1/2'
    if (regionRequired && zipRequired) return 'w-1/3'
    if (regionRequired && !zipRequired) return 'w-1/2'
  }, [regionRequired, zipRequired])

  const branding = css`
    & {
      box-shadow: 0 0 0 1px #ccc;
    }
    &:focus {
      box-shadow: 0 0 0 2px ${theme.primaryBrandColor};
    }
  `

  const errorCss = css`
    & {
      box-shadow: 0 0 0 2px #fc8181;
    }
  `

  return (
    <div>
      <div className="flex flex-col items-start w-full lg:flex-row lg:justify-between">
        <div className="flex-none w-full mb-3 lg:mr-2 lg:mb-0 lg:flex-1">
          <Field name={`${namePrefix}FirstName`}>
            {({ field, form: { errors, touched } }) => (
              <FloatingLabelInput
                {...field}
                errors={errors}
                showErrors={touched[`${namePrefix}FirstName`]}
                label="First Name"
                placeholder="First Name"
              />
            )}
          </Field>
        </div>
        <div className="flex-none w-full lg:ml-2 lg:mb-0 lg:flex-1">
          <Field name={`${namePrefix}LastName`}>
            {({ field, form: { errors, touched } }) => (
              <FloatingLabelInput
                {...field}
                errors={errors}
                showErrors={touched[`${namePrefix}LastName`]}
                label="Last Name"
                placeholder="Last Name"
              />
            )}
          </Field>
        </div>
      </div>
      <div className="mt-3">
        {googleApiKey ? (
          <Field name={`${namePrefix}Line1`}>
            {({ field, form }) => (
              <GoogleAddressField apiKey={googleApiKey} field={field} form={form} namePrefix={namePrefix} />
            )}
          </Field>
        ) : (
          <Field name={`${namePrefix}Line1`}>
            {({ field, form: { errors, touched } }) => (
              <FloatingLabelInput
                {...field}
                errors={errors}
                showErrors={touched[`${namePrefix}Line1`]}
                label="Address"
                placeholder="Address"
              />
            )}
          </Field>
        )}
      </div>
      <div className="mt-3">
        <Field name={`${namePrefix}Line2`}>
          {({ field, form: { errors, touched } }) => (
            <FloatingLabelInput
              {...field}
              errors={errors}
              showErrors={touched[`${namePrefix}Line2`]}
              label="Apartment, suite, etc. (Optional)"
              placeholder="Apartment, suite, etc. (Optional)"
            />
          )}
        </Field>
      </div>
      <div className="mt-3">
        <Field name={`${namePrefix}City`}>
          {({ field, form: { errors, touched } }) => (
            <FloatingLabelInput
              {...field}
              errors={errors}
              showErrors={touched[`${namePrefix}City`]}
              label="City"
              placeholder="City"
            />
          )}
        </Field>
      </div>
      <div className="flex flex-col items-start w-full mt-3 lg:flex-row">
        <div
          className={`flex-1 w-full mb-3 lg:flex-none lg:pr-${
            !regionRequired && !zipRequired ? '0' : '2'
          } lg:mb-0 lg:${widthClass}`}>
          <Field name={`${namePrefix}Country`}>
            {({ field, form: { errors, values, setValues } }) => (
              <div>
                <div className="relative w-full">
                  <label className="absolute w-full px-3 my-1 text-xs text-gray-600 pointer-events-none">Country</label>
                  <CountryDropdown
                    {...field}
                    css={countryHasErrors(errors) ? errorCss : branding}
                    className="w-full pb-1 pl-3 pr-8 bg-white rounded outline-none appearance-none"
                    style={{ paddingTop: '1.2rem' }}
                    showDefaultOption={false}
                    value={field.value}
                    onChange={val => {
                      const countryData = CountryRegionData.find(c => c.code === val)
                      const zipRequired = countryData ? countryData.zipRequired : true
                      const first = countryData ? countryData.regions[0] : null

                      setValues({
                        ...values,
                        [`${namePrefix}Country`]: val,
                        [`${namePrefix}Region`]: first ? first.code : null,
                        [`${namePrefix}Zipcode`]: zipRequired ? values[`${namePrefix}Zipcode`] : '',
                      })
                    }}
                    priorityOptions={['US', 'CA', 'GB']}
                  />
                  <div className="absolute top-0 right-0 pl-2 mt-3 mr-2 border-l border-gray-400 pointer-events-none">
                    <FiChevronDown className="text-2xl text-gray-400" />
                  </div>
                </div>
                {countryHasErrors(errors) ? (
                  <div className="text-sm text-red-400">
                    {Object.keys(errors)
                      .filter(k => k === `${namePrefix}Country`)
                      .map(k => errors[k])
                      .join(', ')}
                  </div>
                ) : null}
              </div>
            )}
          </Field>
        </div>

        {regionRequired ? (
          <div
            className={`flex-1 w-full mb-3 lg:flex-none lg:pl-2 lg:pr-${
              regionRequired && !zipRequired ? '0' : '2'
            } lg:mb-0 lg:${widthClass}`}>
            <Field name={`${namePrefix}Region`}>
              {({ field, form: { errors, setFieldValue } }) => (
                <div>
                  <div className="relative w-full">
                    <label className="absolute w-full px-3 my-1 text-xs text-gray-600 pointer-events-none">
                      {selectedCountryData ? selectedCountryData.regionLabel : 'State/Region'}
                    </label>
                    <RegionDropdown
                      id="region-select"
                      css={regionHasErrors(errors) ? errorCss : branding}
                      className="w-full pb-1 pl-3 pr-8 bg-white rounded outline-none appearance-none"
                      style={{ paddingTop: '1.2rem' }}
                      showDefaultOption={false}
                      blankOptionLabel="Select Country"
                      country={selectedCountry || 'US'}
                      value={field.value || ''}
                      onChange={val => {
                        setFieldValue(field.name, val)
                      }}
                    />
                    <div className="absolute top-0 right-0 pl-2 mt-3 mr-2 border-l border-gray-400 pointer-events-none">
                      <FiChevronDown className="text-2xl text-gray-400" />
                    </div>
                  </div>
                  {regionHasErrors(errors) ? (
                    <div className="text-sm text-red-400">
                      {Object.keys(errors)
                        .filter(k => k === `${namePrefix}Region`)
                        .map(k => errors[k])
                        .join(', ')}
                    </div>
                  ) : null}
                </div>
              )}
            </Field>
          </div>
        ) : null}
        {zipRequired ? (
          <div className={`flex-1 w-full lg:pl-2 lg:flex-shrink lg:${widthClass}`}>
            <div className="flex-none w-full lg:mr-1 lg:mb-0 lg:flex-1">
              <Field name={`${namePrefix}Zipcode`}>
                {({ field, form: { errors, touched } }) => (
                  <FloatingLabelInput
                    {...field}
                    errors={errors}
                    showErrors={touched[`${namePrefix}Zipcode`]}
                    label="Zipcode"
                    placeholder="Zipcode"
                  />
                )}
              </Field>
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-3">
        <Field name={`${namePrefix}Phone`}>
          {({ field, form: { errors, touched } }) => (
            <FloatingLabelInput
              {...field}
              errors={errors}
              showErrors={touched[`${namePrefix}Phone`]}
              label="Phone"
              placeholder={phoneOptional ? 'Phone (Optional)' : 'Phone'}
            />
          )}
        </Field>
      </div>
    </div>
  )
}

export default AddressForm
