import React, { useContext, useEffect } from 'react'
import { ThemeContext } from 'contexts/ThemeContext'
import { LocationContext } from 'contexts/LocationContext'
import AddressForm from 'components/AddressForm'
import Color from 'color'
import { useFormikContext } from 'formik'

const ShippingInformation = ({ checkout }) => {
  const { theme } = useContext(ThemeContext)
  const { location } = useContext(LocationContext)

  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (location && location.status !== 'fail' && !checkout.shippingCountry && !checkout.shippingRegion) {
      setFieldValue('shippingCountry', location.countryCode)
      setFieldValue('shippingRegion', location.region)
    }
  }, [location, checkout.shippingCountry, checkout.shippingRegion, setFieldValue])

  return (
    <div className="mt-6">
      <div
        className="text-lg font-semibold"
        style={{
          color: Color(theme.primaryBrandColor).darken(0.25).string(),
        }}>
        Shipping Address
      </div>
      <div className="mt-4">
        <AddressForm
          namePrefix="shipping"
          phoneOptional={checkout.funnel.phoneOptional}
          googleApiKey={checkout.funnel.shop.googleAutocompleteApiKey}
        />
      </div>
    </div>
  )
}

export default ShippingInformation
